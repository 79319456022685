/* eslint-disable @typescript-eslint/no-shadow */
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { AdminDashboard, AdminDashboardRole, AppRole } from '../../models/admin-dashboard.modal';

@Component({
  selector: 'app-admin-grid-table',
  templateUrl: './admin-grid-table.component.html',
  styleUrls: ['./admin-grid-table.component.scss'],
})
export class AdminGridTableComponent implements OnInit {
  [x: string]: any;
  @ViewChild('table', {
    static: true,
  })
  // eslint-disable-next-line @typescript-eslint/indent
  table: MatTable<AdminDashboard>;

  tableColumns: string[] = [];
  @Input() dataSource: AdminDashboard[] = [];
  @Input() appRoles: AppRole[] = [];
  dragDisabled = true;
  ngOnInit() {
    this.dataSource.sort((a, b) => a.sortOrder - b.sortOrder);
    this.getDataValue();
    this.generateTableColumns();
  }

  getDataValue() {
    // Add all role names that are not already present in tableColumns
    this.appRoles.sort((a, b) => a.sortOrder - b.sortOrder);
    this.appRoles.forEach((roleName) => {
      if (!this.tableColumns.includes(roleName.roleName)) {
        this.tableColumns.push(roleName.roleName);
      }
    });
  }

  generateTableColumns() {
    this.tableColumns = ['columnNo', 'name', ...this.appRoles.map((m) => m.roleName), 'sort'];
  }

  dragDrop(event: CdkDragDrop<any>) {
    this.dragDisabled = true;
    const draggedIndex = this.dataSource.findIndex((d) => d === event.item.data);
    const droppedIndex = event.currentIndex;
    if (draggedIndex !== droppedIndex) {
      const movedItem = this.dataSource.splice(draggedIndex, 1)[0];
      this.dataSource.splice(droppedIndex, 0, movedItem);
      this.dataSource.forEach((item, index) => {
        item.sortOrder = index + 1;
      });
      this.table.renderRows();
    }
  }

  getRoleVisibility(roles: AdminDashboardRole[], roleName: string): boolean {
    if (!this.dataSource || this.dataSource.length === 0) {
      return false;
    }
    const role = roles.find((role) => role.roleName === roleName);
    return role ? role.visibility : false;
  }

  toggleRoleVisibility(checked: boolean, roles: AdminDashboardRole[], roleName: string) {
    const roleIndex = roles.find((role) => role.roleName === roleName);
    if (roleIndex) {
      roleIndex.visibility = checked;
      this.table.renderRows();
    }
  }
}
