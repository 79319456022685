/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AttachmentResponse } from 'src/app/shared/models/i-attachment-response.modal';
import { IAttachment } from 'src/app/shared/models/i-attachment.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { CustomSnackMessageComponent } from 'src/app/shared/components/customsnackmessage/customsnackmessage.component';
import { UserService } from 'src/app/shared/services/user.service';
import { AccountInfo } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-customer-attachments',
  templateUrl: 'customer-attachments.component.html',
  styleUrls: ['customer-attachments.component.scss'],
})
export class CustomerAttachmentsComponent implements OnInit {
  @Input() projectId: string | null;
  @Input() backgroundClass: string;
  @Output() selectedAttachment: EventEmitter<IAttachment> = new EventEmitter();
  selectedFiles: File[] = [];
  attachedFiles: AttachmentResponse[] = [];
  displayAddColumns: string[] = ['fileName', 'deleteAttachment'];
  displayStoreColumns: string[] = ['fileName', 'deleteStoredAttachment'];
  dataSource = new MatTableDataSource<File>();
  dataSourceAttached = new MatTableDataSource<AttachmentResponse>();
  totalFileSize = 0;
  isSuccess: boolean;
  account: AccountInfo | null;

  allowedFormat = 'application/pdf, image/jpeg, image/png, image/jpg';
  fileURL = '';
  fileTypeExtension = '';
  urlBlob = '';
  @Input() set showSuccess(value: boolean) {
    this.isSuccess = value;
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public precastService: PrecastService,
    public snackBar: MatSnackBar,
    public userService: UserService,
    private formService: FormService,
  ) {
    this.userService.userAccount?.subscribe({
      next: (userAccount) => {
        this.account = userAccount;
      },
    });
  }

  ngOnInit(): void {
    this.formService.attachments.subscribe({
      next: (files: File[] | null) => {
        if (files) {
          this.selectedFiles = [...files];
          this.dataSource.data = this.selectedFiles;
          this.emitSelectedAttachment();
        } else {
          this.selectedFiles = [];
        }
      },
    });
    this.getAllAttachment();
    this.checkSessionStorageFile();
  }

  checkSessionStorageFile() {
    const storedFile = sessionStorage.getItem('uploadedFile');
    if (storedFile) {
      const uploadedFileInfo = JSON.parse(storedFile);
      const byteCharacters = atob(uploadedFileInfo.content.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: 'application/pdf',
      });
      const file = new File([blob], uploadedFileInfo.filename);
      this.totalFileSize = file.size;
      this.selectedFiles.push(file);
      this.emitSelectedAttachment();
      this.dataSource.data = this.selectedFiles;
    }
  }

  deleteAttachment(index: number) {
    const deletedFile = this.selectedFiles.splice(index, 1)[0];
    this.totalFileSize -= deletedFile.size;
    this.dataSource.data = this.selectedFiles;
    this.formService.attachments.next(this.selectedFiles);
  }

  getFileDetails(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];
        if (this.isValidFile(file)) {
          this.totalFileSize += file.size;
          this.selectedFiles.push(file);
        }
      }

      if (inputElement.files.length > this.selectedFiles.length) {
        this.snackBar.openFromComponent(CustomSnackMessageComponent, {
          data: messagesConfig.customerAttachmentError.message,
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['red-snackbar'],
        });
      }

      this.emitSelectedAttachment();
      this.formService.attachments.next(this.selectedFiles);
      this.dataSource.data = this.selectedFiles;
    }
    this.fileInput.nativeElement.value = '';
  }

  clearSelection() {
    this.selectedFiles = [];
    this.totalFileSize = 0;
    this.dataSource.data = [];
    sessionStorage.removeItem('selectedFiles');
  }

  getAttachment(fileResponse: AttachmentResponse) {
    if (!this.projectId) return;
    this.precastService.getAttachement(this.projectId, fileResponse.fileName).subscribe({
      next: (response) => {
        const { url } = response || {
        };
        if (url) {
          const fileType = this.getFileExtension(fileResponse.fileName);
          this.urlBlob = url;
          switch (fileType) {
            case 'pdf':
              this.fileTypeExtension = 'application/pdf';
              break;
            case 'jpeg':
            case 'jpg':
              this.fileTypeExtension = 'image/jpg';
              break;
            case 'png':
              this.fileTypeExtension = 'image/png';
              break;
            default:
              break;
          }
          this.viewBlob();
        }
      },
    });
  }

  getAllAttachment() {
    if (!this.projectId) return;
    this.precastService.getAllattachments(this.projectId).subscribe({
      next: (response) => {
        this.attachedFiles = response || [];
        this.dataSourceAttached.data = this.attachedFiles;
      },
    });
  }

  deleteStoredAttachment(file: AttachmentResponse) {
    if (!this.projectId) return;
    this.precastService.deleteAttchment(this.projectId, file.fileName).subscribe({
      next: () => {
        this.getAllAttachment();
      },
    });
  }

  uploadByManager() {
    if (!this.projectId) return;
    const formData = new FormData();
    this.selectedFiles.forEach((file, index) => {
      formData.append(`file_${index}`, file, file.name);
    });
    this.precastService.uploadAttachments(this.projectId, formData, true).subscribe({
      next: () => {
        this.clearSelection();
        this.getAllAttachment();
      },
    });
  }

  isValidFile(file: File): boolean {
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
    const maxSize = 10 * 1024 * 1024;
    const isFileTypeValid = allowedTypes.includes(file.type);
    if (this.totalFileSize + file.size >= maxSize) {
      this.snackBar.openFromComponent(CustomSnackMessageComponent, {
        data: messagesConfig.customerAttachmentError.message,
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['red-snackbar'],
      });
      return false;
    }
    const isNotDuplicate = !this.attachedFiles.some((attachedFile) => attachedFile.fileName === file.name)
      && !this.selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    return isFileTypeValid && isNotDuplicate;
  }

  emitSelectedAttachment() {
    const formData = new FormData();
    this.selectedFiles.forEach((file) => formData.append('files', file));
    this.selectedAttachment.emit({
      value: formData,
      dirty: true,
      valid: this.selectedFiles.length > 0,
    });
  }

  formatSize(size: number): string {
    if (size > 1024 * 1024 || size === 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    }
    if (size > 1024 || size === 1024) {
      return `${(size / 1024).toFixed(1)} KB`;
    }
    return `${size} byte`;
  }

  viewBlob() {
    this.http
      .get(this.urlBlob, {
        responseType: 'arraybuffer',
      })
      .subscribe((blobFile) => {
        const blob = new Blob([blobFile], {
          type: this.fileTypeExtension,
        });
        this.fileURL = URL.createObjectURL(blob);
        window.open(this.fileURL);
      });
  }

  getFileExtension(filename: string): string {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }
}
