import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ValidateOneDecimal(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const oneDecimalRegEx = /^(?!0\d)([0-9]\d{0,2}(\.\d{1})?|99999)$/g;
    if (control.value !== 0 && !oneDecimalRegEx.test(control.value)) {
      return {
        valid: false,
      };
    }
    return null;
  };
}

export function CustomDecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const regex = /^(?:0(?:\.\d{1,2})?|\d{1,4}(?:\.\d{1,2})?)$/;
    if (value && !regex.test(value)) {
      return {
        invalidFormat: true,
      };
    }
    return null;
  };
}

// This function are not allowed DOT[.] , DOT Zero [.0] and integer DOT[xxx.] else allows all the values even null
export function DecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    const oneDecimalRegExFollowingNumber = /^(?!\.$)(?!\.0$)(?!.*\.$)(?!.*\.0$).*$|^$/;
    if (value && !oneDecimalRegExFollowingNumber.test(value)) {
      return {
        invalidFormat: true,
      };
    }
    return null;
  };
}
