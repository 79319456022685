import { Injectable } from '@angular/core';
import { ExportTableUtil } from 'src/app/shared/util/export-table.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { WorksheetResponse } from '../worksheet/models/worksheet-response.modal';

@Injectable({
  providedIn: 'root',
})
export class ExportTableService {
  worksheetdata!: WorksheetResponse;
  headerValue: any;
  rowValue: any;
  notesUpdateBy: string | undefined = '';
  noteText: string | undefined = '';
  soilType: string;
  filenamelist: any;
  citylocation: string;
  constructor(private snackBar: MatSnackBar) {
    (window as any).exportWSTable = this.exportWSTable.bind(this);
    (window as any).downloadFileData = this.downloadFileData.bind(this);
  }

  // Generate Worksheet open new window tab and export donwload//
  private getTableName(headerType: string): string {
    const nameMapping: { [key: string]: string } = {
      asRecivedWorksheets: 'As Received Worksheet',
      moistureWorksheets: 'Moisture Worksheet',
      nativeSoilWorksheets: 'Native Soil Worksheet',
      proctorWorksheets: 'Proctor Worksheet',
      precast: 'Precast Worksheet',
    };
    return (
      nameMapping[headerType]
      || headerType
        .replace('Worksheets', '')
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }

  private generateNativeSoilTable(headerData: any, rowData: any): string {
    let tableHtml = '';

    ['asRecivedWorksheets', 'moistureWorksheets', 'nativeSoilWorksheets', 'proctorWorksheets'].forEach((type) => {
      if (headerData[type] && rowData[type] && rowData[type].length > 0) {
        const tableName = this.getTableName(type);
        tableHtml += `<h2>${tableName}</h2>`;
        tableHtml += '<table>';
        tableHtml += '<thead><tr>';
        headerData[type].forEach((header: any) => {
          tableHtml += `<th>${header.displayName}</th>`;
        });
        tableHtml += '</tr></thead><tbody>';
        rowData[type].forEach((row: any) => {
          tableHtml += '<tr>';
          headerData[type].forEach((header: any) => {
            const cellValue = row[header.key] !== undefined && row[header.key] !== null ? row[header.key] : '';
            tableHtml += `<td>${cellValue}</td>`;
          });
          tableHtml += '</tr>';
        });
        tableHtml += '</tbody></table>';
      }
    });

    return tableHtml;
  }

  private generatePrecastTable(headerData: any[], rowData: any[]): string {
    let tableHtml = '';

    if (headerData && rowData && rowData.length > 0) {
      const tableName = this.getTableName('precast');
      tableHtml += `<h2>${tableName}</h2>`;
      tableHtml += '<table>';
      tableHtml += '<thead><tr>';
      headerData.forEach((header) => {
        tableHtml += `<th>${header.displayName}</th>`;
      });
      tableHtml += '</tr></thead><tbody>';
      rowData.forEach((row) => {
        tableHtml += '<tr>';
        headerData.forEach((header) => {
          const cellValue = row[header.key] !== undefined && row[header.key] !== null ? row[header.key] : '';
          tableHtml += `<td>${cellValue}</td>`;
        });
        tableHtml += '</tr>';
      });
      tableHtml += '</tbody></table>';
    }

    return tableHtml;
  }

  openInNewTab(
    headerData: any,
    rowData: any,
    worksheetRecords: any,
    notesUpdatedBy: any,
    text: any,
    soilType: string,
  ): void {
    this.worksheetdata = worksheetRecords;
    this.headerValue = headerData;
    this.rowValue = rowData;
    this.notesUpdateBy = notesUpdatedBy;
    this.noteText = text;
    this.soilType = soilType;
    let tableHtml = '';
    if (soilType === 'nativesoil') {
      tableHtml = this.generateNativeSoilTable(headerData, rowData);
    } else if (soilType === 'precast') {
      tableHtml = this.generatePrecastTable(headerData, rowData);
    }

    const downloadButtonHtml = `
      
      <div style="margin-top: 20px;">
    <button id="downloadBtn" style="padding: 10px 20px; font-size: 16px;">
      DOWNLOAD XLSX FILE
    </button>
  </div>
    `;

    let geoThermpreApproval = '';
    if (this.soilType === 'precast') {
      geoThermpreApproval = ` <p><strong>Pre-Approval:</strong> ${worksheetRecords.project.preApproval || ''}</p>`;
    } else {
      geoThermpreApproval = ` <p><strong>Geotherm Field:</strong> ${worksheetRecords.project.geothermField || ''}</p>`;
    }
    const projectInfoHtml = `
      <div>
        <p><strong>Project Name: </strong>${worksheetRecords.project.projectName || ''}</p>
        <p><strong>Project Number:</strong> ${worksheetRecords.project.projectNumber || ''}</p>
        <p><strong>Company Name:</strong> ${worksheetRecords.company.name || ''}</p>
        <p><strong>Sample Received Date:</strong> ${worksheetRecords.project.sampleReceivedDate || ''}</p>
        <p><strong>Instructions Confirmed At:</strong> ${worksheetRecords.project.instructionsConfirmedAt || ''}</p>
        <p><strong>Due Date: </strong>${worksheetRecords.project.dueDate || ''}</p>
        <p><strong>Oven Date: </strong>${worksheetRecords.project.ovenDate || ''}</p>
        <p><strong>Wet Test Data:</strong> ${worksheetRecords.project.wetTestData || ''}</p>
        <p><strong>Dry Test Data: </strong>${worksheetRecords.project.dryTestData || ''}</p>
       ${geoThermpreApproval}
        <p><strong>Rush Testing:</strong> ${worksheetRecords.project.rushTesting || ''}</p>
        <p><strong>Note Text: </strong>${text || ''}</p>
        <p><strong>Notes Updated By: </strong>${notesUpdatedBy || ''}</p>
      </div>
    `;

    const html = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Excel Preview</title>
          <style>
            body {
              font-family: Roboto, sans-serif;
              margin: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            th {
              background-color: #f2f2f2;
              text-align: left;
            }
            .header-title {
              margin-bottom: 20px;
            }
            h1 {
              text-align: center;
            }
        
            #downloadBtn {
            margin-top: -60px;
            right: 1%;
            position: absolute;
            color: #DD3333;
            align-items: center;
            background-color: white;
            border-radius: 4px;
            border: 2px solid #DD3333;
            cursor:pointer;
          }
          </style>
        </head>
        <body>
          <h1>Excel Preview${downloadButtonHtml}</h1>
          ${projectInfoHtml}
          ${tableHtml}
          <script>
            document.getElementById('downloadBtn').addEventListener('click', function() {
              // Simulate export to Excel by calling a function on the parent page
              if (window.opener && typeof window.opener.exportWSTable === 'function') {
                window.opener.exportWSTable();
              } else {
                alert('Export function not available.');
              }
            });
          </script>
        </body>
      </html>
    `;

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.open();
      newWindow.document.write(html);
      newWindow.document.close();
    }
  }

  exportWSTable() {
    const { projectName } = this.worksheetdata.project;
    const { projectNumber } = this.worksheetdata.project;
    const { name } = this.worksheetdata.company;
    const { sampleReceivedDate } = this.worksheetdata.project;
    const { instructionsConfirmedAt } = this.worksheetdata.project;
    const { dueDate } = this.worksheetdata.project;
    const { ovenDate } = this.worksheetdata.project;
    const { wetTestData } = this.worksheetdata.project;
    const { dryTestData } = this.worksheetdata.project;
    const { geothermField } = this.worksheetdata.project;
    const { rushTesting } = this.worksheetdata.project;
    const { noteText } = this;
    const notesUpdatedBy = this.notesUpdateBy;
    if (this.soilType === 'nativesoil') {
      ExportTableUtil.exportWorksheetTable(
        this.headerValue,
        this.rowValue,
        projectName,
        projectNumber,
        name,
        sampleReceivedDate,
        instructionsConfirmedAt,
        dueDate,
        ovenDate,
        wetTestData,
        dryTestData,
        geothermField,
        rushTesting,
        noteText,
        notesUpdatedBy,
      );
    } else if (this.soilType === 'precast') {
      ExportTableUtil.exportPrecastWorksheetTable(
        this.headerValue,
        this.rowValue,
        projectName,
        projectNumber,
        name,
        sampleReceivedDate,
        instructionsConfirmedAt,
        dueDate,
        ovenDate,
        wetTestData,
        dryTestData,
        geothermField,
        rushTesting,
        noteText,
        notesUpdatedBy,
      );
    }
  }

  // Geo Map Location Download Data //

  downloadTxtFile(filename: any, city: string) {
    this.filenamelist = filename;
    this.citylocation = city;
    if (!Array.isArray(this.filenamelist) || this.filenamelist.length === 0) {
      this.snackBar.open(messagesConfig.fileNameListEmpty.message, 'DONE', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['default-snackbar'],
      });
      return;
    }
    const fileContent = this.filenamelist
      .map((filenames: any, index: number) => `${index + 1}. ${filenames}`)
      .join('<br>');
    const newTab = window.open('', '_blank');

    if (newTab) {
      newTab.document.write(`
      <html>
        <head>
          <title>${this.citylocation} FileName(s) List</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            h1 {
              margin-bottom: 10px;
            }
            .download-button {
              padding: 10px 20px;
              right: 1%;
              position: absolute;
              color: #DD3333;
              align-items: center;
              background-color: white;
              border-radius: 4px;
              border: 2px solid #DD3333;
              cursor:pointer;
            }
              
          </style>
        </head>
        <body>
          <h1>${this.citylocation} FileName(s) List
            <button id="downloadBtn"class="download-button" >DOWNLOAD TXT FILE</button>
          </h1>
          <p>${fileContent}</p>
          <script>
          document.getElementById('downloadBtn').addEventListener('click', function() {
            if (window.opener && typeof window.opener.downloadFileData === 'function') {
              window.opener.downloadFileData();
            } else {
              alert('Export function not available.');
            }
          });
        </script>
        </body>
      </html>
    `);
      newTab.document.close();
    }
  }

  downloadFileData() {
    const fileContent = this.filenamelist.join('\n');
    const blob = new Blob([fileContent], {
      type: 'text/plain',
    });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `${this.citylocation}.txt`;
    link.click();
    window.URL.revokeObjectURL(url);
  }
}
