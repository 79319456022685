import { Component, Output, EventEmitter, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPrecastSample, IPrecastSampleData } from 'src/app/shared/models/i-precast-sample.modal';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { messagesConfig } from '../../../../shared/constants/message.constants';
import { sampleData } from '../../../../shared/constants/sampleData.constant';
import { IDropdown } from '../../../../shared/models/i-dropdown-type.modal';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'app-sample-data-precast',
  templateUrl: 'sample-data-precast.component.html',
  styleUrls: ['sample-data-precast.component.scss'],
})
export class SampleDataPrecastComponent implements OnInit {
  @Output() sampleDataPrecastData: EventEmitter<IPrecastSampleData> = new EventEmitter();
  @Output() sampleTypeSelected: EventEmitter<string[]> = new EventEmitter();
  @Input() data: any;
  makeSampleEditable = true;
  @Input() set makeEditable(value: boolean) {
    this.makeSampleEditable = value;
  }

  @Input() companyData: ICompany | undefined;

  sampleDataPrecastForm: FormGroup;
  sampleTypes: IDropdown[] = sampleData;
  currentDate = new Date(Date.now());
  readytoEmit = false;
  isFocused = false;
  isButtonDisabled = true;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    public cdr: ChangeDetectorRef,
  ) {
    this.sampleDataPrecastForm = this.fb?.group({
      samples: this.fb?.array([]),
    });
  }

  ngOnInit(): void {
    this.sampleDataPrecastForm?.valueChanges?.subscribe((frmGrp) => {
      if (this.readytoEmit) {
        this.sampleDataPrecastData?.emit({
          value: frmGrp?.samples,
          valid: this.sampleDataPrecastForm?.valid,
          dirty: this.sampleDataPrecastForm?.dirty,
        });
      }
    });
    if (this.data) {
      const sampleLength = this.data.length;
      if (sampleLength > 0) {
        for (let i = 0; i < sampleLength; i++) {
          this.getSampleControls.push(this.createItem());
        }
      }
      const patchValue = {
        samples: this.data,
      };
      this.sampleDataPrecastForm.patchValue(patchValue);
    } else {
      this.getSampleControls.push(this.createItem());
    }
    this.readytoEmit = true;
    this.sampleDataPrecastData?.emit({
      value: this.sampleDataPrecastForm.get('samples')?.value,
      valid: this.sampleDataPrecastForm?.valid,
      dirty: this.sampleDataPrecastForm?.dirty,
    });
  }

  public addSample(): void {
    if (this.getSampleControls?.length < 50) {
      this.getSampleControls?.push(this.createItem());
      this.sampleDataPrecastForm.markAsUntouched();
      this.sampleDataPrecastForm.updateValueAndValidity();
    } else {
      this.dialogService?.open(messagesConfig?.maxSampleWarning);
    }
    this.isFocused = false;
    this.cdr.detectChanges();
  }

  toggleButtonState(): void {
    if (this.isButtonDisabled === true) {
      this.addSample();
    }
  }

  public removeItem(index: number) {
    this.getSampleControls?.removeAt(index);
    this.processSampleType();
  }

  onFocus() {
    this.isFocused = true;
  }

  public sampleError(sample: any): string {
    const currentSample = sample as FormGroup;
    const requiredError = currentSample.get('numSamplesPerSet')?.hasError('required');
    const sampleValue = currentSample.get('numSamplesPerSet')?.value;
    if (this.sampleDataPrecastForm?.valid === false) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
    let message = '';
    if (requiredError) {
      message = 'Please enter samples per set.';
    } else {
      if (sampleValue < 1) {
        message = 'Samples per set should be atleast 1.';
      }
      if (sampleValue > 10) {
        message = 'Allowed sample per set is 10.';
      }
    }
    return message;
  }

  private createItem(): FormGroup {
    return this.fb?.group({
      id: [null],
      sampleId: ['', Validators.required],
      truckNumber: [''],
      ticketNumber: [''],
      sampleCollectionDate: ['', Validators.required],
      sampleType: ['', Validators.required],
      numSamplesPerSet: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
        Validators.required,
      ],
      mixDesignCode: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
      ],
      thermalSpecifications: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
      ],
    });
  }

  get getSampleControls(): FormArray {
    return this.sampleDataPrecastForm?.controls['samples'] as FormArray;
  }

  datechange(data: Date, i: number): void {
    const controlArray: FormArray = <FormArray> this.sampleDataPrecastForm?.get('samples');
    controlArray?.controls[i]?.get('sampleCollectionDate')?.setValue(data);
  }

  sampleTypeChange(): void {
    this.processSampleType();
  }

  processSampleType(): void {
    const sampleType: string[] = [];
    this.sampleDataPrecastForm.value.samples.forEach((elem: IPrecastSample) => {
      sampleType.push(elem.sampleType);
    });
    setTimeout(() => {
      this.sampleTypeSelected.emit([...new Set(sampleType)]);
    }, 10);
  }
}
