export const PdfNativeTableHeader = [
  {
    text: 'Sample ID', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Depth (ft)', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Effort (%)', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Description', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Thermal Resistivity (°C-cm/W)', colSpan: 2, style: 'tableHeaderMerged',
  },
  {
  },
  {
    text: 'Moisture Content (%)', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Dry Density (lb/ft3)', rowSpan: 2, style: 'tableHeaderStyle',
  },
];

export const PdfNativeMergedHeader = [
  '',
  '',
  '',
  '',
  {
    text: 'Dry', style: 'tableHeaderMerged',
  },
  {
    text: 'Wet', style: 'tableHeaderMerged',
  }, '', '',
];

export const PdfPrecastTableHeader = [
  {
    text: 'Sample ID', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Date Cast', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Material Type', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Moisture Content (%)', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Dry Density (lb/ft3)', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Thermal Resistivity (°C-cm/W)', colSpan: 2, style: 'tableHeaderMerged',
  },
  {
  },
];

export const PdfPrecastMergedHeader = [
  '',
  '',
  '',
  '',
  '',
  {
    text: 'Dry', style: 'tableHeaderMerged',
  },
  {
    text: 'Wet', style: 'tableHeaderMerged',
  },
];
