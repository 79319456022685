/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationExtras } from '@angular/router';
import { Subject, Subscription, forkJoin, takeUntil, timer } from 'rxjs';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountInfo } from '@azure/msal-common';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/shared/services/form.service';
import { TrackEventService } from 'src/app/shared/services/track-event.service';
import { ManageReportModalComponent } from 'src/app/shared/components/manage-report-modal/manage-report-modal.component';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { AdminHeader } from 'src/app/shared/models/admin-header.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INativeSample } from 'src/app/shared/models/i-native-sample.modal';
import { IPrecastSample } from 'src/app/shared/models/i-precast-sample.modal';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { IMessage } from 'src/app/shared/models/message.modal';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { LandingDialogComponent } from '../../shared/components/landing-dialog/landing-dialog.component';
import { DashboardTable, typesData } from '../../shared/constants/table-column.constants';
import { NotesComponent } from '../../shared/components/notes/notes.component';
import { WorksheetService } from '../worksheet/services/worksheet.service';
import { PrecastService } from '../../shared/services/precast.service';
import { IDropdown } from '../../shared/models/i-dropdown-type.modal';
import { UserService } from '../../shared/services/user.service';
import { IDashboardItems } from './models/idashboard-items.modal';
import { ProjectsCount } from '../../shared/models/iprojects-count.modal';
import { ShipmentReceivedComponent } from './components/shipment-received/shipment-received.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('adminTab') adminTab: MatTab | undefined;
  @ViewChild('menuTriggerprojStatus') projStatustrigger: MatMenuTrigger;
  @ViewChild(MatPaginator) public dashboarPaginator!: MatPaginator;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;
  private readonly destroyed$ = new Subject<boolean>();
  public dataSource = new MatTableDataSource<IDashboardItems>();
  public projectRecords: IDashboardItems[] = [];
  public projectStatuses: IDropdown[] = [];
  public materialTypes: IDropdown[] = [];
  public userAccount: AccountInfo | null; // TODO Need to remove this null occurence
  public projectsCount: ProjectsCount = new ProjectsCount();
  public displayedColumns: string[] = DashboardTable;
  public selectedMaterialTypes: string[] = [];
  public selectedProjectStatus: string[] = [];
  public materialType = '';
  public projectStatus = '';
  public searchKeyWord: any = '';
  public columnName = '';
  public recordsPerPage = 10;
  public totalRecords = 0;
  public currentPage = 1;
  public totalPages = 1;
  public showSearchResultCount = false;
  public myProjectsFlagApi = false;
  public isArchiveTabFlagApi = true;
  public myProjects = false;
  public archiveProjects = true;
  public dialogRef: any; // TODO Need to remove this occurance of any
  private readonly destroying$ = new Subject<void>();
  private reportDownloadSubscription = Subscription.EMPTY;
  private projectsSubscription = Subscription.EMPTY;
  private userSubscription = Subscription.EMPTY;
  public selectedTab = 0;
  public countDown = Subscription.EMPTY;
  public counter: number;
  dashboardRefresh: number;
  public tick = 1000;
  headerKeyToRowKeyMapping: { [key: string]: string } = {
  };

  userRoles: string[] = [];
  headerData: AdminHeader[] = [];
  displayedColumn: string[] = [];
  deleteRecordDialogRef: any;
  filteredNativeSamples: INativeSample[] = [];
  filteredPrecastSamples: IPrecastSample[] = [];
  errorMsg: MatDialogRef<MessageModalComponent>;
  inActiveRecordsCheckboxValue = false;
  isDisabledWhenInactiveRecord = false;
  isCheckboxDisabled = false;

  constructor(
    public dialogService: DialogService,
    private worksheetService: WorksheetService,
    private dashboardService: PrecastService,
    private paginator: MatPaginatorIntl,
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private formService: FormService,
    private trackEventService: TrackEventService,
    private adminService: AllAdminService,
    private snackBar: MatSnackBar,
  ) {
    this.paginator.itemsPerPageLabel = 'Rows per page';
  }

  ngOnInit() {
    this.userAccountRole();
    if (sessionStorage.getItem('inActiveRecordsCheckboxValue')) {
      const activeRecord = sessionStorage.getItem('inActiveRecordsCheckboxValue');
      if (activeRecord !== null && JSON.parse(activeRecord) === true) {
        this.isDisabledWhenInactiveRecord = true;
        this.archiveProjects = false;
        this.inActiveRecordsCheckboxValue = JSON.parse(activeRecord);
        this.isArchiveTabFlagApi = false;
        this.handleCheck();
      }
    }
    if (sessionStorage.getItem('tabIndex')) {
      const storedNumber = sessionStorage.getItem('tabIndex');
      const tabLabel = sessionStorage.getItem('tabLabel');
      if (storedNumber !== null) {
        const myNumber = Number(storedNumber);
        this.selectedTab = myNumber;
        const initialTabChangeEvent: MatTabChangeEvent = {
          index: myNumber,
          tab: {
            textLabel: tabLabel,
          } as any,
        };
        this.onTabChanged(initialTabChangeEvent);
      }
    }
    if (sessionStorage.getItem('searchKeyWord')) {
      const search = sessionStorage.getItem('searchKeyWord');
      if (search !== null && search !== undefined) {
        this.searchKeyWord = decodeURIComponent(search);
      }
    }
    if (sessionStorage.getItem('materialType')) {
      const sessionStoredMaterialType = sessionStorage.getItem('materialType');
      if (sessionStoredMaterialType !== null) {
        this.selectedMaterialTypes = this.splitStringByComma(sessionStoredMaterialType);
      }
    }
    if (sessionStorage.getItem('projectStatus')) {
      const sessionStoredProjectStatus = sessionStorage.getItem('projectStatus');
      if (sessionStoredProjectStatus !== null) {
        this.selectedProjectStatus = this.splitStringByComma(sessionStoredProjectStatus);
      }
    }
    if (sessionStorage.getItem('currentPage')) {
      this.currentPage = JSON.parse(sessionStorage.getItem('currentPage') as any);
    }
    if (sessionStorage.getItem('recordsPerPage')) {
      this.recordsPerPage = JSON.parse(sessionStorage.getItem('recordsPerPage') as any);
    }
    if (sessionStorage.getItem('columnName')) {
      const colNameSort = sessionStorage.getItem('columnName');
      if (colNameSort !== null && colNameSort !== undefined) {
        this.columnName = colNameSort;
      }
    }
    localStorage.removeItem('reviewForm');
    sessionStorage.removeItem('editViewSubmission');
    sessionStorage.removeItem('uploadedFile');
    sessionStorage.removeItem('uploadedFilesList');
    this.formService.attachments.next(null);
    this.dataSource.paginator = this.dashboarPaginator;
    this.getDashboardData();
    this.userSubscription = this.userService.userAccount.subscribe({
      next: (account) => {
        this.userAccount = account;
      },
    });
    this.getAdminCounter();
    this.getColumnHeader();
  }

  ngAfterViewInit() {
    this.trackEventService.onClick$.subscribe(() => {
      this.resetCounter();
    });

    this.trackEventService.onMouseMove$.subscribe(() => {
      this.resetCounter();
    });
    this.trackEventService.onMouseUp$.subscribe(() => {
      this.resetCounter();
    });

    this.trackEventService.onMKeyUp$.subscribe(() => {
      this.resetCounter();
    });

    this.trackEventService.onWindowBlur$.subscribe(() => {
      this.resetCounter();
    });
    this.countDown = timer(0, this.tick).subscribe(() => {
      this.counter -= 1;
      sessionStorage.setItem('Refresh-counter', this.counter.toString());
      if (this.counter === 0) {
        this.getDashboardData();
        this.resetCounter();
      }
    });
  }

  userAccountRole() {
    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  allUserAction(): boolean {
    return this.roleCheck(['Manager', 'Admin', 'Reportwriter', 'Labtech']);
  }

  getAdminCounter() {
    this.adminService.getAdmin().subscribe(
      (responseList) => {
        const adminObject = responseList.find((item: any) => item.key === 'cycletimefortvdashboard');
        if (adminObject) {
          this.counter = adminObject.value;
          this.dashboardRefresh = adminObject.value;
        }
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  resetCounter(counterTime: number = this.dashboardRefresh) {
    this.counter = counterTime || this.dashboardRefresh;
  }

  getColumnHeader() {
    this.adminService.getAdminDashboard().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumn = this.headerData.map((header) => header.key);
        this.dataSource = new MatTableDataSource(this.projectRecords);
        this.dataSource.sort = this.sort;
        this.getColumnkeyMatch();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  // Match header key value with row key value
  getColumnkeyMatch() {
    this.headerKeyToRowKeyMapping = {
    };
    this.headerData.forEach((header) => {
      this.headerKeyToRowKeyMapping[header.key] = header.key;
    });
  }

  // Displays the header value based on sorted order
  getDisplayName(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    return header ? header.displayName : '';
  }

  getTooltipText(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    return header ? header.tooltipText : '';
  }

  // Displays the column row value
  getRowDataByKey(row: any, key: string): string {
    const rowKey = this.headerKeyToRowKeyMapping[key];
    const value = row[rowKey];
    // Custom date formatting for 'submitteddate', 'received', and 'duedate' columns
    if (key === 'submitted' || key === 'sampleReceivedDate' || key === 'dueDate') {
      if (value === null) {
        return '';
      }
      const date = new Date(value);
      return date.toLocaleString('en-us', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });
    }
    if (key === 'rushed') {
      return value ? 'true' : 'false'; // Convert boolean value to string representation
    }

    if (key === 'soilType') {
      return value.soilType;
    }
    if (key === 'status') {
      return value.status;
    }
    return value;
  }

  public getDashboardData(): void {
    this.fetchProjectsCount();
    this.fetchDashboardData();
    this.onPaginationNavigate();
  }

  public onPaginationNavigate(event?: PageEvent): void {
    if (event?.pageIndex) {
      this.currentPage = event.pageIndex + 1;
    } else {
      if (sessionStorage.getItem('currentPage')) {
        this.currentPage = JSON.parse(sessionStorage.getItem('currentPage') as any);
        if (event?.pageIndex === 0) {
          this.currentPage = 1;
        }
      } else {
        this.currentPage = 1;
      }
      this.dashboarPaginator?.firstPage();
    }
    sessionStorage.setItem('currentPage', this.currentPage.toString());

    if (event?.pageSize) {
      this.recordsPerPage = event?.pageSize ? event.pageSize : 10;
    } else if (sessionStorage.getItem('recordsPerPage')) {
      this.recordsPerPage = JSON.parse(sessionStorage.getItem('recordsPerPage') as any);
    }
    sessionStorage.setItem('recordsPerPage', this.recordsPerPage.toString());
    this.fetchProjects();
  }

  public routingPage(): void {
    this.dialog.open(LandingDialogComponent, {
      disableClose: true,
      data: 'auth',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    sessionStorage.removeItem('uploadedFile');
  }

  public gotoWorksheet(projectID: string, mode: string, soilType: string): void {
    const navigateSoilType = soilType === 'Pre Cast' ? 'precast' : 'nativesoil';
    if (mode) {
      this.router.navigateByUrl(`worksheet?soilType=${navigateSoilType}&&projectId=${projectID}`);
    } else if (soilType === 'Pre Cast') {
      this.worksheetService.generatePrecastWorksheet(projectID).subscribe({
        next: () => {
          this.fetchProjects();
          this.router.navigateByUrl(`worksheet?soilType=${navigateSoilType}&&projectId=${projectID}`);
        },
        error: () => {},
      });
    } else {
      this.worksheetService.generateNativeSoilWorksheet(projectID).subscribe({
        next: () => {
          this.fetchProjects();
          this.router.navigateByUrl(`worksheet?soilType=${navigateSoilType}&&projectId=${projectID}`);
        },
        error: () => {},
      });
    }
  }

  // TODO: Change this any type and move the constant to constant file
  public openDialog(element: any, type: string): void {
    const messageObject = {
      projectId: element?.id,
      projectStatus: element?.status,
      projectName: element?.projectName,
      projectType: element?.soilType,
      companyName: element?.customer,
      projectNumber: element?.projectNumber,
    };
    const component = type === 'Notes' ? NotesComponent : ShipmentReceivedComponent;
    this.dialogRef = this.dialog?.open<any>(component, {
      panelClass: 'app-dialog',
      data: messageObject,
      disableClose: true,
      width: type === 'Notes' ? '60%' : '70%',
    });
    if (type === 'SetStatus') {
      this.dialogRef.afterClosed().subscribe(() => {
        this.fetchProjectsCount();
        this.fetchProjects();
      });
    }
  }

  // TODO Need to think of a better name for this
  public pageNavigate(event: any): void {
    if (event === 'materialTypeCheck') {
      sessionStorage.setItem('materialType', this.materialType);
    } else if (event === 'projectStatusCheck') {
      sessionStorage.setItem('projectStatus', this.projectStatus);
    }
    // this.currentPage = 1;
    this.currentPage = sessionStorage.getItem('currentPage')
      ? JSON.parse(sessionStorage.getItem('currentPage') as any)
      : 1;
    this.recordsPerPage = sessionStorage.getItem('recordsPerPage')
      ? JSON.parse(sessionStorage.getItem('recordsPerPage') as any)
      : 10;
    this.onPaginationNavigate();
  }

  public onClearSearchButtonClick(): void {
    sessionStorage.removeItem('searchKeyWord');
    this.showSearchResultCount = false;
    this.searchKeyWord = '';
    this.onPaginationNavigate();
  }

  public onSearchChange(): void {
    this.showSearchResultCount = false;
    if (!this.searchKeyWord) {
      this.onClearSearchButtonClick();
    }
  }

  sortData(sort: Sort) {
    const selectedColumn = this.headerKeyToRowKeyMapping[sort.active];
    this.columnName = `${selectedColumn} ${sort.direction}`;
    sessionStorage.setItem('columnName', this.columnName);
    this.onPaginationNavigate();
  }

  public onCheckboxClick(
    checked: boolean,
    item: string,
    selectedItemArray: string[],
    allItems: { label: string }[],
    targetProperty: string,
  ): void {
    const itemIndex = selectedItemArray.indexOf(item);
    if (item === 'All') {
      selectedItemArray = checked ? [...allItems.map((items) => items.label)] : [];
    } else if (!checked) {
      this.removeItem(item, itemIndex, selectedItemArray, 'All', selectedItemArray);
    } else {
      this.addItem(item, selectedItemArray, 'All', allItems);
    }
    if (targetProperty === 'materialType') {
      this.selectedMaterialTypes = selectedItemArray;
    } else {
      this.selectedProjectStatus = selectedItemArray;
    }
    this.updateProperty(selectedItemArray, targetProperty, allItems);
  }

  public manageReport(element: any): void {
    const date = new Date(element.dueDate);
    let fileName = `${element?.customer}-${element?.projectName}-${element?.soilType === 'Pre Cast' ? 'PC' : 'NS'}`;
    if (element?.projectNumber) {
      fileName = `${fileName}-${element?.projectNumber}`;
    }
    const duedate = element.dueDate
      ? `-${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date
        .getFullYear()
        .toString()}`
      : '';
    fileName = `${fileName}${duedate}`;

    this.dialog?.open(ManageReportModalComponent, {
      panelClass: 'manage-report-dialog',
      data: {
        id: element.id,
        title: fileName,
        status: element.status,
        type: element?.soilType === 'Pre Cast' ? 'precast' : 'nativesoil',
        dueDate: element.dueDate,
        assignedUser: element.assignedUser,
        geothermField: element.geothermField,
      },
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  public assignToReportWriter(projectId: string) {
    this.dashboardService
      ?.assignToReportWriter(projectId)
      ?.pipe(takeUntil(this.destroying$))
      ?.subscribe({
        next: () => {
          this.fetchProjects();
        },
      });
  }

  public assignToMyself(projectId: string, soilType: string) {
    this.userService.userGraphApi().subscribe({
      next: (adUser: any) => {
        const payload = {
          projectId,
          aadObjectId: adUser?.id,
          firstName: adUser?.givenName,
          lastName: adUser?.surname,
          userName: adUser?.displayName,
          email: adUser?.userPrincipalName,
          role: adUser?.role,
          additionalNotes: null,
        };
        this.dashboardService.assignToMyself(payload, soilType).subscribe({
          next: () => {
            this.fetchProjects();
          },
        });
      },
    });
  }

  public onTabChanged($event: MatTabChangeEvent): void {
    if ($event.index === 0) {
      this.myProjects = false;
      this.isCheckboxDisabled = false;
    } else if ($event.index === 1) {
      this.myProjects = true;
      this.isCheckboxDisabled = true;
    }

    this.selectedTab = $event.index;
    sessionStorage.setItem('tabIndex', $event.index.toString());
    sessionStorage.setItem('tabLabel', $event.tab.textLabel);
    this.myProjectsFlagApi = this.myProjects;

    this.clearFiltersAndSort();
    this.onPaginationNavigate();
  }

  public fetchDashboardData(): void {
    const projectsCount$ = this.dashboardService?.fetchSampleCount();
    const materialTypes$ = this.dashboardService?.fetchMaterialTypes();
    const projectStatuses$ = this.dashboardService.fetchProjectStatuses();
    forkJoin({
      projectsCount: projectsCount$,
      materialTypes: materialTypes$,
      projectStatuses: projectStatuses$,
    }).subscribe({
      next: ({ projectsCount, materialTypes, projectStatuses }) => {
        this.projectsCount = projectsCount;
        this.materialTypes = materialTypes
          ?.sort((a, b) => a.order - b.order)
          ?.filter((materialType) => !materialType?.disabled);
        this.dashboardService.projectStatuses = projectStatuses;
        this.projectStatuses = projectStatuses.sort((a, b) => a.order - b.order);
        this.materialTypes = [typesData, ...this.materialTypes];
        this.projectStatuses = [typesData, ...this.projectStatuses];
      },
    });
  }

  private fetchProjects(): void {
    const sessionMaterialType = sessionStorage.getItem('materialType');
    const sessionProjectStatus = sessionStorage.getItem('projectStatus');
    if (sessionStorage.getItem('columnName')) {
      const colNameSort = sessionStorage.getItem('columnName');
      if (colNameSort !== null && colNameSort !== undefined) {
        this.columnName = colNameSort;
      }
    }
    let sessionSearchKeyWord = sessionStorage.getItem('searchKeyWord');
    if (sessionSearchKeyWord !== null && sessionSearchKeyWord !== undefined) {
      sessionSearchKeyWord = decodeURIComponent(sessionSearchKeyWord);
    }
    this.fetchProjectCountbasedParameters();
    this.projectsSubscription = this.dashboardService
      ?.fetchProjects(
        JSON.parse(sessionStorage.getItem('currentPage') as any)
          ? JSON.parse(sessionStorage.getItem('currentPage') as any)
          : this.currentPage,
        sessionSearchKeyWord || this.searchKeyWord,
        JSON.parse(sessionStorage.getItem('recordsPerPage') as any)
          ? JSON.parse(sessionStorage.getItem('recordsPerPage') as any)
          : this.recordsPerPage,
        this.columnName,
        this.myProjectsFlagApi,
        JSON.parse(sessionStorage.getItem('isArchiveTabFlagApi') as any)
          ? JSON.parse(sessionStorage.getItem('isArchiveTabFlagApi') as any)
          : this.isArchiveTabFlagApi,
        sessionMaterialType || this.materialType,
        sessionProjectStatus || this.projectStatus,
      )
      .subscribe({
        next: (response: IDashboardItems[]) => {
          this.projectRecords = response;
          this.dataSource = new MatTableDataSource<IDashboardItems>(this.projectRecords);
        },
      });
  }

  private fetchProjectCountbasedParameters(): void {
    const sessionMaterialType = sessionStorage.getItem('materialType');
    const sessionProjectStatus = sessionStorage.getItem('projectStatus');
    let sessionSearchKeyword = sessionStorage.getItem('searchKeyWord');
    if (sessionSearchKeyword !== null && sessionSearchKeyword !== undefined) {
      sessionSearchKeyword = decodeURIComponent(sessionSearchKeyword);
    }
    this.projectsSubscription = this.dashboardService
      ?.fetchProjectsCount(
        JSON.parse(sessionStorage.getItem('currentPage') as any)
          ? JSON.parse(sessionStorage.getItem('currentPage') as any)
          : this.currentPage,
        sessionSearchKeyword || this.searchKeyWord,
        this.myProjectsFlagApi,
        JSON.parse(sessionStorage.getItem('isArchiveTabFlagApi') as any)
          ? JSON.parse(sessionStorage.getItem('isArchiveTabFlagApi') as any)
          : this.isArchiveTabFlagApi,
        sessionMaterialType || this.materialType,
        sessionProjectStatus || this.projectStatus,
      )
      ?.subscribe({
        next: (response: number) => {
          this.totalRecords = response;
          this.showSearchResultCount = !!this.searchKeyWord;
          this.totalPages = Math.ceil(response / this.recordsPerPage);
          if (this.totalPages === 0) {
            this.projectRecords = [];
          }
        },
      });
  }

  private fetchProjectsCount(): void {
    this.projectsSubscription = this.dashboardService?.fetchSampleCount()?.subscribe({
      next: (response: ProjectsCount) => {
        this.projectsCount = response;
      },
    });
  }

  private removeItem(
    item: string,
    itemIndex: number,
    selectedItemArray: string[],
    targetItem: string,
    targetItems: string[],
  ): void {
    if (itemIndex > -1) {
      selectedItemArray.splice(itemIndex, 1);
      this.removeTargetItemIfAllSelected(targetItem, targetItems);
    }
  }

  private addItem(
    item: string,
    selectedItemArray: string[],
    targetItem: string,
    targetItems: { label: string }[],
  ): void {
    selectedItemArray.push(item);
    this.addTargetItemAllIfAllSelected(item, selectedItemArray, targetItem, targetItems);
  }

  private removeTargetItemIfAllSelected(targetItem: string, targetItems: string[]): void {
    const allIndex = targetItems.indexOf(targetItem);
    if (allIndex > -1) {
      targetItems.splice(allIndex, 1);
    }
  }

  private addTargetItemAllIfAllSelected(
    item: string,
    selectedItemArray: string[],
    targetItem: string,
    targetItems: { label: string }[],
  ): void {
    const isAllSelected = !selectedItemArray.includes(targetItem) && selectedItemArray.length === targetItems.length - 1;
    if (isAllSelected) {
      selectedItemArray.push(targetItem);
    }
  }

  private updateProperty(selectedItemArray: string[], targetProperty: string, allItems: { label: string }[]): void {
    const property = selectedItemArray.length > 0
      ? selectedItemArray.filter((selectedItem) => selectedItem).toString()
      : allItems.map((item) => item.label).toString();
    if (targetProperty === 'materialType') {
      this.materialType = property;
    } else {
      this.projectStatus = property;
    }
  }

  private createProjectReport(data: Blob, element: any): void {
    const downloadURL = window?.URL?.createObjectURL(data);
    const link = document.createElement('a');
    const date = new Date();
    const fileName = `${element?.customer}-${element?.projectName}-${element?.soilType === 'Pre Cast' ? 'PC' : 'NS'}-${
      element?.projectNumber
    }-${date.getMonth() + 1}${date.getDate().toString().padStart(2, '0')}${date.getFullYear().toString().slice(2)}`;
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  private clearFiltersAndSort(projectStatus = ''): void {
    // this.selectedMaterialTypes = [];
    // this.selectedProjectStatus = [];
    const valueMaterialType = sessionStorage.getItem('materialType');
    const valueProjectStatus = sessionStorage.getItem('projectStatus');
    if (valueMaterialType === null || valueMaterialType === '') {
      this.selectedMaterialTypes = [];
      this.materialType = '';
    }
    if (valueProjectStatus === null || valueProjectStatus === '') {
      this.selectedProjectStatus = [];
      this.projectStatus = '';
    }
    this.projectStatus = projectStatus;
    if (sessionStorage.getItem('currentPage')) {
      this.currentPage = JSON.parse(sessionStorage.getItem('currentPage') as any);
    } else {
      this.currentPage = 1;
    }

    this.columnName = '';
    //  this.searchKeyWord = ''; //commented this to retain the value on the tab section change
    this.showSearchResultCount = false;
  }

  review(projectID: string, soilType: string): void {
    const params = {
      f: 'd',
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
      // Other navigation options can be added here if needed
    };
    const navigateSoilType = soilType === 'Pre Cast' ? 'precast' : 'nativesoil';
    this.formService.updateData(null);
    this.formService.fetchFormData(projectID, navigateSoilType).subscribe({
      next: (data) => {
        if (data && data.samples && Array.isArray(data.samples)) {
          this.filteredNativeSamples = [];
          this.filteredPrecastSamples = [];

          data.samples.forEach((sample: any) => {
            if (soilType === 'Pre Cast') {
              const precastSample = sample as IPrecastSample;
              this.filteredPrecastSamples.push(precastSample);
            } else {
              const nativeSample = sample as INativeSample;
              this.filteredNativeSamples.push(nativeSample);
            }
          });

          // Check if all sampleStatusTypeId values are null for Pre Cast samples
          if (soilType === 'Pre Cast') {
            const allPrecastNull = this.filteredPrecastSamples.every((sample) => sample.sampleStatusTypeId === null);
            if (allPrecastNull) {
              const errorMessage = messagesConfig.viewForm;
              this.showErrorPopUpMessage(errorMessage);
              return;
            }
          } else {
            // Check for Native samples
            const allNativeNull = this.filteredNativeSamples.every((sample) => sample.sampleStatusTypeId === null);
            if (allNativeNull) {
              const errorMessage = messagesConfig.viewForm;
              this.showErrorPopUpMessage(errorMessage);
              return;
            }
          }

          this.router.navigate([`/review/form/${navigateSoilType}/${projectID}`], navigationExtras);
        } else {
          console.log('No valid samples found in the data.');
        }
      },
      error: () => {},
    });
  }

  showErrorPopUpMessage(successMessage: IMessage): void {
    this.errorMsg = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.errorMsg.afterClosed().subscribe((err) => {
      this.errorMsg.close();
    });
  }

  ngOnDestroy() {
    this.destroying$.next(undefined);
    this.destroying$.complete();
    this.projectsSubscription.unsubscribe();
    this.reportDownloadSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.countDown.unsubscribe();
    this.trackEventService.wake$.unsubscribe();
  }

  splitStringByComma(input: string): string[] {
    return input.split(',').map((value) => value.trim());
  }

  clearFilters() {
    this.columnName = '';
    this.selectedMaterialTypes = [];
    this.selectedProjectStatus = [];
    this.materialType = '';
    this.projectStatus = '';
    sessionStorage.removeItem('materialType');
    sessionStorage.removeItem('projectStatus');
    sessionStorage.clear();
    this.getDashboardData();
  }

  removeSearchKeyWord() {
    sessionStorage.removeItem('searchKeyWord');
  }

  onKeyPress(event: KeyboardEvent): void {
    sessionStorage.removeItem('searchKeyWord');
  }

  archiveRecord(projectID: string, soilType: string): void {
    this.deleteRecordDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog',
      data: {
        title: 'Delete record',
        content:
          'This will delete all the project related data including worksheets from every location.'
          + 'Are you sure you want to delete it? Please download a copy of the Project related data in a zipped folder to avoid any loss.',
        cancelText: 'No, do not delete permanently',
        continueText: 'Yes, delete it permanently',
      },
      disableClose: true,
    });
    this.deleteRecordDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        const soilValue: number = soilType === 'Native Soil' ? 1 : 0;
        this.dashboardService
          .softDeleteRecord(projectID, soilValue)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (response) => {
              if (response) {
                this.getDashboardData();
                this.snackBar.open(messagesConfig.softDeleteMessage.message, 'DONE', {
                  duration: 3000,
                  verticalPosition: 'top',
                  panelClass: ['default-snackbar'],
                });
              }
            },
            error: (err) => console.error('Error API:', err),
          });
      }
    });
  }

  restoreRecord(projectID: string): void {
    this.deleteRecordDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog',
      data: {
        title: 'Restore deleted record',
        content: 'Do you want to make the Project record Active?',
        cancelText: 'No, Do not restore it',
        continueText: 'Yes, Restore record',
      },
      disableClose: true,
    });
    this.deleteRecordDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        this.dashboardService
          .restoreDeleteRecord(projectID)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (response) => {
              if (response) {
                this.getDashboardData();
                this.snackBar.open(messagesConfig.restoreDeleteMessage.message, 'DONE', {
                  duration: 3000,
                  verticalPosition: 'top',
                  panelClass: ['default-snackbar'],
                });
              }
            },
            error: (err) => console.error('Error API:', err),
          });
      }
    });
  }

  checkArchiveRecords($event: MatCheckboxChange): void {
    if ($event.checked) {
      this.handleCheck();
    } else {
      this.handleUncheck();
    }
    // Store the checkbox value in session storage
    sessionStorage.setItem('inActiveRecordsCheckboxValue', $event.checked.toString());
  }

  handleCheck() {
    if (this.userService.roleCheck(['Admin'])) {
      this.archiveProjects = false;
      this.isArchiveTabFlagApi = this.archiveProjects;
      this.isDisabledWhenInactiveRecord = true;
      sessionStorage.setItem('isArchiveTabFlagApi', this.isArchiveTabFlagApi.toString());
      this.clearFiltersAndSort();
      this.onPaginationNavigate();
    }
  }

  handleUncheck() {
    if (this.userService.roleCheck(['Admin'])) {
      this.archiveProjects = true;
      this.isDisabledWhenInactiveRecord = false;
      this.isArchiveTabFlagApi = this.archiveProjects;
      sessionStorage.setItem('isArchiveTabFlagApi', this.isArchiveTabFlagApi.toString());
      this.clearFiltersAndSort();
      this.onPaginationNavigate();
    }
  }

  makeRecordInactive(projectID: string): void {
    this.deleteRecordDialogRef = this.dialog?.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog',
      data: {
        title: 'Inactivate the record',
        content: 'This would make the project record inactive, are you sure you want to inactivate it? Kindly confirm.',
        cancelText: 'No, do not make it inactive',
        continueText: 'Yes, make it inactive',
      },
      disableClose: true,
    });
    this.deleteRecordDialogRef?.afterClosed()?.subscribe((response?: string) => {
      if (response === 'YES') {
        this.dashboardService
          .makeRecordInactiveApiCall(projectID)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (response) => {
              if (response) {
                this.getDashboardData();
                this.snackBar.open(messagesConfig.inActivateRecordMessage.message, 'DONE', {
                  duration: 3000,
                  verticalPosition: 'top',
                  panelClass: ['default-snackbar'],
                });
              }
            },
            error: (err) => console.error('Error API:', err),
          });
      }
    });
  }
}
