import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { FormService } from 'src/app/shared/services/form.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ReportService } from 'src/app/features/report/services/report.service';
import { ADMINGENERAL } from 'src/app/shared/models/i-admin-general.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { AssignToLabtechComponent } from 'src/app/shared/components/assign-to-labtech/assign-to-labtech.component';
import { MatDialog } from '@angular/material/dialog';
import { SetStatusComponent } from 'src/app/shared/components/set-status/set-status.component';
import { IDropdown } from 'src/app/shared/models/i-dropdown-type.modal';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { SharedService } from 'src/app/features/service/shared.service';
import { WorksheetResponse } from 'src/app/features/worksheet/models/worksheet-response.modal';
import { INotes } from 'src/app/features/models/inotes.modal';
import { ExportTableService } from 'src/app/features/service/export-table.service';
import { PdfStyles } from '../../constants/pdf-styles-constants';
import { PdfNativeTableColumns, PdfPrecastTableColumns } from '../../constants/pdf-table-columns-connstants';
// eslint-disable-next-line max-len
import { PdfNativeTableHeader,
  PdfNativeMergedHeader,
  PdfPrecastTableHeader,
  PdfPrecastMergedHeader } from '../../constants/pdf-table-header-constants';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
  providers: [DatePipe],
})
export class ViewReportComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  projectId = '';
  soilType = '';
  previewData: REPORTPREVIEW;
  reportId: string;
  address: IADDRESS;
  signature: ISIGNATURE;
  sigArray: ISIGNATURE[];
  title = '';
  projectName = '';
  hasBillingInfo = false;
  PONumber: string;
  selectedIndex = 0;
  savedChangeRecleted = false;
  logo: string;
  forPdfTableDta: any[] = [];
  figureImages: any[] = [];
  imageCount = 0;
  sampleCount = 0;
  sampleCompletedCount = 0;
  statusId: any;
  statusLabel: any;
  reportOwner: any;
  exportShow = false;
  projectData: any;
  allWorksheetApiRes: any;
  headerData: any;
  rowData: any;
  public worksheetRecords!: WorksheetResponse;
  noteText: string | undefined = '';
  note: INotes | undefined;
  geothermField: boolean;
  constructor(
    private adminService: AllAdminService,
    public activatedRoute: ActivatedRoute,
    private formService: FormService,
    private reportService: ReportService,
    private http: HttpClient,
    private datePipe: DatePipe,
    public userService: UserService,
    public dialog: MatDialog,
    private precastService: PrecastService,
    private sharedService: SharedService,
    private exporttableService: ExportTableService,
  ) {}

  ngOnInit(): void {
    const type = this.activatedRoute.snapshot.paramMap.get('type');
    const e = this.activatedRoute.snapshot.queryParamMap.get('edit');
    if (e && e === 'true') {
      this.selectedIndex = 1;
    }
    this.soilType = type !== null ? type : '';
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.projectId = id !== null ? id : '';
    this.getPreviewData();
    this.getProjectInfo(this.projectId, this.soilType);
    this.getAdminDetails();
    this.formService?.updateData(null);
    this.getGraphData();
    this.convertImageToDataURL('/assets/images/p-logo.jpg')
      .then((dataURL) => {
        this.logo = dataURL;
      })
      .catch(() => {});
  }

  getAddress(data: ADMINGENERAL[]): IADDRESS {
    const address: IADDRESS = {
      address: '',
      city: '',
      state: '',
      ph: '',
      fax: '',
      email: '',
      street1: '',
      street2: '',
      weburl: '',
    };
    if (data) {
      data.forEach((elem: ADMINGENERAL) => {
        if (elem.key === 'companyaddress') {
          address.address = elem.value;
        } else if (elem.key === 'city') {
          address.city = elem.value;
        } else if (elem.key === 'state') {
          address.state = elem.value;
        } else if (elem.key === 'contactnumber') {
          address.ph = elem.value;
        } else if (elem.key === 'email') {
          address.email = elem.value;
        } else if (elem.key === 'street1') {
          address.street1 = elem.value;
        } else if (elem.key === 'street2') {
          address.street2 = elem.value;
        } else if (elem.key === 'weburl') {
          address.weburl = elem.value;
        } else if (elem.key === 'fax') {
          address.fax = elem.value;
        }
      });
    }
    return address;
  }

  public getPreviewData(): void {
    const reportData$ = this.reportService?.fetchReportData(this.projectId, this.soilType);
    const signatures$ = this.reportService?.getSignature();
    forkJoin({
      reportData: reportData$,
      signatures: signatures$,
    })?.subscribe({
      next: ({ reportData, signatures }) => {
        this.reportId = reportData.id;
        this.previewData = reportData;
        this.reportOwner = this.previewData.assignedTo;
        this.previewData.reportDate = this.getActualDate(this.previewData.reportDate);
        this.sigArray = signatures;

        const index = this.sigArray.findIndex((x: ISIGNATURE) => x.id === reportData.signatureTypeId);
        if (index > -1) {
          this.signature = this.sigArray[index];
        }
        this.forPdfTableDta = [];
        this.previewData.thermalData.forEach((element) => {
          if (this.soilType === SoilType.Native) {
            this.forPdfTableDta.push({
              sampleId: element.sampleId,
              depth: element.depth,
              effort: element.effort,
              description: element.description,
              wet: element.wet,
              dry: element.dry,
              moistureContent: element.moistureContent,
              density: element.density,
            });
          } else if (this.soilType === SoilType.Precast) {
            this.forPdfTableDta.push({
              sampleId: element.sampleId,
              collectionDate: this.datePipe.transform(element.collectionDate, 'MM/dd/yyyy'),
              matrialType: element.matrialType,
              moistureContent: element.moistureContent,
              density: element.density,
              wet: element.wet,
              dry: element.dry,
            });
          }
        });
        this.reportService.mlGraphData = reportData.mlData;
      },
    });
  }

  openAssignToDialog() {
    this.dialog
      .open(AssignToLabtechComponent, {
        data: {
          projectId: this.projectId,
          type: this.soilType,
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .subscribe((userInfo) => {
        if (userInfo) {
          this.reportOwner = `${userInfo.firstName} ${userInfo.lastName}`;
        }
        this.initialExcelFile();
      });
  }

  openSetStatusDialog() {
    this.dialog
      .open(SetStatusComponent, {
        data: {
          projectId: this.projectId,
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .subscribe((status: any) => {
        if (status.statusId) {
          this.statusId = status?.statusId;
          this.fetchAllStatus();
        }
      });
  }

  private fetchAllStatus(): void {
    this.precastService?.fetchProjectsLookup()?.subscribe({
      next: (lookupResponse: IDropdown[]) => {
        this.statusLabel = lookupResponse.find((status) => (status.id === this.statusId ? status : ''))?.label;
      },
    });
  }

  getProjectInfo(projectId: string, soilType: string): void {
    this.formService?.fetchFormData(projectId, soilType)?.subscribe({
      next: (data) => {
        this.worksheetRecords = data;
        this.reportService.projectInformation = data;
        this.hasBillingInfo = data.company.hasBillingInfo;
        this.projectName = data?.project?.projectName;
        this.PONumber = data.project.projectNumber;
        this.statusId = data.project.projectStatusTypeId;
        const dueDate = data.project.dueDate ? data.project.dueDate : '';
        this.geothermField = data.project.geothermField;
        this.exportShow = this.geothermField;
        const date = new Date(dueDate);
        this.title = `${data?.company?.name}-${data?.project?.projectName}-${
          soilType === SoilType.Precast ? 'PC' : 'NS'
        }`;
        if (data?.project?.projectNumber) {
          this.title = `${this.title}-${data?.project?.projectNumber}`;
        }
        const formatedDueDate = dueDate
          ? `-${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date
            .getFullYear()
            .toString()}`
          : '';
        this.title = `${this.title}${formatedDueDate}`;
        this.fetchAllStatus();
        if (this.geothermField) {
          this.initialExcelFile();
        }
      },
      error: () => {},
    });
  }

  getAdminDetails() {
    this.adminService?.getAdminDetails()?.subscribe((data: any) => {
      this.address = this.getAddress(data);
    });
  }

  getUpdatedPriviewData(): void {
    this.reportService?.fetchReportData(this.projectId, this.soilType)?.subscribe({
      next: (reportData) => {
        this.previewData = reportData;
        this.previewData.reportDate = this.getActualDate(this.previewData.reportDate);
        const index = this.sigArray.findIndex((x: ISIGNATURE) => x.id === reportData.signatureTypeId);
        if (index > -1) {
          this.signature = this.sigArray[index];
        }
      },
    });
  }

  changeTab(tabGroup: MatTabGroup, event: boolean) {
    if (event) {
      this.getUpdatedPriviewData();
    }
    tabGroup.selectedIndex = 0;
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      if (this.savedChangeRecleted) {
        this.getUpdatedPriviewData();
        this.savedChangeRecleted = false;
      }
    }
  }

  getSavedNotify(event: boolean): void {
    this.savedChangeRecleted = event;
  }

  getActualDate(date: string | Date): Date {
    const utcDate = new Date(date);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    return localDate;
  }

  getMainBody() {
    if (this.soilType === SoilType.Precast) {
      return this.getMainBodyPrecast();
    }
    return this.getMainBodyNativeSoil();
  }

  getMainBodyNativeSoil() {
    const mainBody = [];
    mainBody.push({
      text: `${this.previewData.mainBodyIntro}`,
      style: 'mainBody',
    });
    mainBody.push(
      {
        text: [
          {
            text: 'Thermal Resistivity Tests: ',
            bold: true,
            decoration: 'underline',
            style: 'mainBodyTitle',
          },
          {
            text: `${this.previewData.mainBody}`,
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 10],
      },
      {
        text: 'Sample ID, Description, Thermal Resistivity, Moisture Content and Density',
        style: 'sampleHead',
      },
    );
    return mainBody;
  }

  getMainBodyPrecast() {
    const mainBody = [];
    mainBody.push(
      {
        text: [
          {
            text: `${this.previewData.mainBody}`,
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 10],
      },
      {
        text: 'Test Results: (Average of Cylinders)  ',
        style: 'sampleHead',
      },
    );
    return mainBody;
  }

  getCommentsAdded() {
    if (this.soilType === SoilType.Precast) {
      return this.getCommentAddedPrecast();
    }
    return this.getCommentAddedNative();
  }

  getCommentAddedPrecast() {
    const comments = [
      {
        text: [
          {
            text: 'Comments: ',
            bold: true,
            decoration: 'underline',
            style: 'mainBodyTitle',
          },
        ],
        margin: [30, 10, 30, 5],
      },
    ];
    if (this.previewData.commentPart1) {
      comments.push({
        text: [
          {
            text: `Concrete: ${this.previewData.commentPart1}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart2) {
      comments.push({
        text: [
          {
            text: `FTB: ${this.previewData.commentPart2}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart3) {
      comments.push({
        text: [
          {
            text: `Grout/Slurry: ${this.previewData.commentPart3}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    if (this.previewData.commentPart4) {
      comments.push({
        text: [
          {
            text: `Other: ${this.previewData.commentPart4}`,
            bold: false,
            decoration: '',
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    comments.push({
      text: [
        {
          text: `${this.previewData.commentPart5}`,
          bold: false,
          decoration: '',
          style: 'mainBody',
        },
      ],
      margin: [30, 0, 30, 0],
    });
    return comments;
  }

  getCommentAddedNative() {
    const comments = [];
    if (this.previewData.commentPart1) {
      comments.push({
        text: [
          {
            text: `Comments:${this.previewData.commentPart1}`,
            style: 'mainBody',
          },
        ],
        margin: [30, 0, 30, 0],
      });
    }
    comments.push({
      text: [
        {
          text: `${this.previewData.commentPart2}`,
          style: 'mainBody',
        },
      ],
      margin: [30, 0, 30, 0],
    });
    return comments;
  }

  getSignature() {
    const signature = [];
    signature.push({
      text: [
        {
          text: 'Geotherm USA, LLC',
          style: 'mainBody',
        },
      ],
      margin: [30, 5, 30, 0],
    });
    signature.push({
      image: `data:image/png;base64,${this.signature.signatureImage}`,
      width: 80,
      margin: [30, 5, 30, 0],
    });

    signature.push({
      text: [
        {
          text: `${this.signature.label}`,
          style: 'mainBody',
        },
      ],
      margin: [30, 5, 30, 0],
      pageBreak: 'after',
    });
    return signature;
  }

  public export(): void {
    const figureImages = [];
    for (let i = 0; i < this.imageCount; i++) {
      const canvas = document.getElementById(`canvas${i}`) as HTMLCanvasElement;
      figureImages.push({
        image: canvas.toDataURL('image/png', 1.0),
      });
    }
    this.figureImages = [...figureImages];

    let PdfTableColumns;
    if (this.soilType === SoilType.Native) {
      PdfTableColumns = PdfNativeTableColumns;
    } else if (this.soilType === SoilType.Precast) {
      PdfTableColumns = PdfPrecastTableColumns;
    }

    const docDefinition = {
      footer: () => [
        {
          text: 'COOL SOLUTIONS FOR UNDERGROUND POWER CABLES',
          style: 'footer',
        },
        {
          text: 'THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION',
          style: 'footer',
        },
        {
          text: 'Serving the electric power industry since 1978',
          style: 'footer1',
        },
      ],
      content: [
        ...this.getLogoAndAdress(),
        {
          text: `${this.datePipe.transform(this.previewData.reportDate, 'mediumDate')}`,
          style: 'datebold',
          margin: [30, 25, 0, 0],
        },
        {
          text: `${this.previewData.companyName}`,
          style: 'bold',
          margin: [30, 15, 0, 0],
        },
        {
          text: `${this.previewData.streetAddress}`,
          margin: [30, 0, 0, 0],
        },
        {
          text: `${this.previewData.cityState}`,
          margin: [30, 0, 0, 0],
        },
        {
          text: `Attn: ${this.previewData.contactName}`,
          style: 'datebold',
          decoration: 'underline',
          margin: [30, 0, 0, 0],
        },
        {
          text: `${this.previewData.header}`,
          style: 'centerTitle',
          margin: [30, 10, 0, 0],
        },
        {
          text: `${this.previewData.subHeader}`,
          style: 'centerTitle2',
          decoration: 'underline',
          margin: [30, 0, 0, 10],
        },
        ...this.getMainBody(),

        this.table([...this.forPdfTableDta], PdfTableColumns),
        this.getCommentsAdded(),
        this.getSignature(),
        ...this.putImagesInPdf(),
      ],
      styles: PdfStyles as any,
    };

    pdfMake.createPdf(docDefinition).download(this.title);
  }

  convertImageToDataURL(imagePath: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http
        .get(imagePath, {
          responseType: 'blob',
        })
        .subscribe(
          (blob: Blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  setTableHeader(body: any, soilType: string): void {
    if (soilType === SoilType.Native) {
      body.push(PdfNativeTableHeader);
      body.push(PdfNativeMergedHeader);
    } else if (soilType === SoilType.Precast) {
      body.push(PdfPrecastTableHeader);
      body.push(PdfPrecastMergedHeader);
    }
  }

  buildTableBody(data: any, columns: any) {
    const body: any[] = [];
    this.setTableHeader(body, this.soilType);
    data.forEach((row: any) => {
      const dataRow: any = [];
      columns.forEach((column: any) => {
        dataRow.push({
          text: row[column].toString(),
          style: 'tableDataStyle',
        });
      });

      body.push(dataRow);
    });

    return body;
  }

  table(data: any, columns: any) {
    return {
      table: {
        headerRows: 2,
        body: this.buildTableBody(data, columns),
      },
      margin: [30, 0, 30, 0],
    };
  }

  getImageCount(event: number): void {
    this.imageCount = event;
  }

  putImagesInPdf() {
    const date = this.datePipe.transform(this.previewData.reportDate, 'MMMM d, y');
    const imageArray: any[] = [];
    let pageBreaks = 'after';
    this.figureImages.forEach((images, i) => {
      if (i === this.figureImages.length - 1) {
        pageBreaks = '';
      }
      imageArray.push(
        {
          image: this.logo,
          width: 150,
          margin: [30, 0, 0, 20],
        },
        {
          image: images.image,
          width: 400,
          height: 500,
          style: 'imageStyle',
          margin: [0, 30, 0, 0],
        },
        {
          text: `${this.previewData.companyName} - ${this.previewData.subHeader}`,
          style: 'canvasStylef',
        },
        {
          text:
            this.soilType === SoilType.Native
              ? 'Thermal Analysis of Native Soil Samples'
              : 'Thermal Analysis of Precast Samples',
          style: 'canvasStyle',
        },
        {
          columns: [
            {
              stack: [
                {
                  text: `Figure ${i + 1}`,
                  style: 'figureStyle',
                },
              ],
            },
            {
              text: date,
              style: 'bottomRightDate',
            },
          ],
          pageBreak: pageBreaks,
        },
      );
    });
    return imageArray;
  }

  getLogoAndAdress() {
    const lAndA = [];
    lAndA.push({
      columns: [
        {
          image: this.logo,
          width: 200,
          margin: [30, 0, 0, 0],
        },
        {
          stack: [
            {
              text: `${this.address.street1}, ${this.address.street2}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `${this.address.city}, ${this.address.state}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `Tel: ${this.address.ph}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `Fax: ${this.address.fax}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
            },
            {
              text: `${this.address.email}`,
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
              style: 'blueLink',
            },
            {
              text: `${this.address.weburl}`,
              style: 'blueLink',
              alignment: 'left',
              margin: [125, 0, 0, 0],
              fontSize: 11,
              link: `${this.address.weburl}`,
            },
          ],
        },
      ],
    });
    return lAndA;
  }

  getGraphData(): void {
    this.reportService.getGraphData(this.projectId, this.soilType)?.subscribe({
      next: (data) => {
        data.forEach((graphDataArray: any[]) => {
          let completed = true;
          graphDataArray.forEach((each) => {
            if (!each.graphStatus) {
              completed = false;
            }
          });
          this.sampleCount += 1;
          if (completed) {
            this.sampleCompletedCount += 1;
          }
        });
      },
      error: () => {},
    });
  }

  initialExcelFile() {
    this.sharedService.fetchAllTabsData();
    this.sharedService.getNativeSoilWs(this.projectId);
    this.sharedService.fetchWSNotes(this.projectId);
    this.sharedService.headerData$.subscribe((data) => {
      this.headerData = data;
    });
    this.sharedService.rowData$.subscribe((data) => {
      this.rowData = data;
    });
    this.sharedService.notes$.subscribe((data) => {
      this.note = data;
    });
    this.sharedService.notesText$.subscribe((data) => {
      this.noteText = data;
    });
  }

  exportcsv() {
    const notesUpdatedBy = this.note?.updatedBy?.userName;
    this.exporttableService.openInNewTab(
      this.headerData,
      this.rowData,
      this.worksheetRecords,
      notesUpdatedBy,
      this.noteText,
      this.soilType,
    );
  }
}
