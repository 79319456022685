import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-report-modal',
  templateUrl: './manage-report-modal.component.html',
  styleUrls: ['./manage-report-modal.component.scss'],
})
export class ManageReportModalComponent {
  config: any;
  constructor(
    private mdr: MatDialogRef<ManageReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: MatDialog,
  ) {
    this.config = data;
  }

  closeDialog(): void {
    this.mdr?.close(false);
  }
}
