<div class="gcontainer review-container" #reviewpage *ngIf="formData">
  <div class="r-header-wrapper">
    <div class="no-print">
      <nav aria-label="breadcrumb" class="bredcrums" *ngIf="userAccount">
        <ol class="breadcrumb">
          <li *ngIf="fromWhere === 'd'" class="breadcrumb-item active breadcrumb cursor" aria-current="page">
            <a class="c-primary" [routerLink]="'/dashboard'" tabindex="0"> Dashboard </a>
          </li>
          <li *ngIf="fromWhere === 'd'" class="breadcrumb-item active" aria-current="page">
            Review Form - <span *ngIf="soilType === 'precast'">Pre Cast</span>
            <span *ngIf="soilType === 'nativesoil'">Native Soil</span>
          </li>
          <li *ngIf="fromWhere === 'w'" class="breadcrumb-item active breadcrumb cursor" aria-current="page">
            <a class="c-primary" [routerLink]="'/dashboard'" tabindex="0"> Dashboard </a>
          </li>
          <li *ngIf="fromWhere === 'w'" class="breadcrumb-item active breadcrumb cursor" aria-current="page">
            <a class="c-primary" (click)="gotoWorksheet()" tabindex="0" (keydown)="gotoWorksheet()">
              {{ formData.company.name }} - {{ formData.project.projectName }} - Worksheet -
              <span *ngIf="soilType === 'precast'">Pre Cast</span>
              <span *ngIf="soilType === 'nativesoil'">Native Soil</span>
            </a>
          </li>
          <li *ngIf="fromWhere === 'w'" class="breadcrumb-item active" aria-current="page">Review Form</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h1 class="header-title">{{ title }}</h1>
      </div>
      <h2 class="no-print c-primary" *ngIf="!printSuccess && !projectId">Please review the submission form</h2>
      <h2 class="no-print h-success" *ngIf="showPrintSuccessMessage">Submission Successful</h2>
    </div>
    <div class="row">
      <form [formGroup]="Inhouseform">
        <div class="col-md-12">
          <div class="manageProjects">
            <p class="heading" *ngIf="!projectId">Attachments</p>
            <p class="heading" *ngIf="projectId">Manage Project</p>
            <div class="row">
              <div [ngClass]="{
                'col-3': isDueDateSet,
                'col-5': !isDueDateSet
              }" class="project-column">
                <!-- <span class="view-notes" (click)="openDialog(projectId)" *ngIf="projectId && notes &&!editViewSubmission">
                <img class="notes-img" src="/assets/icons/Comment.svg" alt="Comment icon" />
                &nbsp;View Notes ({{ getNotesCount() }})
              </span> -->
                <app-customer-attachments (selectedAttachment)="selectedAttachment($event)" [projectId]="projectId"
                  [backgroundClass]="'yellow'" [showSuccess]="showPrintSuccessMessage"
                  style="vertical-align: middle; margin-right: 16px"></app-customer-attachments>
              </div>
              <div [ngClass]="{
                  'col-5': projectId && soilType === 'precast',
                  'col-7':
                    (!projectId && soilType === 'precast') ||
                    (projectId && soilType !== 'precast') ||
                    (!projectId && soilType !== 'precast')
                }" class="project-column">
                <mat-chip class="chipset-green float-right" *ngIf="formData.project.geothermField">
                  <img src="./../../../assets/icons/pre-approval-tick.svg" class="icon-position"
                    alt="Geo-Testing-Success" />
                  <span class="text-white chip-text">Geotherm Field Testing </span>
                </mat-chip>

                <mat-chip class="chipset-blue float-right" *ngIf="formData.project.preApproval">
                  <img src="./../../../assets/icons/pre-approval-tick.svg" class="icon-position"
                    alt="Rush-Testing-Warning" />
                  <span class="text-white chip-text"> Pre-Approval</span>
                </mat-chip>
                <mat-chip class="chipset-warning float-right" *ngIf="formData.project.rushTesting">
                  <img src="./../../../assets/icons/rush-warning-icon.svg" class="icon-position"
                    alt="Rush-Testing-Warning" />
                  <span class="chip-text"> Rush</span>
                </mat-chip>
              </div>
              <div class="col-2" style="margin-top: -15px;" *ngIf="isDueDateSet">
                <mat-label  class="chipset-warning float-left due-date">Due Date</mat-label>
                <mat-form-field appearance="outline" class="float-right" style="width: auto;">
                  <input
                    matInput
                    (dateChange)="onDueDateChange()"
                    [matDatepicker]="picker"
                    formControlName="dueDate"
                    [min]="yesterday"
                    readonly
                    />

                    <!-- (dateChange)="onDueDateChange()" -->
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-2" *ngIf="projectId && soilType === 'precast'">
                <mat-label class="mat-label custom-col-prefix">Sample Prefix</mat-label>
                <mat-form-field appearance="outline" [ngClass]="{ 'disabled-form-field': disablePrefix }">
                  <input matInput formControlName="inHouseIdPrefix" (input)="onPrefixChange($event)"
                    [readonly]="disablePrefix" appAlphabetic [maxLength]="2" />
                  <button mat-icon-button matSuffix class="search-icon" *ngIf="shouldShowSearchIcon()"
                    (click)="checkInhouseId()">
                    <img src="/assets/icons/commit.svg" alt="commit" />
                  </button>

                  <span matSuffix class="done-icon" *ngIf="shouldShowDoneIcon()">
                    <img src="/assets/icons/checked.svg" alt="done" />
                  </span>
                </mat-form-field>
                <span class="error" *ngIf="shouldShowError()"> Prefix Unavailable to Take</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="re-content-wrapper">
      <div class="col-md-12" *ngIf="projectId">
        <mat-checkbox color="primary" class="checkbox-left" [disabled]="instructionData.instructionsConfirmed === true"
          *ngIf="soilType === 'nativesoil'" [(ngModel)]="instructionsReceived">
          Instructions Received
        </mat-checkbox>
      </div>
      <app-company-contact-information *ngIf="companyInformation" [returnUrl]="fromWhere"
        [enableEditButton]="enableCompanyEdit | async" [data]="companyInformation" [billing]="billingInformation"
        [link]="editLink" [samples]="viewSubmissionInfo"
        (submitForm)="handleSubmitForm($event)"></app-company-contact-information>
      <div class="spacer"></div>
      <app-sample-information [returnUrl]="fromWhere" [enableEditButton]="enableSampleEdit | async" [link]="editLink"
        [title]="sampleTitle" [headerColumns]="headerColumns" [displayedColumns]="displayedColumns"
        [dataSource]="dataSource" [data]="companyInformation" [Type]="soilType" [sampleViewInfo]="tableViewSubmission"
        (samplesChange)="handleSamplesChange($event)"></app-sample-information>
      <div class="spacer"></div>
      <app-instructions-sample-type [returnUrl]="fromWhere" [enableEditButton]="enableInstructionEdit | async"
        *ngIf="instructionData" [link]="editLink" [title]="instructionTitle" [data]="instructionData" [Type]="soilType"
        [commentData]="viewSubmissionInfo" [companyData]="companyInformation"
        (submitForm)="handleComment($event)"></app-instructions-sample-type>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <div class="btn-wrapper">
        <div class="no-print btn-left">
          <button *ngIf="cancelOrClose" mat-raised-button (click)="close()">CLOSE</button>
          <button *ngIf="(enableSampleEdit && !cancelOrClose && !projectId) || (projectId && editViewSubmission)"
            mat-raised-button (click)="onCancel()">
            CANCEL
          </button>
          <button *ngIf="!enableSampleEdit" mat-raised-button (click)="back()">BACK</button>
        </div>
        <div class="no-print btn-container btn-right">
          <button mat-raised-button (click)="print()" *ngIf="!projectId || (projectId && editViewSubmission)">
            <img src="/assets/icons/Print-red.svg" alt="print" />
            PRINT
          </button>
          <button class="edit-btn" mat-raised-button
            *ngIf="(showSubmitButton && !projectId) || (projectId && editViewSubmission)" (click)="editSubmission()">
            EDIT
          </button>
          <button mat-raised-button *ngIf="projectId && !editViewSubmission && roleCheck(['Admin', 'Manager'])"
            (click)="onSubmitWorksheet()">
            SAVE
          </button>
          &nbsp;&nbsp;
          <button *ngIf="(showSubmitButton && !projectId) || (projectId && editViewSubmission)"
            [disabled]="!enableSubmitButton" mat-raised-button color="primary" (click)="onSubmit()">
            {{ submitText }}
          </button>
          <button *ngIf="projectId && !editViewSubmission" [matMenuTriggerFor]="aboveMenu"
            [disabled]="!enableSubmitButton" mat-raised-button color="primary">
            ACTIONS
          </button>
          <mat-menu class="dashboard-menu menu-border" #aboveMenu="matMenu" yPosition="below">
            <button *ngIf="roleCheck(['Reportwriter', 'Admin', 'Manager'])" mat-menu-item (click)="editSubmission()">
              <span class="menu-text">
                <img src="/assets/icons/Edit.svg" alt="File" />
                Edit Submission Form
              </span>
            </button>
            <button mat-menu-item *ngIf="false">
              <span class="menu-text">
                <img src="/assets/icons/scale.svg" alt="Done-icon" />
                Shipment Weight Received
              </span>
            </button>
            <button mat-menu-item *ngIf="roleCheck(['Admin', 'Manager'])" (click)="previewOpenDialog(projectId)"
              [disabled]="enableMissingButton">
              <span class="menu-text">
                <img src="/assets/icons/Alert-3.svg" alt="Notification-icon" />
                Notify Issues or Missing Data
              </span>
            </button>
            <button mat-menu-item (click)="viewGenerateSheet()" *ngIf="roleCheck(['Admin', 'Manager'])">
              <span class="menu-text">
                <img src="/assets/icons/Bar-Chart-2.svg" alt="Notification-icon" />
                {{
                !this.generateFlag || (companyInformation && !companyInformation.worksheetGenerated)
                ? 'Generate Worksheet'
                : 'View Worksheet'
                }}
              </span>
            </button>
            <button mat-menu-item (click)="softDelete(projectId)" *ngIf="roleCheck(['Admin'])">
              <span class="menu-text">
                <img src="/assets/icons/Delete.svg" alt="Delete-icon" />
                Delete
              </span>
            </button>
          </mat-menu>
          <p class="c-primary" *ngIf="showPrintHints">
            Please print this page first to be included with your sample shipment before submitting.
          </p>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>