import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { FormService } from 'src/app/shared/services/form.service';
import { ChartOptions, ChartDataset, Chart } from 'chart.js';
import { IPoints } from 'src/app/shared/models/graph-points.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ValidateOneDecimal } from 'src/app/shared/validators/one-decimal.validator';
import { ReportService } from '../../services/report.service';
import { MlErrorComponent } from '../ml-error/ml-error.component';

@Component({
  selector: 'app-thermal-resistivity',
  templateUrl: './thermal-resistivity.component.html',
  styleUrls: ['./thermal-resistivity.component.scss'],
  providers: [DatePipe],
})
export class ThermalResistivityComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  chart: Chart;
  projectId = '';
  soilType = '';
  projectName = '';
  public lineChartData: ChartDataset[] = [];
  public storedlineChartData: ChartDataset[] = [];
  form: FormGroup;
  showForm = false;
  graphData: FormArray;
  leftBorderColor = ['#E43131', '#3FC828', '#19B7E9', '#AD1CE0', '#9F3A0F', '#000000'];
  drawerActive = false;
  maxTr = 0;
  maxMc = 0;
  graphDataLengthArray: number[] = [];
  splitFigureIndex = 0;
  splitFigureData: any[] = [];
  prevDisabled = true;
  nextDisabled = false;
  splitCount = 6;
  figureCountArray = [];
  figureIndicatorIndex = 0;
  figureCompletedCount = 0;
  markAllComplete = false;
  figureCompletedArray: (boolean | null)[] = [];
  sampleCompletedCount = 0;
  sampleCount = 0;
  graphDataProcessCompleted = false;
  textDataArray: any[] = [];
  public mlDataArray: ChartDataset[] = [];
  public activeLineChartData: ChartDataset[] = [];
  figureClicked = false;
  data: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
    const type = this.activatedRoute.snapshot.paramMap.get('type');
    this.soilType = type !== null ? type : '';
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.projectId = id !== null ? id : '';
    if (!this.reportService.projectInformation) {
      this.getProjectInfo(this.projectId, this.soilType);
    } else {
      this.projectName = this.reportService.projectInformation.project.projectName;
    }
    // this.splitCount = this.soilType === SoilType.Native ? 1 : 6;
    this.getGraphData();
    this.getPreviewData();
  }

  getProjectInfo(projectId: string, soilType: string): void {
    this.formService?.fetchFormData(projectId, soilType)?.subscribe({
      next: (data) => {
        this.reportService.projectInformation = data;
        this.projectName = this.reportService.projectInformation.project.projectName;
      },
      error: () => {},
    });
  }

  getSampleCompletedCount() {
    this.sampleCompletedCount = 0;
    this.sampleCount = 0;
    this.splitFigureData.forEach((items: any) => {
      let completed = true;
      items.forEach((obj: any) => {
        if (!obj.graphStatus) {
          completed = false;
        }
      });
      this.sampleCount += 1;
      if (completed) {
        this.sampleCompletedCount += 1;
      }
    });
  }

  getFigureCompletedCount() {
    let figCompleteCheck = true;
    let initialSize = 0;
    this.figureCountArray.forEach((each, i: number) => {
      let figCompleted: boolean | null = true;
      figCompleteCheck = true;
      let size = (i + 1) * this.splitCount;
      if (size >= this.splitFigureData.length) {
        size = this.splitFigureData.length;
      }
      // implementation of figure completed
      const splitArray = this.splitFigureData.slice(initialSize, size);
      splitArray.forEach((items: any) => {
        figCompleted = true;
        items.forEach((obj: any) => {
          if (obj.graphStatus === null) {
            figCompleted = null;
          } else if (!obj.graphStatus) {
            figCompleted = false;
          }
        });
        if (!figCompleted) {
          figCompleteCheck = false;
        }
      });

      this.figureCompletedArray[i] = figCompleteCheck;
      initialSize = size;
    });

    if (this.figureCountArray.length === 0) {
      this.splitFigureData.forEach((items: any) => {
        let figCompleted: boolean | null = true;
        items.forEach((obj: any) => {
          if (obj.graphStatus === null) {
            figCompleted = null;
          } else if (!obj.graphStatus) {
            figCompleted = false;
          }
        });
        this.figureCompletedArray[0] = figCompleted;
      });
    }
    const markAllComplete = this.figureCompletedArray[this.getFigureCount(this.splitFigureIndex) - 1];
    if (markAllComplete) {
      this.markAllComplete = true;
    } else {
      this.markAllComplete = false;
    }
  }

  getGraphData(): void {
    this.reportService.getGraphData(this.projectId, this.soilType)?.subscribe({
      next: (graphData) => {
        // this.sampleCount += 1;
        this.figureCompletedCount = 0;
        this.textDataArray = [];
        const allGraphData: any[] = [];
        let figLength = 1;
        if (graphData.length > this.splitCount) {
          figLength = graphData.length % this.splitCount === 0
            ? graphData.length / this.splitCount
            : Math.floor(graphData.length / this.splitCount) + 1;
          if (figLength > 0) {
            this.figureCountArray = Array.from({
              length: figLength,
            });
          }
        }
        this.figureCompletedArray = this.figureCountArray;
        const dataLength: number[] = [];
        this.data = graphData;
        this.data.forEach((graphDataArray: any[], i: number) => {
          const graphPoints: IPoints[] = [];
          graphDataArray.reverse();
          dataLength.push(graphDataArray.length);
          this.lineChartData.push({
            type: 'line',
            data: [],
            tension: 0.3,
            borderColor: this.leftBorderColor[i % this.splitCount],
            borderDash: [0, 0],
          });
          graphDataArray.forEach((each) => {
            if (this.soilType === SoilType.Native) {
              if (graphDataArray[0].worksheetType === 'Bulk') {
                graphPoints.push({
                  x: each.labSpecMoistureContent,
                  y: each.wetTr,
                });
              } else {
                graphPoints.push({
                  x: each.moistureContent,
                  y: each.wetTr,
                });
              }
            } else if (this.soilType === SoilType.Precast) {
              graphPoints.push({
                x: each.moisture,
                y: each.wetTr,
              });
            }
          });

          if (this.soilType === SoilType.Native) {
            graphDataArray.unshift({
              labSpecMoistureContent: 0,
              wetTr: graphDataArray[0].dryTr,
              clientDescription: graphDataArray[0].clientDescription,
              density: graphDataArray[0].density,
              dryTr: graphDataArray[0].dryTr,
              graphStatus: graphDataArray[0].graphStatus,
              sampleId: graphDataArray[0].sampleId,
              worksheetType: graphDataArray[0].worksheetType,
              moistureContent: 0,
              dryPlusTare: graphDataArray[0].dryPlusTare,
            });
          } else if (this.soilType === SoilType.Precast) {
            graphDataArray.unshift({
              moisture: 0,
              wetTr: graphDataArray[0].dryTr,
              sampleType: graphDataArray[0].sampleType,
              density: graphDataArray[0].density,
              dryTr: graphDataArray[0].dryTr,
              graphStatus: graphDataArray[0].graphStatus,
              sampleId: graphDataArray[0].sampleId,
              dryPlusTare: graphDataArray[0].dryPlusTare,
            });
          }

          graphPoints.unshift({
            x: 0,
            y: graphDataArray[1]?.dryTr,
          });
          this.lineChartData[i].data = graphPoints;
          allGraphData.push(graphDataArray);
        });
        this.storedlineChartData = JSON.parse(JSON.stringify(this.lineChartData));
        this.showForm = true;
        this.splitFigureData = allGraphData;
        if (this.reportService.mlGraphData) {
          this.createFormAndGraph(this.splitFigureIndex);
        }
        // this.graphDataLengthArray = dataLength;
        if (this.splitFigureIndex === 0) {
          if (this.figureCountArray.length === 0) {
            this.nextDisabled = true;
          }
          if (this.splitFigureIndex === this.splitFigureData.length - 1) {
            this.prevDisabled = true;
          }
        }
        this.getSampleCompletedCount();
        this.getFigureCompletedCount();
        this.graphDataProcessCompleted = true;
      },
      error: () => {},
    });
  }

  createFormAndGraph(splitIndex: number) {
    const mlDataArray: ChartDataset[] = [];
    if (this.chart) {
      this.chart.destroy();
    }
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
    const dataToLoad = this.splitFigureData.slice(splitIndex, splitIndex + 1 * this.splitCount);
    let mlData: any;
    let validationsMaxMin: ValidatorMaxMin;
    dataToLoad.forEach((outer: any, i: number) => {
      const graphPoints: IPoints[] = [];
      this.addItem();
      if (this.soilType === SoilType.Native) {
        if (outer[0].worksheetType === 'Bulk') {
          validationsMaxMin = {
            minMoisture: 0,
            maxMoisture: outer[outer.length - 1].labSpecMoistureContent - 0.1,
          };
        } else {
          validationsMaxMin = {
            minMoisture: 0,
            maxMoisture: outer[outer.length - 1].moistureContent - 0.1,
          };
        }
        // eslint-disable-next-line no-nested-ternary
        const depthString = outer[1]?.stockPile
          ? ' - Stockpile'
          : outer[1]?.depth
            ? ` - ${outer[1]?.depth}'-${outer[1]?.endDepth}'`
            : '';
        this.textDataArray.push({
          name: outer[1].sampleId.substring(0, 6),
          lineText: `${outer[1].sampleId} - @${outer[1].effort}%${depthString}`,
        });
      } else if (this.soilType === SoilType.Precast) {
        validationsMaxMin = {
          minMoisture: 0,
          maxMoisture: outer[outer.length - 1].moisture - 0.1,
        };
        this.textDataArray.push({
          name: outer[1].sampleId.substring(0, 6),
          lineText: `${outer[1].inHouseId} - ${this.datePipe.transform(outer[1].castDate, 'MM/dd/yy')} - ${
            outer[1].sampleType
          }`,
        });
      }

      outer.forEach((inner: any, j: number) => {
        if (j === outer.length - 1) {
          validationsMaxMin.maxMoisture += 0.1;
        }
        this.addControl(i, inner.worksheetType, validationsMaxMin);
        if (this.reportService.mlGraphData && this.reportService.mlGraphData.responseSamples !== null) {
          const mlDataFindIndex = this.reportService.mlGraphData.responseSamples.findIndex(
            (x: any) => x.worksheetId === inner.id,
          );
          if (mlDataFindIndex > -1) {
            mlData = this.reportService.mlGraphData.responseSamples[mlDataFindIndex];
          }
        }
      });

      if (mlData && mlData.graphData && mlData.graphData.length > 0) {
        mlData.graphData.forEach((x: any) => {
          graphPoints.push({
            x: x.moisture,
            y: x.tr,
          });
        });
      }

      mlDataArray.push({
        type: 'line',
        data: graphPoints,
        tension: 0.3,
        borderColor: this.leftBorderColor[i],
        borderDash: [5, 5],
      });
    });
    this.activeLineChartData = this.lineChartData.slice(splitIndex, splitIndex + 1 * this.splitCount);
    const patchValue = {
      items: dataToLoad,
    };
    this.form.patchValue(patchValue);
    this.mlDataArray = mlDataArray;
    this.mlDataArray.forEach((each: any) => {
      this.activeLineChartData.push({
        type: 'line',
        data: [],
        tension: 0.3,
        borderColor: each.borderColor,
        borderDash: [5, 5],
      });
    });
    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'scatter',
      data: {
        datasets: this.activeLineChartData,
      },
      options: this.setChartOptions(),
    });
    this.maxTr = this.checkLegendChange() * 50;
    this.maxMc = this.checkLegendChange() * 5;
  }

  findMax(old: number, latest: number): number {
    return old < latest ? latest : old;
  }

  setChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: true,
          displayColors: false,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'THERMAL DRYOUT CURVE',
          color: 'black',
        },
      },
      scales: {
        y: {
          min: 0,
          max: this.checkLegendChange() * 50,
          title: {
            display: true,
            text: 'THERMAL RESISTIVITY (°C -cm/W)',
            color: 'black',
            font: {
              weight: 'bold',
            },
          },

          ticks: {
            stepSize: 10,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            major: {
              enabled: true,
            },
            color: 'black',
          },
        },
        x: {
          min: 0,
          max: this.checkLegendChange() * 5,
          title: {
            display: true,
            text: 'MOISTURE CONTENT (% DRY WEIGHT)',
            color: 'black',
            font: {
              weight: 'bold',
              style: 'normal',
            },
          },
          ticks: {
            stepSize: 1,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            color: 'black',
          },
        },
      },
    };
  }

  createNativeItem(worksheetType: string, validationsMaxMin: ValidatorMaxMin): FormGroup {
    if (worksheetType === 'Bulk') {
      return this.formBuilder.group({
        projectId: [null],
        id: [null],
        nativeSoilSampleId: [null],
        labSpecMoistureContent: [
          null,
          [
            Validators.required,
            ValidateOneDecimal(),
            Validators.max(validationsMaxMin.maxMoisture),
            Validators.min(validationsMaxMin.minMoisture),
          ],
        ],
        wetTr: [null, Validators.required],
        clientDescription: '',
        density: null,
        graphStatus: null,
        wetPlusTare: [],
        drySoil: [null],
        mouldPlusProbeTare: [null],
        dryTr: [null],
        sampleId: [null],
        worksheetType: [null],
        moistureContent: [null],
        dryPlusTare: [null],
      });
    }
    return this.formBuilder.group({
      projectId: [null],
      id: [null],
      nativeSoilSampleId: [null],
      labSpecMoistureContent: [null],
      wetTr: [null, Validators.required],
      clientDescription: '',
      density: null,
      graphStatus: null,
      wetPlusTare: [],
      drySoil: [null],
      mouldPlusProbeTare: [null],
      dryTr: [null],
      sampleId: [null],
      worksheetType: [null],
      moistureContent: [
        null,
        [
          Validators.required,
          ValidateOneDecimal(),
          Validators.max(validationsMaxMin.maxMoisture),
          Validators.min(validationsMaxMin.minMoisture),
        ],
      ],
      dryPlusTare: [null],
    });
  }

  createPrecastItem(validationsMaxMin: ValidatorMaxMin): FormGroup {
    return this.formBuilder.group({
      projectId: [null],
      id: [null],
      preCastSampleId: [null],
      moisture: [
        null,
        [
          Validators.required,
          ValidateOneDecimal(),
          Validators.max(validationsMaxMin.maxMoisture),
          Validators.min(validationsMaxMin.minMoisture),
        ],
      ],
      wetTr: [null, [Validators.required]],
      sampleType: '',
      density: null,
      graphStatus: null,
      wetPlusTare: [],
      drySoilWeight: [null],
      dryTr: [null],
      sampleId: [null],
      dryPlusTare: [null],
    });
  }

  next() {
    this.splitFigureIndex += this.splitCount;
    if (this.form.valid && this.form.touched) {
      this.onSubmit();
    } else {
      this.getGraphData();
    }
    this.nextEndCheck();
  }

  nextEndCheck() {
    if (this.splitFigureIndex / this.splitCount >= this.splitFigureData.length / this.splitCount - 1) {
      this.nextDisabled = true;
      this.prevDisabled = false;
    } else {
      this.prevDisabled = false;
      this.nextDisabled = false;
    }
  }

  prev() {
    this.splitFigureIndex -= this.splitCount;
    if (this.form.valid && this.form.touched) {
      this.onSubmit();
    } else {
      this.getGraphData();
    }
    this.previousEndCheck();
  }

  addItem(): void {
    const items = this.form.get('items') as FormArray;
    items.push(this.formBuilder.array([]));
  }

  save() {
    this.onSubmit();
  }

  onSubmit() {
    this.graphDataProcessCompleted = false;
    const submitData: any[] = [];
    this.form.value.items.forEach((eachObj: any) => {
      let innerArray: any[] = [];
      let dryTr = 0;
      let drySoil = 0;
      let mouldPlusProbeTare = 0;
      eachObj.forEach((obj: any, j: number) => {
        if (j > 0) {
          obj.dryTr = dryTr;
          obj.wetTr = Number(obj.wetTr);
          obj.mouldPlusProbeTare = mouldPlusProbeTare;
          if (this.soilType === SoilType.Native) {
            obj.drySoil = drySoil;
            obj.labSpecMoistureContent = Number(obj.labSpecMoistureContent);
            obj.moistureContent = Number(obj.moistureContent);
            if (obj.worksheetType === 'Bulk') {
              obj.wetPlusTare = (
                ((obj.labSpecMoistureContent) * (obj.dryPlusTare - obj.mouldPlusProbeTare)) / 100
                + obj.dryPlusTare
              ).toFixed(0);
            } else {
              obj.wetPlusTare = (
                ((obj.moistureContent) * (obj.dryPlusTare - obj.mouldPlusProbeTare)) / 100
                + obj.dryPlusTare
              ).toFixed(0);
            }
          } else if (this.soilType === SoilType.Precast) {
            obj.drySoilWeight = drySoil;
            obj.moisture = Number(obj.moisture);
            obj.wetPlusTare = (obj.moisture * (drySoil / 100) + obj.dryPlusTare).toFixed(0);
          }

          innerArray.push(obj);
        } else {
          if (Number(eachObj[0].dryTr) > Number(eachObj[0].wetTr)) {
            dryTr = Number(eachObj[0].dryTr);
          } else {
            dryTr = Number(eachObj[0].wetTr);
          }
          if (this.soilType === SoilType.Native) {
            drySoil = Number(eachObj[eachObj.length - 1].drySoil);
            mouldPlusProbeTare = Number(eachObj[eachObj.length - 1].mouldPlusProbeTare);
          } else if (this.soilType === SoilType.Precast) {
            drySoil = Number(eachObj[eachObj.length - 1].drySoilWeight);
          }
        }
      });
      submitData.push(innerArray);
      innerArray = [];
    });
    this.reportService.updateGraphData(submitData, this.soilType)?.subscribe({
      next: () => {
        this.getGraphData();
        this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
      },
    });
  }

  get getControls(): FormArray {
    return this.form?.controls['items'] as FormArray;
  }

  addControl(i: number, worksheetType: string, validationsMaxMin: ValidatorMaxMin): void {
    const itemsArray = this.form.get('items') as FormArray;
    const controlArray = itemsArray.at(i) as FormArray;
    if (this.soilType === SoilType.Native) {
      controlArray.push(this.createNativeItem(worksheetType, validationsMaxMin));
    } else if (this.soilType === SoilType.Precast) {
      controlArray.push(this.createPrecastItem(validationsMaxMin));
    }
  }

  getEachControls(i: number): FormArray | null {
    const itemsArray = this.form.get('items') as FormArray;
    return itemsArray.at(i) as FormArray | null;
  }

  getControlsLength(i: number): number {
    const controls = this.getEachControls(i)?.controls;
    return controls ? controls.length : 0;
  }

  showGraph(checked: boolean, index: number) {
    if (checked) {
      this.activeLineChartData[index].data = this.storedlineChartData[this.splitFigureIndex + index].data;
      this.chart.update();
    } else {
      this.activeLineChartData[index].data = [];
      this.chart.update();
    }
  }

  showMLGraph(checked: boolean, index: number) {
    const mlen = this.mlDataArray.length;
    const dataIndex = mlen + index;
    if (checked) {
      if (this.mlDataArray && this.mlDataArray[index].data.length === 0) {
        this.dialog?.open(MlErrorComponent, {
          panelClass: 'ml-error-dialog',
          data: {
            title: 'Error',
            message: 'No data found in the filtering process of DryDensity and MoistureContent',
            type: 'WARNING',
          },
          disableClose: true,
          enterAnimationDuration: '0ms',
          exitAnimationDuration: '0ms',
        });
      } else {
        this.activeLineChartData[dataIndex].data = this.mlDataArray[index].data;
        this.chart.update();
      }
    } else {
      this.activeLineChartData[dataIndex].data = [];
      this.chart.update();
    }
  }

  detectNativeWetChange(i: number, j: number): void {
    if (j === 0) {
      this.form.value.items[i].forEach((form: any) => {
        form.dryTr = this.form.value.items[i][0].wetTr;
      });
    }
    if (this.form.value.items[i][j].worksheetType === 'Bulk') {
      this.activeLineChartData[i].data[j] = {
        x: this.form.value.items[i][j].labSpecMoistureContent,
        y: this.form.value.items[i][j].wetTr,
      };
    } else {
      this.activeLineChartData[i].data[j] = {
        x: this.form.value.items[i][j].moistureContent,
        y: this.form.value.items[i][j].wetTr,
      };
    }
    if (this.maxTr / 50 !== this.checkLegendChange() || this.maxMc / 5 !== this.checkLegendChange()) {
      this.maxTr = this.checkLegendChange() * 50;
      this.maxMc = this.checkLegendChange() * 5;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'scatter',
        data: {
          datasets: this.lineChartData.slice(this.splitFigureIndex, this.splitFigureIndex + 1 * this.splitCount),
        },
        options: this.setChartOptions(),
      });
    } else if (
      Number(this.form.value.items[i][j].labSpecMoistureContent) > 0
      && Number(this.form.value.items[i][j].wetTr) > 0
    ) {
      this.chart.update();
    } else if (Number(this.form.value.items[i][0].labSpecMoistureContent) === 0) {
      this.chart.update();
    }
  }

  detectPrecastWetChange(i: number, j: number): void {
    if (j === 0) {
      this.form.value.items[i].forEach((form: any) => {
        form.dryTr = this.form.value.items[i][0].wetTr;
      });
    }
    this.activeLineChartData[i].data[j] = {
      x: this.form.value.items[i][j].moisture,
      y: this.form.value.items[i][j].wetTr,
    };
    if (this.maxTr / 50 !== this.checkLegendChange() || this.maxMc / 5 !== this.checkLegendChange()) {
      this.maxTr = this.checkLegendChange() * 50;
      this.maxMc = this.checkLegendChange() * 5;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'scatter',
        data: {
          datasets: this.lineChartData.slice(this.splitFigureIndex, this.splitFigureIndex + 1 * this.splitCount),
        },
        options: this.setChartOptions(),
      });
    } else if (Number(this.form.value.items[i][j].moisture) > 0 && Number(this.form.value.items[i][j].wetTr) > 0) {
      this.chart.update();
    } else if (Number(this.form.value.items[i][0].moisture) === 0) {
      this.chart.update();
    }
  }

  addTest(i: number): void {
    const itemsArray = this.form.get('items') as FormArray;
    const controlArray = itemsArray.at(i) as FormArray;

    if (controlArray.length < 5) {
      const j = controlArray.length - 1;
      let patchValue: any;
      let validationsMaxMin: ValidatorMaxMin;
      if (this.soilType === SoilType.Native) {
        if (controlArray.value[controlArray.length - 1].worksheetType === 'Bulk') {
          validationsMaxMin = {
            minMoisture: 0,
            maxMoisture: controlArray.value[controlArray.length - 1].labSpecMoistureContent - 0.1,
          };
        } else {
          validationsMaxMin = {
            minMoisture: 0,
            maxMoisture: controlArray.value[controlArray.length - 1].moistureContent - 0.1,
          };
        }
        controlArray.insert(
          j,
          this.createNativeItem(controlArray.value[controlArray.length - 1].worksheetType, validationsMaxMin),
        );
        patchValue = {
          projectId: controlArray.value[controlArray.length - 1].projectId,
          nativeSoilSampleId: controlArray.value[controlArray.length - 1].nativeSoilSampleId,
          labSpecMoistureContent: '',
          worksheetType: controlArray.value[controlArray.length - 1].worksheetType,
          moistureContent: '',
          wetTr: '',
          dryPlusTare: controlArray.value[controlArray.length - 1].dryPlusTare,
          dryTr: controlArray.value[controlArray.length - 1].dryTr,
        };
      } else if (this.soilType === SoilType.Precast) {
        validationsMaxMin = {
          minMoisture: 0,
          maxMoisture: controlArray.value[controlArray.length - 1].moisture - 0.1,
        };
        controlArray.insert(j, this.createPrecastItem(validationsMaxMin));
        patchValue = {
          projectId: controlArray.value[controlArray.length - 1].projectId,
          preCastSampleId: controlArray.value[controlArray.length - 1].preCastSampleId,
          moisture: '',
          wetTr: '',
          dryPlusTare: controlArray.value[controlArray.length - 1].dryPlusTare,
          dryTr: controlArray.value[controlArray.length - 1].dryTr,
        };
      }
      const control = controlArray.at(j) as FormGroup;
      control.patchValue(patchValue);
      const last = this.lineChartData[i + this.splitFigureIndex].data[j];
      this.lineChartData[i + this.splitFigureIndex].data.push(last);
    }
  }

  removeTest(i: number): void {
    const itemsArray = this.form.get('items') as FormArray;
    const controlArray = itemsArray.at(i) as FormArray;
    if (this.graphDataLengthArray[i] < controlArray.length - 1 && controlArray.length > 2) {
      const j = controlArray.length - 1;
      controlArray.removeAt(j - 1);
      this.lineChartData[i].data.splice(j - 1, 1);
      this.chart.update();
    }
  }

  openNav(): void {
    const sidenav = document.getElementById('drawer');
    if (sidenav) {
      sidenav.style.width = '250px';
      this.drawerActive = true;
    }
  }

  closeNav(): void {
    const sidenav = document.getElementById('drawer');
    if (sidenav) {
      sidenav.style.width = '0';
      this.drawerActive = false;
    }
  }

  loadFigure(index: number) {
    this.figureClicked = true;
    this.onSubmit();
    // let stpeIndex = 0;
    // if (index !== 0) {
    //   stpeIndex = index - 1 + this.splitCount;
    // }
    if (this.figureCountArray.length > 1) {
      // this.createFormAndGraph(stpeIndex);
    }
    this.splitFigureIndex = this.splitCount * index;
    if (this.splitFigureIndex === 0) {
      this.prevDisabled = true;
    } else {
      this.prevDisabled = false;
    }
    if (this.splitFigureIndex / this.splitCount >= this.splitFigureData.length / this.splitCount - 1) {
      this.nextDisabled = true;
    } else {
      this.nextDisabled = false;
    }
  }

  previousEndCheck() {
    if (this.splitFigureIndex === 0) {
      this.prevDisabled = true;
      this.nextDisabled = false;
    } else {
      this.figureIndicatorIndex -= 1;
      this.prevDisabled = false;
      this.nextDisabled = false;
    }
  }

  markAllAsComplete(event: MatCheckboxChange) {
    let checked = false;
    if (event.checked) {
      checked = true;
    }
    this.form.value.items.forEach((items: any) => {
      items.forEach((obj: any) => {
        obj.graphStatus = checked;
      });
    });
    this.form.markAsTouched();
    const currentData = this.splitFigureData[this.splitFigureIndex / this.splitCount];
    currentData.forEach((obj: any) => {
      obj.graphStatus = checked;
    });
    // this.splitFigureData = this.form.value.items;
  }

  markAsComplete(i: number): void {
    this.form.value.items[i].forEach((obj: any) => {
      obj.graphStatus = true;
    });
    // this.splitFigureData = this.form.value.items;
  }

  setFigureComplete() {
    let validIndex: number;
    if (this.splitFigureIndex * this.splitCount >= this.splitFigureData.length - 1) {
      validIndex = this.splitFigureIndex;
    } else {
      validIndex = this.splitFigureIndex;
    }
    this.figureCompletedArray[validIndex] = this.markAllComplete;
    if (this.markAllComplete) {
      this.sampleCompletedCount += 1;
    }
  }

  getFigureCount(splitCount: number): number {
    if (splitCount === 0) {
      return 1;
    }
    return Math.floor(splitCount / this.splitCount) + 1;
  }

  public getPreviewData(): void {
    if (!this.reportService.mlGraphData) {
      this.reportService?.fetchReportData(this.projectId, this.soilType)?.subscribe({
        next: (reportData) => {
          this.reportService.mlGraphData = reportData.mlData;
          this.createFormAndGraph(this.splitFigureIndex);
        },
      });
    }
  }

  figCompletedCount(): number {
    let completed = 0;
    this.figureCompletedArray.forEach((x) => {
      if (x === true) {
        completed += 1;
      }
    });
    return completed;
  }

  checkLegendChange(): number {
    let maxTr = 0;
    let maxMc = 0;
    this.getControls.value.forEach((control: any[], i: number) => {
      this.getEachControls(i)?.value.forEach((each: any, j: number) => {
        if (j === 0) {
          maxTr = this.findMax(maxTr, each.dryTr);
        }
        maxTr = this.findMax(maxTr, each.wetTr);
        if (this.soilType === SoilType.Native) {
          if (control[0].worksheetType === 'Bulk') {
            maxMc = this.findMax(maxMc, each.labSpecMoistureContent);
          } else {
            maxMc = this.findMax(maxMc, each.moistureContent);
          }
        } else if (this.soilType === SoilType.Precast) {
          maxMc = this.findMax(maxMc, each.moisture);
        }
      });
    });
    maxTr = Number(maxTr);
    if (maxTr / 50 > maxMc / 5) {
      return maxTr % 50 === 0 ? maxTr / 50 : Math.ceil(maxTr / 50);
    }
    return maxMc % 5 === 0 ? maxMc / 5 : Math.ceil(maxMc / 5);
  }
}

export interface ValidatorMaxMin {
  minMoisture: number;
  maxMoisture: number;
}
