<div id="header-container" class="row m-0" >
  <div class="col">
    <button (click)="reDirectUrl()" class="geotherm-logo-button">
      <img class="geotherm-logo" alt="geotherm-logo" src="../../../../assets/images/geotherm-logo.svg" />
    </button>
  </div>
  
  <div class="no-print col menu-container" *ngIf="userAccount?.name">
    <div class="demo-section">
      <mat-icon class="home-icon" (click)="reDirectUrl()">
      <img alt="geotherm-logo" src="../../../../assets/icons/Home-1.svg" />
      </mat-icon>
      <mat-icon
        class="cursor"
        [matMenuTriggerFor]="notificationmenu"
        [matBadge]="unreadNotificationCount > 10 ? '10+' : unreadNotificationCount"
        [matBadgeHidden]="unreadNotificationCount <= 0"
        matBadgeColor="warn">
        <img class="img-position" alt="geotherm-logo" src="../../../../assets/icons/Union.svg" />
      </mat-icon>
    </div>
    <mat-menu #notificationmenu="matMenu" class="notification-menu">
      <div class="max-height-70" *ngIf="notifications.length > 0">
        <div class="notification-sub" *ngFor="let notification of notifications">
          <span class="notification-title">
            {{ notification.message }}
          </span>
          <button class="dismiss-btn" *ngIf="!notification.isRead" (click)="onNotificationDismiss(notification)">
            DONE
          </button>
        </div>
      </div>
      <span *ngIf="notifications.length === 0" class="notification-title"> No notifications found. </span>
    </mat-menu>
    <div class="demo-section1 cursor" [matMenuTriggerFor]="usermenu">
      <mat-icon>
        <img class="img-position" src="../../../../assets/icons/profile.svg" alt="profile-icon" />
      </mat-icon>
      <span class="text"> {{ userAccount.name }}</span>
    </div>
    <mat-menu #usermenu="matMenu" class="notification-menu user-menu">
      <div *ngIf="userService.roleCheck(['Admin'])" class="button-hover">
        <button mat-button (click)="settings()">
          <span class="user-text">
            <img src="/assets/icons/settings-user.svg" alt="settings-user" />&nbsp; Settings
          </span>
        </button>
      </div>
      <div *ngIf="userService.roleCheck(['Admin'])" class="button-hover">
        <button mat-button (click)="navigateToGeoXplore()">
          <span class="user-text"> <img src="/assets/icons/globe.svg" alt="globe"  />&nbsp; GeoXplore </span>
        </button>
      </div>
      <div *ngIf="userService.roleCheck(['Admin'])" class="button-hover">
        <button mat-button (click)="navigateToAuditLogReport()">
          <span class="user-text"> <img src="/assets/icons/union_audit.svg" alt="audit-log" width="16px" />&nbsp; Audit Log Report</span>
        </button>
      </div>
      <div *ngIf="userService.roleCheck(['Admin','Manager'])" class="button-hover">
        <button mat-button (click)="navigateToInHouseDashborad()">
          <span class="user-text"> <img src="/assets/icons/Dashboard.svg" alt="in-house-dashboard" width="19px" />&nbsp;{{ buttonText }}</span>
        </button>
      </div>
      <div class="button-hover">
        <button mat-button (click)="logOut()">
          <span class="user-text"> <img src="/assets/icons/logout.svg" alt="logout" />&nbsp; Log Out </span>
        </button>
      </div>
    </mat-menu>
  </div>
</div>
