/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/shared/services/form.service';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { tableColumns } from 'src/app/shared/constants/table-column.constants';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { NotesComponent } from 'src/app/shared/components/notes/notes.component';
import { SetStatusComponent } from 'src/app/shared/components/set-status/set-status.component';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { INotes } from 'src/app/features/models/inotes.modal';
import { PrecastWorksheet } from 'src/app/features/worksheet/models/precast-worksheet.modal';
import { WorksheetResponse } from 'src/app/features/worksheet/models/worksheet-response.modal';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectDetailsComponent } from 'src/app/shared/components/project-details/project-details.component';
import { AdminHeader } from 'src/app/shared/models/admin-header.model';
import { ExportTableUtil } from 'src/app/shared/util/export-table.utils';
import { WorksheetService } from './services/worksheet.service';
import { SharedService } from '../service/shared.service';
import { ExportTableService } from '../service/export-table.service';

@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WorksheetComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) worklistPaginator!: MatPaginator;
  public worksheetRecords!: WorksheetResponse;
  public displayedColumns: string[] = tableColumns.worksheetColumns;
  public dataSource = new MatTableDataSource<PrecastWorksheet>();
  public dialogRef: MatDialogRef<NotesComponent> | undefined;
  public messageDialogRef: MatDialogRef<MessageModalComponent> | undefined;
  public statusDialogRef: MatDialogRef<SetStatusComponent> | undefined;
  public projectId = '';
  public soilType = '';
  public precastSampleId?: string | undefined;
  public note: INotes | undefined;
  private readonly destroyed$ = new Subject<boolean>();
  ovenDate = '';
  wetTestData = '';
  dryTestData = '';
  noteText: string | undefined = '';
  isReportWriter = false;
  sampleReceivedDate = '';
  dueDate = '';
  instructionConfirmedAt = '';
  daysleft: number | null;
  isRushTesting = false;
  isGeoTesting = false;
  preApprovalTesting = false;
  @Input() FetchAllHeaderData: any;
  headerData: any;
  rowData: any;
  constructor(
    private precastService: PrecastService,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogService,
    private router: Router,
    public dialog: MatDialog,
    public userService: UserService,
    private worksheetService: WorksheetService,
    private formService: FormService,
    private sharedService: SharedService,
    private exporttableService: ExportTableService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.projectId = params['projectId'];
      this.soilType = params['soilType'];
      this.fetchWorksheetDetails(this.projectId);
      this.fetchWorksheetNotes(this.projectId);
    });
    this.userService.userRole.subscribe({
      next: () => {
        this.isReportWriter = this.userService.roleCheck(['Reportwriter']);
      },
    });
  }

  fetchWorksheetNotes(projectID: string): void {
    this.sharedService.fetchWSNotes(projectID);
    this.sharedService.notes$.subscribe((data) => {
      this.note = data;
    });
    this.sharedService.notesText$.subscribe((data) => {
      this.noteText = data;
    });
  }

  fetchWorksheetDetails(projectId: string): void {
    const projectResponse$ = this.worksheetService.fetchProjectById(projectId);
    const companyResponse$ = this.worksheetService.fetchCompanyById(projectId);
    combineLatest([projectResponse$, companyResponse$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([project, company]) => {
          this.worksheetRecords = {
            project,
            company,
          };
          Object.assign(this.worksheetRecords, company);
          this.instructionConfirmedAt = this.worksheetRecords.project.instructionsConfirmedAt
            ? this.worksheetRecords.project.instructionsConfirmedAt
            : '';
          this.dueDate = this.worksheetRecords.project.dueDate ? this.worksheetRecords.project.dueDate : '';
          this.sampleReceivedDate = this.worksheetRecords.project.sampleReceivedDate
            ? this.worksheetRecords.project.sampleReceivedDate
            : '';
          this.ovenDate = this.worksheetRecords.project.ovenDate ? this.worksheetRecords.project.ovenDate : '';
          this.wetTestData = this.worksheetRecords.project.wetTestData ? this.worksheetRecords.project.wetTestData : '';
          this.dryTestData = this.worksheetRecords.project.dryTestData ? this.worksheetRecords.project.dryTestData : '';
          this.isRushTesting = this.worksheetRecords.project.rushTesting
            ? this.worksheetRecords.project.rushTesting
            : false;
          this.isGeoTesting = this.worksheetRecords.project.geothermField
            ? this.worksheetRecords.project.geothermField
            : false;
          this.preApprovalTesting = this.worksheetRecords.project.preApproval
            ? this.worksheetRecords.project.preApproval
            : false;
          this.dataSource.paginator = this.worklistPaginator;
          if (this.dueDate) {
            const a = new Date(this.dueDate);
            const b = new Date();
            this.daysleft = this.findDateDifference(a, b);
          } else {
            this.daysleft = null;
          }
        },
      });
  }

  findDateDifference(a: any, b: any) {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.ceil((utc1 - utc2) / MS_PER_DAY);
  }

  openViewNotesDialog(): void {
    const messageObject = {
      projectId: this.projectId,
      notes: this.note,
    };
    this.dialog
      .open(NotesComponent, {
        panelClass: 'app-dialog',
        data: messageObject,
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.fetchWorksheetNotes(this.projectId);
      });
  }

  openProjectDetailsComponent(title: string, fieldName: string): void {
    if (!this.isReportWriter) {
      const messageObject = {
        projectId: this.projectId,
        title,
        fieldName,
        ovenDate: this.ovenDate,
        wetTestData: this.wetTestData,
        dryTestData: this.dryTestData,
      };
      this.dialog
        .open(ProjectDetailsComponent, {
          panelClass: 'app-dialog',
          data: messageObject,
          disableClose: true,
          width: '50%',
        })
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.fetchWorksheetDetails(this.projectId);
        });
    }
  }

  openSetStatus(): void {
    const messageObject = {
      projectId: this.projectId,
    };
    this.dialog
      .open(SetStatusComponent, {
        panelClass: 'app-dialog',
        data: messageObject,
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.fetchWorksheetNotes(this.projectId);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(false);
    this.destroyed$.complete();
  }

  reDirectUrl() {
    this.router.navigate(['dashboard']);
  }

  assignedDone(): void {
    this.fetchWorksheetNotes(this.projectId);
  }

  review(projectID: string, soilType: string): void {
    const params = {
      f: 'w',
    };
    const navigationExtras: NavigationExtras = {
      queryParams: params,
      // Other navigation options can be added here if needed
    };
    this.formService.updateData(null);
    this.router.navigate([`/review/form/${soilType}/${projectID}`], navigationExtras);
  }

  handleFetchAllHeader(data: {
    asRecivedWorksheets: AdminHeader[];
    moistureWorksheets: AdminHeader[];
    nativeSoilWorksheets: AdminHeader[];
    proctorWorksheets: AdminHeader[];
  }): void {
    this.headerData = data;
  }

  handleAllResNativeWorksheet(data: any): void {
    this.rowData = data;
  }

  handleAllResPrecastWorksheet(data: any): void {
    this.rowData = data;
  }

  handleFetchPrecastHeader(data: any): void {
    this.headerData = data;
  }

  export() {
    const notesUpdatedBy = this.note?.updatedBy?.userName;
    this.exporttableService.openInNewTab(
      this.headerData,
      this.rowData,
      this.worksheetRecords,
      notesUpdatedBy,
      this.noteText,
      this.soilType,
    );
  }
}
