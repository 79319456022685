/* eslint-disable default-case */
import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import { INotes } from 'src/app/features/models/inotes.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { PrecastService } from 'src/app/shared/services/precast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ExportTableUtil } from 'src/app/shared/util/export-table.utils';

export interface SampleStatus {
  disabled: boolean;
  label: string;
  id: string;
  order: string;
}

export interface SampleNativeSoil {
  id: string;
  proctorType: boolean;
  sampleId: string;
  collectionDate: string;
  sampleDepth: number;
  sampleEndDepth: number;
  sampleType: string;
  soilDescription: string;
  maxDryDensity: number;
  compactionEffort: string;
  moistureOptimum: boolean;
  moistureAsReceived: boolean;
  specifiedMoistureContent: number | null;
  optimumMoistureContent: number;
  sampleStatusTypeId: string | null;
  weightReceived: number;
  weightUsed: number;
  weightRemaining: number;
  stockPile?: boolean;
  status?:string | undefined;
  depth?:string | undefined;
}

export interface SamplePreCast {
  id: string;
  sampleId: string;
  sampleCollectionDate: string;
  sampleType: string;
  mixDesignCode: string;
  numSamplesPerSet: number;
  thermalSpecifications: string;
  truckNumber: string;
  ticketNumber: string;
  sampleStatusTypeId: string | null;
  weightReceived?: number;
}

type ColumnName = 'status' | 'sampleId' | 'sampleType' | 'depth' | 'weightReceived' | 'weightUsed' | 'weightRemaining';

const columnMapping: Record<ColumnName, keyof SampleNativeSoil> = {
  status: 'status',
  sampleId: 'sampleId',
  sampleType: 'sampleType',
  depth: 'depth',
  weightReceived: 'weightReceived',
  weightUsed: 'weightUsed',
  weightRemaining: 'weightRemaining',
};

const columnHeaders: Record<ColumnName, string> = {
  status: 'Status',
  sampleId: 'Sample ID',
  sampleType: 'Sample Type',
  depth: 'Depth',
  weightReceived: 'Weight Received (gm)',
  weightUsed: 'Weight Used (gm)',
  weightRemaining: 'Weight Remaining (gm)',
};

@Component({
  selector: 'app-shipment-received',
  templateUrl: 'shipment-received.component.html',
  styleUrls: ['shipment-received.component.scss'],
  providers: [DatePipe],
})
export class ShipmentReceivedComponent implements OnInit {
  @Input() projectId: string;
  displayPrecastColumns = ['status', 'sampleId', 'sampleCollectionDate', 'materialType', 'mixDesign'];
  displayNativeColumns: ColumnName[] = [
    'status',
    'sampleId',
    'sampleType',
    'depth',
    'weightReceived',
    'weightUsed',
    'weightRemaining',
  ];

  dataSource = new MatTableDataSource<SamplePreCast | SampleNativeSoil>();
  PreviewSource = new MatTableDataSource<SampleNativeSoil>();
  sampleStatuses: SampleStatus[];
  additionalNotes: string;
  saveEnabled: boolean;
  config: any;
  shipmentForm: FormGroup;
  projectType: string;
  updateSampleStatus:any;
  public dialogRef: any;
  userAccount: any;
  userRoles: string[] = [];
  showHide = false;
  constructor(
    private matDialogRef: MatDialogRef<ShipmentReceivedComponent>,
    @Inject(MAT_DIALOG_DATA) data: MatDialog,
    private precastService: PrecastService,
    private snackBar: MatSnackBar,
    public userService: UserService,
  ) {
    this.config = data;
    this.shipmentForm = new FormGroup({
      samples: new FormArray([]),
    });
  }

  ngOnInit(): void {
    (window as any).exportTable = this.exportTable.bind(this);
    this.getSampleInformation(this.config.projectId, this.config.projectType);
    this.getSampleStatusLookup();
    this.shipmentForm.valueChanges.subscribe(() => {
      this.validateForAll();
    });

    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  ShowPrintBtn(): boolean {
    return !this.showHide && this.roleCheck(['Admin']);
  }

  getSampleStatusLookup() {
    this.precastService.getSampleStatusLookup().subscribe({
      next: (res: SampleStatus[]) => {
        this.sampleStatuses = res;
      },
    });
  }

  getSampleInformation(projectId: string, projectType: string) {
    this.projectType = projectType;
    this.precastService.getSampleInformation(projectId, projectType).subscribe({
      next: (res: SamplePreCast[] | SampleNativeSoil[]) => {
        this.dataSource.data = res;
        this.initializeFormArray(res);
        this.validateForAll();
        this.PreviewSource.data = this.transformData(res);
      },
    });
  }

  initializeFormArray(data: SampleNativeSoil[] | any[]) {
    const formGroups = data.map((sample) => {
      const group = new FormGroup({
        id: new FormControl(sample.id),
        proctorType: new FormControl(sample.proctorType),
        sampleId: new FormControl(sample.sampleId),
        collectionDate: new FormControl(sample.collectionDate),
        sampleDepth: new FormControl(sample.sampleDepth),
        sampleEndDepth: new FormControl(sample.sampleEndDepth),
        stockPile: new FormControl(sample.stockPile),
        sampleType: new FormControl(sample.sampleType),
        soilDescription: new FormControl(sample.soilDescription),
        maxDryDensity: new FormControl(sample.maxDryDensity),
        compactionEffort: new FormControl(sample.compactionEffort),
        moistureOptimum: new FormControl(sample.moistureOptimum),
        moistureAsReceived: new FormControl(sample.moistureAsReceived),
        specifiedMoistureContent: new FormControl(sample.specifiedMoistureContent),
        optimumMoistureContent: new FormControl(sample.optimumMoistureContent),
        sampleStatusTypeId: new FormControl(sample.sampleStatusTypeId || null),
        weightReceived: new FormControl(sample.weightReceived),
        weightUsed: new FormControl(sample.weightUsed),
        weightRemaining: new FormControl(sample.weightRemaining),
      });

      if (sample.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5') {
        group.get('weightReceived')?.setValue(null, {
          emitEvent: false,
        });
        group.get('weightReceived')?.disable();
      }

      return group;
    });

    this.shipmentForm.setControl('samples', new FormArray(formGroups));
  }

  get samplesArray(): FormArray {
    return this.shipmentForm.get('samples') as FormArray;
  }

  getFormGroup(index: number): FormGroup {
    return this.samplesArray.at(index) as FormGroup;
  }

  closeDialog(mode: string): void {
    this.matDialogRef?.close(mode);
  }

  updateStatus() {
    if (this.projectType === 'Native Soil') {
      const updatedData = this.shipmentForm.get('samples')?.value;
      this.updateSampleStatus = this.precastService.updateSampleStatus(this.config.projectType, updatedData);
    } else {
      this.updateSampleStatus = this.precastService.updateSampleStatus(this.config.projectType, this.dataSource.data);
    }

    const payload: INotes = {
      roleName: null,
      projectId: this.config?.projectId,
      text: this.additionalNotes,
    };
    const addNotes$ = this.precastService.addNotes(payload);
    const callStack = this.additionalNotes && this.additionalNotes !== '' ? [this.updateSampleStatus, addNotes$] : [this.updateSampleStatus];
    combineLatest(callStack).subscribe({
      next: () => {
        this.snackBar.open(messagesConfig.changeSuccessMeaage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.closeDialog('');
      },
    });
  }

  onStatusChange(event: any, index: number) {
    const selectedStatus = event.value;
    const weightReceivedControl = this.getFormControl(index, 'weightReceived');

    if (selectedStatus === '2320323b-65e5-4270-95ce-6fa221d3c0b5') { // Missing status
      weightReceivedControl.setValue(null, {
        emitEvent: false,
      });
      weightReceivedControl.disable();
    } else {
      weightReceivedControl.enable();
    }

    this.validateForAll();
  }

  validateForAll() {
    this.saveEnabled = true;

    if (this.projectType === 'Pre Cast') {
      this.dataSource.data.forEach((sample: SampleNativeSoil | SamplePreCast) => {
        if (!sample.sampleStatusTypeId) {
          this.saveEnabled = false;
        }
      });
    } else {
      this.samplesArray.controls.forEach((control: AbstractControl) => {
        const formGroup = control as FormGroup;

        const statusId = formGroup.get('sampleStatusTypeId')?.value;
        const weightReceived = formGroup.get('weightReceived')?.value;

        if (statusId !== '2320323b-65e5-4270-95ce-6fa221d3c0b5') {
          if (
            !statusId
          || !weightReceived
          || weightReceived <= 0
          ) {
            this.saveEnabled = false;
          }
        }
      });
    }
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');

    const maxLength = 5;
    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }
    this.shipmentForm.get('weightReceived')?.setValue(input.value, {
      emitEvent: false,
    });
  }

  getFormControl(index: number, controlName: string): FormControl {
    const formGroup = this.getFormGroup(index);
    return formGroup.get(controlName) as FormControl;
  }

  transformData(data:SamplePreCast[] | SampleNativeSoil[]) {
    return data.map((item) => {
      const transformedItem = {
        ...item,
      } as SampleNativeSoil & { status?: string, depth?: string };

      switch (transformedItem.sampleType) {
        case '3ab1b1ca-7964-4a72-9818-202f0b3c592b':
          transformedItem.sampleType = 'Bulk';
          break;
        case '6f32ebfe-767a-4f27-a295-9dad300d9ba9':
          transformedItem.sampleType = 'Tube';
          break;
      }

      switch (transformedItem.sampleStatusTypeId) {
        case '33de1f0d-8209-4d52-a4c7-18e4918d9932':
          transformedItem.status = 'Damaged';
          break;
        case '2320323b-65e5-4270-95ce-6fa221d3c0b5':
          transformedItem.status = 'Missing';
          break;
        case 'd471cab0-df24-4a80-a670-9994fbdf5f6f':
          transformedItem.status = 'Received';
          break;
      }

      if (transformedItem.sampleDepth == null && transformedItem.sampleEndDepth === null && transformedItem.stockPile === false) {
        transformedItem.depth = '';
      } else if (transformedItem.sampleDepth == null && transformedItem.sampleEndDepth === null && transformedItem.stockPile === true) {
        transformedItem.depth = 'StockPile';
      } else if (transformedItem.sampleDepth !== undefined && transformedItem.sampleEndDepth !== undefined) {
        transformedItem.depth = `${transformedItem.sampleDepth}-${transformedItem.sampleEndDepth}`;
      }

      return transformedItem;
    });
  }

  openInNewTab(): void {
    const newWindow = window.open('', '_blank');
    if (!newWindow) {
      return;
    }

    const tableHtml = this.generateTableHtml();
    const projectInfoHtml = `
    <div>
      <p><strong>Project Name:</strong> ${this.config.projectName}</p>
      <p><strong>Company Name:</strong> ${this.config.companyName}</p>
      <p><strong>Project Number:</strong> ${this.config.projectNumber}</p>
    </div>
  `;
    const downloadButtonHtml = `
  <div style="margin-top: 20px;">
    <button id="downloadBtn" style="padding: 10px 20px; font-size: 16px;">
      DOWNLOAD XLSX FILE
    </button>
  </div>
`;
    newWindow.document.open();
    newWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Receive Shipment Excel Preview</title>
          <style>
            body {
              font-family: Roboto, sans-serif;
              margin: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
            }
            th {
              background-color: #f2f2f2;
              text-align: left;
            }
            .header-title {
              margin-bottom: 20px;
            }
            h1{
              text-align:center
            }
            #downloadBtn {
            margin-top: -60px;
            right: 1%;
            position: absolute;
            color: #DD3333;
            align-items: center;
            background-color: white;
            border-radius: 4px;
            border: 2px solid #DD3333;
            cursor:pointer;
          }
          </style>
        </head>
        <body>
          <h1>Receive Shipment Excel Preview ${downloadButtonHtml}</h1>
          ${projectInfoHtml} 
          ${tableHtml}
          <script>
          document.getElementById('downloadBtn').addEventListener('click', function() {
            // Simulate export to Excel by calling a function on the parent page
            if (window.opener && typeof window.opener.exportTable === 'function') {
              window.opener.exportTable();
            } else {
              alert('Export function not available.');
            }
          });
        </script>
        </body>
      </html>
    `);
    newWindow.document.close();
  }

  generateTableHtml(): string {
    const headerHtml = this.displayNativeColumns.map((column) => `<th>${columnHeaders[column]}</th>`).join('');
    const rowsHtml = this.PreviewSource.data.map((row) => `
        <tr>
          ${this.displayNativeColumns.map((column) => {
    const property = columnMapping[column];
    const value = row[property as keyof SampleNativeSoil];
    return `<td>${value !== undefined && value !== null ? value : ''}</td>`;
  }).join('')}
        </tr>
      `).join('');

    return `
      <table>
        <thead>
          <tr>${headerHtml}</tr>
        </thead>
        <tbody>
          ${rowsHtml}
        </tbody>
      </table>
    `;
  }

  exportTable(): void {
    const filteredData = this.PreviewSource.data.filter(
      (item) => (item as SampleNativeSoil).sampleType !== undefined,
    ) as SampleNativeSoil[];

    const { projectName, projectNumber, companyName } = this.config;

    ExportTableUtil.exportShipmentTableToExcel(
      filteredData,
      this.displayNativeColumns,
      projectName,
      projectNumber,
      companyName,
    );
  }
}
