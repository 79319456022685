import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGaurd } from '../shared/services/role-gaurd.service';
import { ReviewModule } from './review/review.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { GeoMapModule } from './geo-map/geo-map.module';
import { SampleCollectionFormModule } from './sample-collection-form/sample-collection-form.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { AdminModule } from './admin/admin.module';
import { WorksheetModule } from './worksheet/worksheet.module';
import { CustomerValidationModule } from './customer-validation/customer-validation.module';
import { ReportModule } from './report/report.module';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
    canActivate: [MsalGuard],
  },
  {
    path: 'geoxplore',
    loadChildren: () => GeoMapModule,
    canActivate: [MsalGuard, RoleGaurd],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: 'sampleform',
    loadChildren: () => SampleCollectionFormModule,
    canActivate: [RoleGaurd],
    data: {
      roles: ['Manager', 'Admin', 'Reportwriter'],
    },
  },
  {
    path: 'review',
    loadChildren: () => ReviewModule,
    canActivate: [RoleGaurd],
    data: {
      roles: ['Manager', 'Admin', 'Reportwriter'],
    },
  },
  {
    path: 'report',
    loadChildren: () => ReportModule,
    canActivate: [RoleGaurd],
    data: {
      roles: ['Manager', 'Admin', 'Reportwriter'],
    },
  },
  {
    path: 'customer',
    loadChildren: () => CustomerValidationModule,
  },
  {
    path: 'worksheet',
    loadChildren: () => WorksheetModule,
    canActivate: [MsalGuard],
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [MsalGuard, RoleGaurd],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'in-house-dashboard',
    loadChildren: () => import('./tv-dashboard/tv-dashboard.module').then((m) => m.TvDashboardModule),
    canActivate: [MsalGuard],
    data: {
      roles: ['Manager', 'Admin'],
    },
  },
  {
    path: 'audit-log-report',
    loadChildren: () => import('./audit-log/audit-log.module').then((m) => m.AuditLogModule),
    canActivate: [MsalGuard],
    data: {
      roles: ['Admin'],
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => PageNotFoundModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
