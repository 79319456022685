<div class="clearfix">
  <div>
    <p class="heading">Company/Contact Information</p>
  </div>
</div>
<mat-card class="mat-card">
  <mat-card-content class="mat-card-content">
    <form [formGroup]="companyFormGroup">
      <div class="row">
        <div class="col-md-12">
          <mat-label class="mat-label form-label" for="companyName">
            Company Name <span class="required">*</span>
          </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="name" maxlength="50" id="companyName" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="contactName"> Contact Name <span class="required">*</span> </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="contactName" maxlength="50" id="contactName" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="emails">
            Contact Email (s) - Report Submittal<span class="required">*</span>
            <a
              href="javascript:void(0)"
              #tooltip="matTooltip"
              alt="tooltip-information"
              matTooltip="Enter all information to whom the report(s) email should be sent to, separated by comma">
              <img src="/assets/icons/Alert-grey.svg" alt="alert-grey-icon" />
            </a>
          </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="emails" id="emails" appCommaSpace maxlength="500" />
            <mat-error class="font-size-14" *ngIf="emailsError">Please enter valid email(s).</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="contactNumber"> Contact Number <span class="required">*</span> </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="contactNumber" maxlength="14" id="contactNumber" appPhoneMask />
            <mat-error class="font-size-14" *ngIf="contactNumberErrors['validContactNumber'] === false"
              >Please enter valid Contact Number.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-label class="mat-label" for="streetAddress">Street Address </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="streetAddress" id="streetAddress" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="city">City </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="city" id="city" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="state">State/Province </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="state" id="state" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="postalCode">Zip code/Postal code </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="postalCode" id="postalCode" maxlength="10" />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <p class="sub-heading">Billing Information</p>
        </div>
        <div class="col-md-12">
          <mat-checkbox
            color="primary"
            class="checkbox-left"
            formControlName="hasBillingInfo"
            (click)="checkHasBillingInfo()">
            <sub class="checkbox-text">Same as above</sub>
          </mat-checkbox>
        </div>
        <ng-container *ngIf="!companyFormGroup.get('hasBillingInfo')?.value">
          <div class="col-md-4">
            <mat-label class="mat-label" for="invoice"> Company to Invoice </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput maxlength="50" formControlName="invoice" id="invoice" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bemails">
              Accounts Payable Email (s)
              <a
                href="javascript:void(0)"
                #tooltip="matTooltip"
                alt="tooltip-information"
                matTooltip="Enter all information to whom the invoice should be sent to, separated by comma">
                <img src="/assets/icons/Alert-grey.svg" alt="alert-grey-icon" />
              </a>
            </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bemails" id="bemails" appCommaSpace maxlength="500" />
              <mat-error class="font-size-14" *ngIf="bemailsError">Please enter valid email(s).</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bcontactNumber"> Contact Number </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput maxlength="14" formControlName="bcontactNumber" id="bcontactNumber" appPhoneMask />
              <mat-error class="font-size-14" *ngIf="bcontactNumberErrors['validbContactNumber'] === false"
                >Please enter valid Billing Contact Number.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bContactName"> Contact Name<span class="required">*</span> </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput maxlength="50" formControlName="bContactName" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="emails">
              Contact Email (s) - Report Submittal<span class="required">*</span>
              <a
                href="javascript:void(0)"
                #tooltip="matTooltip"
                alt="tooltip-information"
                matTooltip="Enter all information to whom the report(s) email should be sent to, separated by comma">
                <img src="/assets/icons/Alert-grey.svg" alt="alert-grey-icon" />
              </a>
            </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bContactEmail" appCommaSpace maxlength="500" />
              <mat-error class="font-size-14" *ngIf="contactEmailsError">Please enter valid email(s).</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-label class="mat-label" for="bstreetAddress"> Street Address </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bstreetAddress" id="bstreetAddress" maxlength="50" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bcity"> City </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bcity" id="bcity" maxlength="50" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bstate"> State/Province </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bstate" id="bstate" maxlength="50" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-label class="mat-label" for="bpostalCode"> Zip code/Postal code </mat-label>
            <mat-form-field appearance="outline" class="mat-form-field-width">
              <input matInput formControlName="bpostalCode" id="bpostalCode" maxlength="10" />
            </mat-form-field>
          </div>
        </ng-container>
        <div class="cole-md-12">
          <p class="sub-heading">Project Information</p>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="projectName"> Project Name <span class="required">*</span> </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="projectName" id="projectName" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-8"></div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="projectLocation"> Project Location </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="projectLocation" id="projectLocation" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="jobNumber"> PO/Job Number </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="jobNumber" id="jobNumber" maxlength="50" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label class="mat-label" for="projectNumber"> Project Number </mat-label>
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <input matInput formControlName="projectNumber" id="projectNumber" maxlength="50" />
          </mat-form-field>
        </div>
        <div *ngIf="submissionType === 'native-soil'">
          <div class="toggle-header">
            Please select 'Yes' if Geotherm conducted field testing for the sample being submitted
            <span class="required">*</span>
            <a
              href="javascript:void(0)"
              #tooltip="matTooltip"
              alt="tooltip-information"
              matTooltip="Please select geotherm conducted field testing">
              <img src="/assets/icons/Alert-grey.svg" alt="alert-icon" />
            </a>
          </div>
          <div class="geo-toogle">
            <mat-radio-group
              aria-label="Select an option"
              color="primary"
              formControlName="geothermField"
              name="geothermField">
              <mat-radio-button [value]="true" style="margin-right: 20px">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-md-4">
          <mat-slide-toggle formControlName="rushTesting" name="rushTesting" class="label-toggle">
            Rush Testing (Additional Fees)
          </mat-slide-toggle>
        </div>
        <div
          class="col-md-5"
          *ngIf="submissionType === 'precast'"
          [ngClass]="{ 'pre-btn-disabled': !makeSampleEditable }">
          <mat-slide-toggle formControlName="preApproval" name="preApproval" class="label-toggle">
            Pre Approval Testing
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
