import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MatDialog } from '@angular/material/dialog';
import { geoXplore } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { SliderDataService } from 'src/app/shared/services/slider-data.service';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { GeoMapService } from './services/geo-map.service';

@Component({
  selector: 'app-geo-map-container',
  templateUrl: './geo-map-container.component.html',
  styleUrls: ['./geo-map-container.component.scss'],
})
export class GeoMapContainerComponent implements OnInit {
  public map: mapboxgl.Map;
  public marker: mapboxgl.Marker[] = [];
  public style = 'mapbox://styles/mapbox/streets-v12';
  public countries: string[];
  public mapData: any;
  public selectedCountry: string;
  public selectedState: any;
  public states: string[];
  public selectedStateDetails: any;
  public specificStateLatAndLong: any = [];
  public markersList: any = [];
  public sliderValue = 50;
  selectedSoilType = 'native-soil';

  constructor(
    private dialog: MatDialog,
    public router: Router,
    private geoMapService: GeoMapService,
    private sliderDataService: SliderDataService,
  ) {}

  ngOnInit() {
    mapboxgl as typeof mapboxgl;
    this.map = new mapboxgl.Map({
      accessToken: geoXplore.accessToken,
      container: 'map',
      style: this.style,
      center: [-98.5795, 39.8283],
      zoom: 2,
    });
    setTimeout(() => {
      this.map.flyTo({
        center: [-98.5795, 39.8283],
        zoom: 4.5,
        speed: 0.5,
        curve: 0.8,
      });
    }, 1600);
    this.map.addControl(new mapboxgl.NavigationControl());
    this.geoMapService.getLatAndLong().subscribe({
      next: (data) => {
        this.mapData = data;
        this.countries = Object.keys(this.mapData);
        this.selectedCountry = 'United States';
        this.onCountrySelection();
      },
    });
  }

  onSoilTypeChange(event: MatRadioChange): void {
    this.selectedSoilType = event.value;
    this.sliderDataService.updateSelectedSoilType(this.selectedSoilType);
    if (this.selectedSoilType === 'precast') {
      this.geoMapService.getPrecastLatLong().subscribe({
        next: (data) => {
          this.mapData = data;
          this.countries = Object.keys(this.mapData);
          this.selectedCountry = 'United States';
          this.onCountrySelection();
          this.clearMarker();
          this.selectedState = [];
        },
      });
    } else {
      this.geoMapService.getLatAndLong().subscribe({
        next: (data) => {
          this.mapData = data;
          this.countries = Object.keys(this.mapData);
          this.selectedCountry = 'United States';
          this.onCountrySelection();
          this.clearMarker();
          this.selectedState = [];
        },
      });
    }
  }

  onSliderChange(event: Event): void {
    const { value } = (event.target as HTMLInputElement);
    this.sliderValue = Number(value);
    this.sliderDataService.updateSliderValue(this.sliderValue);
  }

  onCountrySelection() {
    this.states = Object.keys(this.mapData[this.selectedCountry]);
  }

  onStateSelection() {
    this.specificStateLatAndLong = this.mapData[this.selectedCountry][this.selectedState];
    this.zoomToState(this.specificStateLatAndLong);
    this.clearMarker();
    this.addMarkers(this.specificStateLatAndLong);
  }

  addMarkers(specificStateLatAndLong: any) {
    specificStateLatAndLong.forEach((city: any, index: number) => {
      this.marker[index] = new mapboxgl.Marker({
        color: '#FF5733',
        offset: [0, -50 / 2],
      })
        .setLngLat([city.longitude, city.latitude])
        .addTo(this.map);
      this.marker[index].getElement().addEventListener('click', () => {
        this.openModal(city);
      });
    });
  }

  public openModal(city: any) {
    this.dialog.open(ModalContentComponent, {
      data: {
        city,
      },
      disableClose: true,
    });
  }

  clearMarker() {
    if (this.marker) {
      this.marker.forEach((marker: any) => {
        marker.remove();
      });
    }
  }

  public zoomToState(specificStateLatAndLong: any) {
    setTimeout(() => {
      this.map.flyTo({
        center: [specificStateLatAndLong[0].longitude, specificStateLatAndLong[0].latitude],
        zoom: 5.5,
        speed: 0.5,
        curve: 0.8,
      });
    }, 200);
  }

  redirectToDasboard() {
    this.router.navigateByUrl('dashboard');
  }
}
