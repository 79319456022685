/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  EventEmitter,
  Renderer2,
  Output,
  OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubcribe';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserService } from 'src/app/shared/services/user.service';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { WorkSheetType } from 'src/app/features/worksheet/models/native-soill-worksheet-type.modal';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AdminHeader } from 'src/app/shared/models/admin-header.model';
import { AllAdminService } from 'src/app/shared/services/all-admin.service';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { SharedService } from 'src/app/features/service/shared.service';
import { NativeSoilService } from '../../services/native-soil/native-soil.service';
import { AssignToLabtechComponent } from '../../../../shared/components/assign-to-labtech/assign-to-labtech.component';
import { EditNativeSoilWorksheetComponent } from '../edit-native-soil-worksheet/edit-native-soil-worksheet.component';
import { NativeSoilType } from '../../models/native-soil-worksheet.modal';

@AutoUnsubscribe
@Component({
  selector: 'app-nativesoil-table',
  templateUrl: './nativesoil-table.component.html',
  styleUrls: ['./nativesoil-table.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativesoilTableComponent implements OnInit {
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    let exists = false;
    const elementWithClass = this.el.nativeElement.querySelector('.pop-des-show');
    if (elementWithClass) {
      exists = true;
      this.renderer.removeClass(elementWithClass, 'pop-des-show');
    }
    const { nativeElement } = this.el;
    this.renderer.removeClass(nativeElement, 'pop-des-show');
    const clickedElement = event.target as HTMLElement;
    const { className } = clickedElement;
    if (className.includes('cli')) {
      exists = true;
    } else {
      exists = false;
      const element = this.el.nativeElement.querySelector('.cli');
      if (element) {
        this.renderer.removeClass(element, 'cli');
      }
    }
    clickedElement.classList.add('cli');
    if (className.includes('des-ico')) {
      const specificParent = this.findSpecificParent(clickedElement, 'td-des');
      if (specificParent) {
        if (!exists) {
          specificParent.classList.add('pop-des-show');
        } else {
          clickedElement.classList.remove('cli');
        }
      }
      this.cd.detectChanges();
    }
  }

  @Input() projectId: string;
  @Output() assigned = new EventEmitter<boolean>();
  displayedColumns: string[] = [];
  headerColumn: any[] = [];
  dataSource: NativeSoilType[] = [];
  isManagerAdmin = false;
  isEdit = false;
  receivedWorkSheet = [];
  moistureWorksheets = [];
  proctorWorksheets = [];
  workSheets = [];
  showTable = false;
  isBulk = true;
  activetab = 'receivedSheet';
  tableShow = true;
  sWTButton = false;
  actionButtonEnable = false;
  isLabTech = false;
  tabAssign = true;
  tabOpenTech = true;
  allResponseWorksheet: any;
  allWorksheetApiRes:any;
  headerData: AdminHeader[] = [];
  headerKeyToRowKeyMapping: { [key: string]: string } = {
  };

  @Output() allResNativeWorksheet = new EventEmitter<any>();
  asRecivedWorksheetsData: AdminHeader[] = [];
  moistureWorksheetsData: AdminHeader[] = [];
  nativeSoilWorksheetsData: AdminHeader[] = [];
  proctorWorksheetsData: AdminHeader[] = [];

  @Output() fetchAllHeader = new EventEmitter<{
    asRecivedWorksheets: AdminHeader[],
    moistureWorksheets: AdminHeader[],
    nativeSoilWorksheets: AdminHeader[],
    proctorWorksheets: AdminHeader[]
  }>();

  constructor(
    public userService: UserService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2,
    private nativeSoilService: NativeSoilService,
    private adminService: AllAdminService,
    private sharedService:SharedService,
  ) {}

  ngOnInit() {
    this.userService.userAccount.subscribe({
      next: () => {
        this.isManagerAdmin = this.userService.roleCheck(['Manager', 'Admin']);
        this.isLabTech = this.userService.roleCheck(['Labtech']);
        this.isEdit = this.userService.roleCheck(['Manager', 'Labtech', 'Admin']);
      },
    });
    this.getWorkSheets(this.projectId);
    this.fetchTabsData();
  }

  // Worksheet (Native Native /Compaction Sheet)
  private processWorksheetTab() {
    this.sWTButton = false;
    this.displayedColumns = [];
    this.headerColumn = [];
    this.activetab = 'workSheet';
    const moistureStatus = this.allResponseWorksheet.moistureWorksheets.some((worksheet: any) => worksheet.status);
    const asReceivedStatus = this.allResponseWorksheet.asRecivedWorksheets.some((worksheet: any) => worksheet.status);
    const nativeSoilType = this.allResponseWorksheet.nativeSoilWorksheets.map(
      (worksheet: any) => worksheet.worksheetType,
    );

    if (this.allResponseWorksheet.asRecivedWorksheets && this.allResponseWorksheet.moistureWorksheets) {
      if (nativeSoilType.includes('Bulk')) {
        if (!this.userService.roleCheck(['Labtech'])) {
          this.tabAssign = asReceivedStatus && moistureStatus;
        } else {
          this.tabAssign = true;
        }
      } else {
        this.tabAssign = true;
      }
    }

    this.adminService.getNativeCompaction().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumns = this.headerData.map((header) => header.key);
        this.dataSource = this.workSheets;
        this.getColumnkeyMatch();
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  // Moisture Content
  private processMoistureWorksheetsTab() {
    this.sWTButton = true;
    this.tabAssign = true;
    this.displayedColumns = [];
    this.headerColumn = [];
    this.activetab = 'moistureWorksheets';
    this.adminService.getNativeMoisture().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumns = this.headerData.map((header) => header.key);
        this.dataSource = this.moistureWorksheets;
        this.getColumnkeyMatch();
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  // Received Worksheet
  private processReceivedSheetTab() {
    this.sWTButton = false;
    this.tabAssign = true;
    this.displayedColumns = [];
    this.headerColumn = [];
    this.activetab = 'receivedSheet';
    this.adminService.getNativeReceived().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumns = this.headerData.map((header) => header.key);
        this.dataSource = this.receivedWorkSheet;
        this.getColumnkeyMatch();
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  // Proctor Worksheet
  private processProctorWorksheetsTab() {
    this.sWTButton = true;
    this.displayedColumns = [];
    this.headerColumn = [];
    this.activetab = 'proctorWorksheets';
    this.adminService.getNativeProctor().subscribe(
      (response) => {
        this.headerData = response;
        this.headerData.sort((a, b) => a.sortOrder - b.sortOrder);
        this.displayedColumns = this.headerData.map((header) => header.key);
        this.dataSource = this.proctorWorksheets;
        this.getColumnkeyMatch();
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching tabs data:', error);
      },
    );
  }

  getColumnkeyMatch() {
    this.headerKeyToRowKeyMapping = {
    };
    this.headerData.forEach((header) => {
      this.headerKeyToRowKeyMapping[header.key] = header.key;
    });
  }

  getTooltipText(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    return header ? header.tooltipText : '';
  }

  // Displays the header value based on sorted order
  getDisplayName(columnName: string): string {
    const header = this.headerData.find((header) => header.key === columnName);
    if (header) {
      return header.units ? `${header.displayName}<br> ${header.units}` : header.displayName;
    }
    return '';
  }

  // Displays the column row value
  getRowDataByKey(row: any, key: string): string {
    const rowKey = this.headerKeyToRowKeyMapping[key];
    const value = row[rowKey];
    if (key === 'collectionDate') {
      if (value === null) {
        return '';
      }
      const date = new Date(value);
      return date.toLocaleString('en-us', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });
    }
    if (key === 'depth') {
      if (value === null) {
        if (row.stockPile === true) {
          return 'Stockpile';
        }
        return '';
      }
      return `${row.depth}-${row.endDepth}`;
    }
    if (key === 'effort' && row.worksheetType === 'Tube') {
      return 'Tube';
    }
    if (key === 'assignedTo') {
      if (typeof value === 'object' && value !== null) {
        const { firstName, lastName } = value;
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        }
      } else if (typeof value === 'string') {
        return value;
      }
    }
    return value;
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.tabChange(event.index);
  }

  tabChange(index: number): void {
    switch (index) {
      case 1:
        this.processMoistureWorksheetsTab();
        break;
      case 2:
        this.processWorksheetTab();
        break;
      case 3:
        this.processProctorWorksheetsTab();
        break;
      default:
        this.processReceivedSheetTab();
    }
  }

  getWorkSheets(projectId: string): void {
    this.nativeSoilService.getNativeSoilWorkSheet(projectId).subscribe({
      next: (response: any) => {
        let initial = 1;
        this.allResponseWorksheet = response;

        // excel export data  started
        const allWorksheetApiRes = JSON.parse(JSON.stringify(response));
        const updateDepth = (worksheet: any) => {
          if (worksheet.depth == null && worksheet.endDepth === null && worksheet.stockPile === false) {
            worksheet.depth = '';
          } else if (worksheet.depth == null && worksheet.endDepth === null && worksheet.stockPile === true) {
            worksheet.depth = 'StockPile';
          } else if (worksheet.depth !== undefined && worksheet.endDepth !== undefined) {
            worksheet.depth = `${worksheet.depth}-${worksheet.endDepth}`;
          }
        };

        const customEffort = (worksheet: any) => {
          if (worksheet.worksheetType === 'Tube') {
            worksheet.effort = 'Tube';
          }
        };

        const customAssignTo = (worksheet: any) => {
          if (worksheet.assignedTo) {
            worksheet.assignedTo = worksheet.assignedTo.userName;
          }
        };
        // excel export data  ended
        this.receivedWorkSheet = response?.asRecivedWorksheets;
        this.receivedWorkSheet.forEach((worksheet: any) => {
          if (!worksheet.groupOrder) {
            initial += 1;
          }
          worksheet.sequence = initial;
          if (worksheet.collectionDate) {
            worksheet.collectionDate = this.getActualDate(worksheet.collectionDate);
          }
          worksheet.displayDepth = this.setDepth(worksheet);
          worksheet.assignedTo = this.mapUser(worksheet);
        });
        initial = 1;
        this.moistureWorksheets = response?.moistureWorksheets;
        this.moistureWorksheets.forEach((worksheet: any) => {
          if (!worksheet.groupOrder) {
            initial += 1;
          }
          worksheet.sequence = initial;
          worksheet.assignedTo = this.mapUser(worksheet);
        });
        initial = 1;
        this.proctorWorksheets = response?.proctorWorksheets;
        this.proctorWorksheets.forEach((worksheet: any) => {
          if (!worksheet.groupOrder) {
            initial += 1;
          }
          worksheet.sequence = initial;
          worksheet.displayDepth = this.setDepth(worksheet);
          worksheet.assignedTo = this.mapUser(worksheet);
        });
        initial = 1;
        this.workSheets = response?.nativeSoilWorksheets;
        this.workSheets.forEach((worksheet: any) => {
          if (!worksheet.groupOrder) {
            initial += 1;
          }
          worksheet.sequence = initial;
          worksheet.displayDepth = this.setDepth(worksheet);
          worksheet.assignedTo = this.mapUser(worksheet);
        });
        if (
          this.receivedWorkSheet.length === 0
          && this.moistureWorksheets.length === 0
          && this.proctorWorksheets.length === 0
        ) {
          this.isBulk = false;
          this.activetab = 'workSheet';
          this.workSheets.forEach((elem: NativeSoilType) => {
            if (elem?.worksheetType === 'Bulk') {
              this.isBulk = true;
              this.activetab = 'receivedSheet';
            }
          });
        }

        // received worksheets
        (allWorksheetApiRes.asRecivedWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customAssignTo(worksheet);
        });

        // moisture worksheets
        (allWorksheetApiRes.moistureWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customAssignTo(worksheet);
        });

        // proctor worksheets
        (allWorksheetApiRes.proctorWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customAssignTo(worksheet);
        });

        // native soil worksheets
        (allWorksheetApiRes.nativeSoilWorksheets || []).forEach((worksheet: any) => {
          updateDepth(worksheet);
          customEffort(worksheet);
          customAssignTo(worksheet);
        });

        this.allWorksheetApiRes = allWorksheetApiRes;
        this.allResNativeWorksheet.emit(this.allWorksheetApiRes);
        const ws = this.getWorkSheetType(this.activetab);
        this.tabChange(ws.type);
        this.showTable = true;
        this.cd.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching worksheets:', err);
      },
    });
  }

  private mapUser(worksheet: any): string | null {
    if (worksheet.assignedToId) {
      return `${worksheet.assignedTo.firstName} ${worksheet.assignedTo.lastName}`;
    }
    return null;
  }

  setDepth(elem: any): string {
    let depthRange = '';
    if (elem.depth !== null) {
      depthRange = `${elem.depth}`;
    }
    if (elem.endDepth) {
      if (elem.depth) {
        depthRange = `${elem.depth}-${elem.endDepth}`;
      }
    }
    return depthRange;
  }

  assignTo(worksheetId: NativeSoilType): void {
    this.dialog
      .open(AssignToLabtechComponent, {
        data: {
          worksheetId,
          type: this.activetab,
        },
        disableClose: true,
        width: '40%',
      })
      .afterClosed()
      .subscribe((assigned) => {
        if (assigned) {
          this.getWorkSheets(this.projectId);
          this.assigned.emit(true);
        }
      });
  }

  editWorksheet(element: NativeSoilType): void {
    const workSheetType = this.getWorkSheetType(this.activetab, 'edit');
    this.dialog
      .open(EditNativeSoilWorksheetComponent, {
        panelClass: 'worksheet-dialog',
        data: {
          element,
          worksheets: workSheetType.worksheet,
          mode: 'UPDATE',
          workSheetType: this.activetab,
          title: workSheetType.title,
          modalType: 'edit',
          isBulk: this.isBulk,
          isForm: workSheetType.isForm,
        },
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((value: string) => {
        if (value !== 'CLOSED') {
          this.getWorkSheets(this.projectId);
        }
      });
  }

  setWeightnTests(element: NativeSoilType): void {
    const workSheetType = this.getWorkSheetType(this.activetab, 'mswt');
    this.dialog
      .open(EditNativeSoilWorksheetComponent, {
        panelClass: 'worksheet-dialog',
        data: {
          element,
          worksheets: workSheetType.worksheet,
          mode: 'UPDATE',
          workSheetType: this.activetab,
          title: `${workSheetType.title} - Settings`,
          modalType: 'mswt',
          isBulk: this.isBulk,
          isForm: true,
        },
        disableClose: true,
        width: '50%',
      })
      .afterClosed()
      .subscribe((value: string) => {
        if (value !== 'CLOSED') {
          this.getWorkSheets(this.projectId);
        }
      });
  }

  deleteWorksheet(id: string): void {
    const workSheetType = this.getWorkSheetType(this.activetab);
    let canDelete = false;
    switch (workSheetType.type) {
      case 0:
        if (this.receivedWorkSheet.length > 1) {
          canDelete = true;
        } else {
          canDelete = false;
        }
        break;
      case 1:
        if (this.moistureWorksheets.length > 1) {
          canDelete = true;
        } else {
          canDelete = false;
        }
        break;
      case 2:
        if (
          this.workSheets.filter(
            (worksheet: NativeSoilType) => !(worksheet.worksheetType === 'Tube' && !worksheet.parentRow),
          ).length > 1
        ) {
          canDelete = true;
        } else {
          canDelete = false;
        }
        break;
      case 3:
        if (this.proctorWorksheets.length > 1) {
          canDelete = true;
        } else {
          canDelete = false;
        }
        break;
      default:
        break;
    }
    if (canDelete) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          panelClass: 'worksheet-dialog',
          data: {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((response?: string) => {
          if (response === 'YES') {
            this.nativeSoilService.deleteNativeSoilWorksheet(id, workSheetType.type).subscribe({
              next: () => {
                this.getWorkSheets(this.projectId);
                this.dialogService.open(messagesConfig.worksheetDeleteMessage);
              },
              error: () => {},
            });
          }
        });
    } else {
      this.dialogService.open(messagesConfig.worksheetDeleteValidation);
    }
  }

  getWorkSheetType(workSheetType: string, modelType?: string): WorkSheetType {
    let type = 0;
    let title = '';
    let worksheet;
    let isForm = false;
    switch (workSheetType) {
      case 'receivedSheet':
        type = 0;
        title = 'Native Soil Data';
        worksheet = this.receivedWorkSheet;
        isForm = true;
        break;
      case 'moistureWorksheets':
        type = 1;
        title = 'Moisture Conditioning Sheet';
        if (modelType === 'mswt') {
          worksheet = this.moistureWorksheets.filter((element: any) => element.parentRow);
        } else {
          worksheet = this.moistureWorksheets.filter((element: any) => !this.checkForMoisturesheetsValid(element));
        }
        isForm = false;
        break;
      case 'workSheet':
        type = 2;
        title = 'Worksheet';
        worksheet = this.workSheets.filter((element: any) => !element.groupOrder || element.worksheetType === 'Bulk');
        isForm = true;
        break;
      case 'proctorWorksheets':
        type = 3;
        title = 'Single Point Proctor - As Rcvd M/C';
        worksheet = this.proctorWorksheets;
        isForm = true;
        break;
      default:
        type = 0;
        title = 'Native Soil Data';
        worksheet = this.receivedWorkSheet;
        isForm = true;
        break;
    }
    return {
      type,
      title,
      worksheet,
      isForm,
    };
  }

  findSpecificParent(element: HTMLElement, parentClassName: string): HTMLElement | null {
    while (element && !element.classList.contains(parentClassName)) {
      element = element.parentNode as HTMLElement;
    }
    return element;
  }

  checkForEditEnable(worksheet: NativeSoilType): boolean {
    if (this.activetab === 'moistureWorksheets') {
      return this.checkForMoisturesheetsValid(worksheet);
    }

    if (this.activetab === 'proctorWorksheets') {
      return this.checkForProctorsheetsValid(worksheet);
    }
    if (this.activetab === 'workSheet') {
      return this.checkForWorksheetValid(worksheet);
    }
    return false;
  }

  checkForMoisturesheetsValid(worksheet: NativeSoilType): boolean {
    if (Number(worksheet.drySoil) !== 0 && Number(worksheet.moistureContent) !== 0) {
      return false;
    }
    return true;
  }

  checkForProctorsheetsValid(worksheet: NativeSoilType): boolean {
    if (Number(worksheet.moistureContent) !== 0 && Number(worksheet.drySoil) !== 0) {
      return false;
    }
    return true;
  }

  checkForWorksheetValid(worksheet: NativeSoilType): boolean {
    if (worksheet.assignedTo !== null && (worksheet.worksheetType === 'Tube' || worksheet.worksheetType === 'Bulk')) {
      return false;
    }
    return true;
  }

  getActualDate(date: string | Date): Date {
    const utcDate = new Date(date);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    return localDate;
  }

  // export .xlsx file that purpose only create // starting
  fetchTabsData(): void {
    this.sharedService.fetchAllTabsData();
    this.sharedService.headerData$.subscribe((data) => {
      if (data) {
        this.fetchAllHeader.emit(data);
      }
    });
  }
  // export .xlsx file that purpose only create // ending
}
