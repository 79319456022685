/* eslint-disable max-len */
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { WorksheetService } from 'src/app/features/worksheet/services/worksheet.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sample, EmailWorksheetInfo, EmailPayload, CommentInfo } from '../../models/worksheet-checkbox.model';
import { messagesConfig } from '../../constants/message.constants';
import { IMessage } from '../../models/message.modal';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: 'app-mail-preview',
  templateUrl: './mail-preview.component.html',
  styleUrls: ['./mail-preview.component.scss'],
})
export class MailPreviewComponent implements OnInit, AfterViewInit {
  errorMessages: EmailWorksheetInfo;
  tableMessage: Sample[] = [];
  instructionMessage: CommentInfo;
  customMessage: {
    sampleIdNames: string;
    sampleColectionDate: string;
    sampleTypeData: string;
    sampleDepthData: string;
    messages: string[];
    sampleStockpile:boolean;
    sampleStatusTypeId: string;
  }[] = [];

  sampleIdName = '';
  sampleStatusTypeId = '';
  soiltypes: string;
  statusLabels: { [key: string]: string } = {
    '33de1f0d-8209-4d52-a4c7-18e4918d9932': 'Damaged',
    '2320323b-65e5-4270-95ce-6fa221d3c0b5': 'Missing',
    'd471cab0-df24-4a80-a670-9994fbdf5f6f': 'Received',
  };

  emailPayloadContent = '';
  receivedSamples: any = {
  };

  missingDamageSamples: any = {
  };

  instructionComment: CommentInfo;
  projectId: string;
  emailContent: string;
  public createSuccess: MatDialogRef<MessageModalComponent>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MailPreviewComponent>,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private worksheetService: WorksheetService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit(): void {
    this.projectId = this.data.projectId;
    this.customMessage = [];
    this.errorMessages = this.data.fullInfo || {
    };
    this.tableMessage = this.data.table || [];
    this.soiltypes = this.data.soilType;
    this.instructionMessage = this.data.instruction;

    if (this.soiltypes === 'precast') {
      this.precastMailTemplate();
    } else {
      this.nativeSoilMailTemplate();
    }
    this.constructEmailContent();
    this.insertEmailContent();
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  precastMailTemplate() {
    this.missingDamageSamples = this.data.table.filter(
      (item: Sample) => item.sampleId === false
        && item.sampleType === false
        && item.sampleCollectionDate === false
        && item.mixDesignCode === false
        && item.numSamplesPerSet === false
        && item.truckNumber === false
        && item.ticketNumber === false
        && (item.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932'
          || item.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'),
    );
    this.receivedSamples = this.data.table
      .filter(
        (item: Sample) => item.sampleId === false
          && item.sampleType === false
          && item.sampleCollectionDate === false
          && item.mixDesignCode === false
          && item.numSamplesPerSet === false
          && item.truckNumber === false
          && item.ticketNumber === false
          && item.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f',
      )
      .map((item: Sample) => ({
        sampleIdName: item.sampleIdName,
        sampleTypeData: item.sampleTypeData,
        sampleColDate: item.sampleColDate,
      }));

    this.tableMessage.forEach((item: Sample) => {
      const errors: string[] = [];
      if (item.sampleId) {
        errors.push('Sample ID - does not match, missing or needs further clarification');
      }
      if (item.sampleType) {
        errors.push('Sample Type - does not match, missing or needs further clarification');
      }
      if (item.sampleCollectionDate) {
        errors.push('Collection Date - does not match, missing or needs further clarification');
      }
      if (item.mixDesignCode) {
        errors.push('Design Code - does not match, missing or needs further clarification');
      }
      if (item.numSamplesPerSet) {
        errors.push('Samples Per Set - does not match, missing or needs further clarification');
      }
      if (item.truckNumber) {
        errors.push('Truck # - does not match, missing or needs further clarification');
      }
      if (item.ticketNumber) {
        errors.push('Ticket # - does not match, missing or needs further clarification');
      }
      if (errors.length > 0) {
        this.customMessage.push({
          sampleIdNames: item.sampleIdName ?? '',
          sampleColectionDate: item.sampleColDate ?? '',
          sampleTypeData: item.sampleTypeData ?? '',
          sampleStatusTypeId: item.sampleStatusTypeId ?? '',
          sampleDepthData: item.sampleDepthData ?? '',
          sampleStockpile: item.sampleStockpile ?? false,
          messages: errors,
        });
      }
    });
  }

  nativeSoilMailTemplate() {
    if (this.data && this.data.table) {
      this.missingDamageSamples = this.data.table.filter(
        (item: Sample) => !item.sampleId
          && !item.sampleType
          && !item.sampleDepth
          && !item.collectionDate
          && !item.soilDescription
          && !item.maxDryDensity
          && !item.optimumMoistureContent
          && !item.compactionEffort
          && !item.moistureOptimum
          && (item.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932'
            || item.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'),
      );

      this.receivedSamples = this.data.table
        .filter(
          (item: Sample) => !item.sampleId
            && !item.sampleType
            && !item.sampleDepth
            && !item.collectionDate
            && !item.soilDescription
            && !item.maxDryDensity
            && !item.optimumMoistureContent
            && !item.compactionEffort
            && !item.moistureOptimum
            && item.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f',
        )
        .map((item: Sample) => ({
          sampleIdName: item.sampleIdName,
          sampleTypeData: item.sampleTypeData,
          sampleColDate: item.sampleColDate,
          sampleDepthData: item.sampleDepthData,
        }));
      this.tableMessage.forEach((item: Sample) => {
        const errors: string[] = [];
        if (item.sampleId) {
          errors.push('Sample ID - does not match, missing or needs further clarification');
        }
        if (item.sampleType) {
          errors.push('Sample Type - does not match, missing or needs further clarification');
        }
        if (item.sampleDepth) {
          errors.push('Sample Depth - does not match, missing or needs further clarification');
        }
        if (item.collectionDate) {
          errors.push('Collection Date - does not match, missing or needs further clarification');
        }
        if (item.soilDescription) {
          errors.push('Soil Description - does not match, missing or needs further clarification');
        }
        if (item.maxDryDensity) {
          errors.push('Max Dry Density - does not match, missing or needs further clarification');
        }
        if (item.optimumMoistureContent) {
          errors.push('Optimum M/C - does not match, missing or needs further clarification');
        }
        if (item.compactionEffort) {
          errors.push('Compaction Effort - does not match, missing or needs further clarification');
        }
        if (item.moistureOptimum) {
          errors.push('Moisture Optimum - does not match, missing or needs further clarification');
        }

        if (errors.length > 0) {
          this.customMessage.push({
            sampleIdNames: item.sampleIdName ?? '',
            sampleColectionDate: item.sampleColDate ?? '',
            sampleTypeData: item.sampleTypeData ?? '',
            sampleStatusTypeId: item.sampleStatusTypeId ?? '',
            sampleDepthData: item.sampleDepthData ?? '',
            sampleStockpile: item.sampleStockpile ?? false,
            messages: errors,
          });
        }
      });
    }
  }

  hasCompanyErrors(): boolean {
    if (this.errorMessages.companyInfo) {
      return Object.values(this.errorMessages.companyInfo).some((value) => value === true);
    }
    return false;
  }

  hasBillingErrors(): boolean {
    if (this.errorMessages.billingInfo) {
      return Object.values(this.errorMessages.billingInfo).some((value) => value === true);
    }
    return false;
  }

  hasProjectErrors(): boolean {
    if (this.errorMessages.projectInfo) {
      return Object.values(this.errorMessages.projectInfo).some((value) => value === true);
    }
    return false;
  }

  hasInstructionErrors(): boolean {
    if (this.instructionMessage?.commentInfo) {
      return Object.values(this.instructionMessage.commentInfo).some((value) => value === true);
    }
    return false;
  }

  constructEmailContent(): void {
    // Company Information
    let image = '';
    image = `
   
    <div class="header">
      <img src="../../../../assets/images/mail-logo.svg" alt="email logo" />
    </div>`;
    let projectName = '';
    if (this.data.projectName) {
      projectName = `
        <p style="font-size:14px;color:#333;">
            Thank you for sending your samples for <strong>${this.data.projectName}</strong>${this.data.projectNo ? ` and <strong>${this.data.projectNo}</strong>` : ''} to our lab for thermal analysis. Below is a list of items we require additional
            information to begin our testing. Please refer to the list below.
        </p>`;
    }
    let contactName = '';
    if (this.data.contactName) {
      contactName = `
      <div class="contactName" style="margin-top:5px;margin-bottom:15px;">
        <strong>“${this.data.contactName}”,</strong>
      </div> `;
    }
    let instructionHtml = '';
    if (this.hasInstructionErrors()) {
      if (this.soiltypes === 'precast') {
        instructionHtml = `
        <div style="background-color:#fdecea;margin:0px 0px 10px 0px;padding:0;">
          <strong>Instructions by sample type</strong>
          <ul style="padding-left:25px;margin-bottom:1rem;">
          ${
  this.instructionMessage.commentInfo?.comments
    ? '<li style="padding:5px 0;"> Need further clarification (refer to additional notes below)</li>'
    : ''
}
          </ul>
        </div>
      `;
      } else {
        instructionHtml = `
        <div style="background-color:#fdecea;margin:0px 0px 10px 0px;padding:0;">
          <strong>Additional comments from client</strong>
          <ul style="padding-left:25px;margin-bottom:1rem;">
          ${
  this.instructionMessage.commentInfo?.comments
    ? '<li style="padding:5px 0;"> Need further clarification (refer to additional notes below)</li>'
    : ''
}
          </ul>
        </div>
      `;
      }
    }
    let companyInfoHtml = '';
    if (this.hasCompanyErrors()) {
      companyInfoHtml = `
        <div style="background-color:#fdecea;margin:0px 0px 10px 0px; padding:0;">
          <strong>Company Information</strong>
          <ul style="padding-left:25px;margin-bottom:1rem;">
          ${
  this.errorMessages.companyInfo.companyName
    ? '<li  style="padding:5px 0;"> Company Name (Requires further clarification or information is missing)</li>'
    : ''
}
          ${
  this.errorMessages.companyInfo.companyContactName
    ? '<li  style="padding:5px 0;"> Contact Name (Requires further clarification or information is missing)</li>'
    : ''
}
          ${
  this.errorMessages.companyInfo.companyEmail
    ? '<li  style="padding:5px 0;"> Contact Email (Requires further clarification or information is missing)</li>'
    : ''
}
          ${
  this.errorMessages.companyInfo.companyAddress
    ? '<li  style="padding:5px 0;"> Address (Requires further clarification or information is missing)</li>'
    : ''
}
          </ul>
        </div>
      `;
    }
    // Billing Information
    let billingInfoHtml = '';
    if (this.hasBillingErrors()) {
      billingInfoHtml = `
      <div style="background-color:#fdecea;margin:0px 0px 10px 0px; padding:0;">
        <strong>Billing Information</strong>
          <ul style="padding-left:25px;margin-bottom:1rem;">
            ${
  this.errorMessages.billingInfo.billingName
    ? '<li  style="padding:5px 0;"> Name (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.billingInfo.billingContactName
    ? '<li  style="padding:5px 0;"> Contact Name (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.billingInfo.billingEmail
    ? '<li  style="padding:5px 0;"> Contact Email (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.billingInfo.billingAddress
    ? '<li  style="padding:5px 0;"> Address (Requires further clarification or information is missing)</li>'
    : ''
}
          </ul>
      </div>`;
    }
    // Project Information
    let projectInfoHtml = '';
    if (this.hasProjectErrors()) {
      projectInfoHtml = `
        <div style="background-color:#fdecea; margin:0px 0px 10px 0px; padding:0;">
          <strong>Project Information</strong>
          <ul style="padding-left:25px;margin-bottom:1rem;">
            ${
  this.errorMessages.projectInfo.projectName
    ? '<li style="padding:5px 0;"> Project Name (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.projectInfo.projectLocation
    ? '<li style="padding:5px 0;"> Project Location (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.projectInfo.projectJobNo
    ? '<li style="padding:5px 0;"> Project Job Number (Requires further clarification or information is missing)</li>'
    : ''
}
            ${
  this.errorMessages.projectInfo.projectNumber
    ? '<li style="padding:5px 0;"> Project Number (Requires further clarification or information is missing)</li>'
    : ''
}
          </ul>
        </div>
      `;
    }
    // missing or damaged
    let missingDamageSamplesHtml = '';
    if (Array.isArray(this.missingDamageSamples)) {
      missingDamageSamplesHtml = this.missingDamageSamples
        .map((record: any) => {
          let collectedOn = '';
          if (this.soiltypes === 'precast') {
            collectedOn = `${record.sampleTypeData} - (Collected on ${this.formatDate(record.sampleColDate)})`;
          } else if (record.sampleDepthData === 'null-null' && record.stockPile === true) {
            collectedOn = `(Stockpile) - ${record.sampleTypeData}`;
          } else if (record.sampleDepthData === 'null-null' && record.stockPile === false) {
            collectedOn = `${record.sampleTypeData}`;
          } else {
            collectedOn = `@${record.sampleDepthData} (ft) - ${record.sampleTypeData}`;
          }
          return `
          <div style="background-color:#fdecea;margin:0px 0px 10px 0px;padding:0;">
            <strong>${record.sampleIdName} - ${collectedOn} - is ${this.statusLabels[record.sampleStatusTypeId]}</strong>
          </div>`;
        })
        .join('');
    }
    // mismatch section
    const customMessageHtml = this.customMessage
      .map((errorMessage) => {
        let collectedOn = '';
        if (this.soiltypes === 'precast') {
          collectedOn = `${errorMessage.sampleTypeData}  -  (Collected on ${this.formatDate(
            errorMessage.sampleColectionDate,
          )})`;
        } else if (errorMessage.sampleDepthData === 'null-null' && errorMessage.sampleStockpile === true) {
          collectedOn = ` (Stockpile)  -  ${errorMessage.sampleTypeData}`;
        } else if (errorMessage.sampleDepthData === 'null-null' && errorMessage.sampleStockpile === false) {
          collectedOn = `${errorMessage.sampleTypeData}`;
        } else {
          collectedOn = `@${errorMessage.sampleDepthData} (ft)  -  ${errorMessage.sampleTypeData}`;
        }
        return `
        <div style="background-color:#fdecea;margin:0px 0px 10px 0px;padding:0;">
          <strong>${errorMessage.sampleIdNames}  -  ${collectedOn}  -  is ${
  this.statusLabels[errorMessage.sampleStatusTypeId]
}</strong>
            <ul style="padding-left:25px;margin-bottom:1rem;">
              ${errorMessage.messages
    .map((message) => `<li style="padding:5px 0;">${message}</li>`)
    .join('')}
            </ul>
        </div>`;
      })
      .join('');
    // received section
    let receivedSamplesHtml = '';
    if (this.receivedSamples.length > 0) {
      receivedSamplesHtml = `
      <p style="font-size:14px;color:#333;">Below is a list of samples received with no issue:</p>
      <div style="background-color:#e2f4ea;border:1px solid #c3e6cb;padding:10px;border-radius:5px;margin:10px 0;">
      <ul style="margin:0;padding-left:25px;">
      ${this.receivedSamples
    .map((sample:any) => {
      let collectedOn = '';
      if (this.soiltypes === 'precast') {
        collectedOn = `-${sample.sampleTypeData}  -  (Collected on ${this.formatDate(sample.sampleColDate)})`;
      } else {
        collectedOn = '';
      }
      return `
              <li style="padding:5px 0;font-weight:600;"> ${sample.sampleIdName}   ${collectedOn}</li>`;
    })
    .join('')}
      </ul>
    </div>
  `;
    }
    // additional comment info
    let additionalNotesHtmlInfo = '';
    if (this.data.additionalNotes) {
      additionalNotesHtmlInfo = `
        <p style="font-size:14px;color:#333;">Please see attachments and “Additional Notes” for added clarification.</p>
       `;
    }
    // additional Comment
    let additionalNotesHtml = '';
    if (this.data.additionalNotes) {
      additionalNotesHtml = `
        <div style="background-color:#f5f5f5;padding:20px;border-radius:5px;margin-top:20px;border-left:5px solid #5bc0de;">
          <strong>Additional Notes:</strong>
          <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">${this.data.additionalNotes}</p>
        </div>`;
    }

    // all error section
    let errorContent = '';
    if (companyInfoHtml || billingInfoHtml || projectInfoHtml || missingDamageSamplesHtml || customMessageHtml || instructionHtml) {
      errorContent = `
      <div style="background-color:#fdecea;border:1px solid #f5c6cb;padding:10px;border-radius:5px;margin:10px 0;">
      ${companyInfoHtml}
      ${billingInfoHtml}
      ${projectInfoHtml}
      ${missingDamageSamplesHtml}
      ${customMessageHtml}
      ${instructionHtml}
    </div>`;
    } else {
      errorContent = '';
    }
    // Constructing final email content
    this.emailContent = `
      <style>
        body {
            font-family:Roboto, sans-serif;
            margin:0;
            padding:0;
            background-color:#f6f6f6;
        }
        .header {
            text-align:left;
            margin-bottom:20px;
            background-color:#363737;
            height:70px;
            img {
              max-width:200px;
              margin:8px 0px 0px 8px;
            }
        }
        .info-box.disabled {
            display:none;
        }
       
      </style>
  
      <div style="max-width:700px; margin:20px auto;background-color:#fff;border:1px solid #ddd;border-radius:5px;">
        ${image}
        <div style="padding:0px 20px 20px 20px;">
          ${contactName}
          ${projectName}
          ${errorContent}
          
          ${receivedSamplesHtml}
          ${additionalNotesHtmlInfo}
         
          <p style="font-size:14px;color:#333;">
            If you have any questions regarding this request, please send an email to
            <a style ="color:#337ab7;text-decoration:none;" href="mailto:samples@geothermusa.com">samples@geothermusa.com</a>.
          </p>
          <p style="font-size:14px;color:#333;">Thank you for your time and assistance. We look forward to hearing from you.</p>
          <p style="font-size:14px;color:#333;">Sincerely,<br />Geotherm</p>
            ${additionalNotesHtml}
        </div>
        <div style="text-align:center;font-size:12px;margin-top:20px;padding-top:10px;border-top:1px solid #ddd;">
          <p style="font-size:14px;color:#333;">
            This e-mail, including any attachments, is for the sole use of the intended recipient and may <br/>
            contain confidential and privileged information.
          </p>
          <p style="font-size:14px;color:#333;">
            Any unauthorized review, use, disclosure, or distribution is prohibited.<br/>
          </p>
        </div>
      </div>
    `;
    this.emailPayloadContent = `
      <div style="max-width:700px;margin:20px auto; background-color:#fff;border:1px solid #ddd;border-radius:5px;">
        <div style="padding:0px 20px 20px 20px;">
          ${contactName}
          ${projectName}
          ${errorContent}
          ${receivedSamplesHtml}
          ${additionalNotesHtmlInfo}
         
          <p style="font-size:14px;color:#333;">
            If you have any questions regarding this request, please send an email to
            <a style ="color:#337ab7;text-decoration:none;" href="mailto:samples@geothermusa.com">samples@geothermusa.com</a>.
          </p>
          <p style="font-size:14px;color:#333;">Thank you for your time and assistance. We look forward to hearing from you.</p>
          <p style="font-size:14px;color:#333;">Sincerely,<br />Geotherm</p>
            ${additionalNotesHtml}
        </div>
        <div style="text-align:center;font-size:12px;margin-top:20px;padding-top:10px;border-top:1px solid #ddd;">
          <p style="font-size:14px;color:#333;">
            This e-mail, including any attachments, is for the sole use of the intended recipient and may <br/>
            contain confidential and privileged information.
          </p>
          <p style="font-size:14px;color:#333;">
            Any unauthorized review, use, disclosure, or distribution is prohibited.<br/>
          </p>
        </div>
      </div>
    `;
  }

  formatDate(sampleColDate: string): string {
    const dateObj = new Date(sampleColDate);
    const monthAbbrev = dateObj.toLocaleString('en-us', {
      month: 'short',
    });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${monthAbbrev} ${day} ${year}`;
  }

  insertEmailContent(): void {
    const emailContainer = this.el.nativeElement.querySelector('#emailContent');
    if (emailContainer) {
      emailContainer.innerHTML = this.emailContent;
      this.infoBoxVisibility();
    }
  }

  private infoBoxVisibility(): void {
    const infoBoxElements = this.el.nativeElement.querySelectorAll('.info-box');
    infoBoxElements.forEach((element: HTMLElement) => {
      if (element.innerText.trim().length === 0) {
        this.renderer.addClass(element, 'disabled');
      } else {
        this.renderer.removeClass(element, 'disabled');
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  send() {
    const emailcontent: EmailPayload = {
      emailData: `<!DOCTYPE html>
      <html lang="en"><head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Email Notification</title>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      ${this.emailContent.match(/<style>[\s\S]*?<\/style>/)?.[0] || ''}
      </head>
      <body>
      ${this.emailPayloadContent.replace(/<style>[\s\S]*?<\/style>/, '')}
      </body>
      </html>
    `,
    };

    const payload: any = {
      emailBody: emailcontent.emailData,
      fileNames: this.data.uploadedFilesList,
    };
    if (this.projectId) {
      this.worksheetService.sendEmailNotification(this.projectId, payload).subscribe({
        next: () => {
          const successMessage: IMessage = messagesConfig.emailSendSuccessfully;
          this.showUpdatePopUpMessage(successMessage, '/dashboard');
          sessionStorage.removeItem('uploadedFilesList');
        },
        error: (err) => {
          console.error('Error sending email:', err);
        },
      });
    }
  }

  showUpdatePopUpMessage(successMessage: IMessage, routeTo: string): void {
    this.createSuccess = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.createSuccess.afterClosed().subscribe(() => {
      if (routeTo) {
        this.cancel();
        this.router.navigate([routeTo]);
      }
    });
  }
}
