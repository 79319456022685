import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService } from '../../services/report.service';

interface Recommendation {
  reportRecommendation: string;
}
@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements OnInit, OnChanges {
  editorContent: Recommendation = {
    reportRecommendation: '',
  };

  @Input() reportId: string;
  @Input() recommendation: Recommendation;
  constructor(private reportService: ReportService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getRecommendationAPi();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['recommendation'] && changes['recommendation'].currentValue) {
      this.editorContent = changes['recommendation'].currentValue;
    }
  }

  onContentChanged(content: string) {
    this.editorContent.reportRecommendation = content;
  }

  getRecommendationAPi() {
    if (this.reportId) {
      this.reportService.getRecommend(this.reportId).subscribe((response: Recommendation) => {
        this.editorContent.reportRecommendation = response.reportRecommendation;
      });
    }
  }

  saveBtn() {
    this.reportService.saveRecommend(this.editorContent, this.reportId).subscribe({
      next: (response) => {
        if (response) {
          this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
        }
      },
      error: () => {},
    });
  }
}
