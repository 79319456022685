<div class="map-model">
  <h2 mat-dialog-title class="mat-h2">
    Sample Details
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-5">
        <h1 class="mat-h1">{{ cityName }}</h1>
        <mat-label class="mat-subtitle-2">{{ typeLabel }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(value)]="selectedSoil" (selectionChange)="selectSoilType()">
            <mat-option *ngFor="let soilType of soilTypes" [value]="soilType">{{ soilType.label }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-label class="mat-subtitle-2">Density lb/ft3</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(value)]="selectedDensity" (selectionChange)="selectDensity()" [disabled]="densityDisable">
            <mat-option *ngFor="let density of densities" [value]="density">{{ density.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-label class="mat-subtitle-2">Moisture content (%)</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(value)]="selectedMoisture" (selectionChange)="selectMoisture()" [disabled]="moistureDisable">
            <mat-option *ngFor="let moisture of moistures" [value]="moisture">{{ moisture.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        
        <!-- <div class="btn-style">
          <mat-label class="download-btn" (click)="downloadData()">Download Data</mat-label>
        </div> -->
        <mat-label class="mat-subtitle-2" style="font-weight: bold">Average Density : </mat-label>
        <span style="background-color: lightgoldenrodyellow">{{ this.dryDensityAvg }} lb/ft3</span> <br />
        <mat-label class="mat-subtitle-2" style="font-weight: bold">Average Moisture content : </mat-label>
        <span style="background-color: lightblue">{{ this.moistureAvg }} %</span>
      </div>
      <div class="col-7">
        <app-line-chart [lineChartData]="lineChartData"></app-line-chart>
      </div>
    </div>
  </mat-dialog-content>
</div>
