export const PdfStyles = {
  header: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 10],
  },
  subheader: {
    fontSize: 16,
    bold: true,
    margin: [0, 10, 0, 5],
  },
  alignRight: {
    alignment: 'right',
    fontSize: 11,
  },
  blueLink: {
    color: 'blue',
    decoration: 'underline',
  },
  datebold: {
    bold: true,
    margin: [0, 0, 0, 20],
  },
  bold: {
    bold: true,
  },
  centerTitle: {
    alignment: 'center',
    fontSize: 11,
    bold: true,
  },
  centerTitle2: {
    alignment: 'center',
    fontSize: 11,
    bold: true,
    margin: [10, 0, 0, 20],
  },
  mainBody: {
    margin: [30, 10, 30, 10],
    fontSize: 11,
    lineHeight: 1.3,
    alignment: 'justify',

  },
  mainBodyTitle: {
    fontSize: 11,
    lineHeight: 1.3,
    bold: true,
  },
  tableHeaderStyle: {
    alignment: 'center',
    fontSize: 9,
    bold: true,
    margin: [10, 10, 0, 0],
  },
  tableDataStyle: {
    alignment: 'center',
    fontSize: 9,
  },
  tableHeaderMerged: {
    alignment: 'center',
    fontSize: 9,
    bold: true,
  },
  imageStyle: {
    alignment: 'center',
  },
  canvasStyle: {
    alignment: 'center',
    bold: true,
    fontSize: 9,
    margin: [10, 0, 0, 10],
  },
  canvasStylef: {
    alignment: 'center',
    bold: true,
    fontSize: 9,
    margin: [10, 20, 0, 10],
  },
  figureStyle: {
    alignment: 'right',
    bold: true,
    fontSize: 9,
    margin: [10, 10, 75, 0],
  },
  bottomRightDate: {
    alignment: 'left',
    bold: true,
    fontSize: 9,
    margin: [70, 10, 0, 0],

  },
  sampleHead: {
    bold: true,
    decoration: 'underline',
    margin: [30, 0, 30, 15],
    fontSize: 11,
  },
  footer: {
    alignment: 'center',
    fontSize: 9,
  },
  footer1: {
    alignment: 'center',
    fontSize: 9,
  },

};
