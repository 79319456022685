/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import * as XLSX from 'xlsx';

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
};

const getFileName = (projectName: string): { wsFileName: string } => {
  const formattedDate = formatDate(new Date());
  const wsFileName = `${projectName}-${formattedDate}`;
  return {
    wsFileName,
  };
};
const getFileNameUSDA = (projectName: string, projectNumber:string, companyName:string): { fileName: string } => {
  const fileName = `${companyName} ${projectName} ${projectNumber}_USDA Weight`;
  return {
    fileName,
  };
};

const customSheetNames: { [key: string]: string } = {
  asRecivedWorksheets: 'AS RECEIVED M C',
  moistureWorksheets: 'MOISTURE CONDITIONING SHEET',
  nativeSoilWorksheets: 'WORKSHEET',
  proctorWorksheets: 'PROCTOR WORKSHEET',
};

export class ExportTableUtil {
  static exportShipmentTableToExcel(
    data: any[],
    columns: string[],
    projectName: string,
    projectNumber: string,
    companyName: string,
  ) {
    const { fileName } = getFileNameUSDA(projectName, projectNumber, companyName);
    const rows: any[][] = [];
    // Add project details ( top of table update the custom details)
    rows.push(['Project Name:', projectName]);
    rows.push(['Company Name:', companyName]);
    rows.push(['Project Number:', projectNumber]);
    rows.push([]); // Empty row oru line spacing
    if (data.length > 0) {
      rows.push(columns);
      data.forEach((item) => {
        const rowData = columns.map((header) => item[header]);
        rows.push(rowData);
      });
    }
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Shipment Received');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportWorksheetTable(
    headerData: any,
    rowData: any,
    projectName: string,
    projectNumber:string,
    name:string,
    sampleReceivedDate?:string,
    instructionsConfirmedAt?:string,
    dueDate?:string,
    ovenDate?:string,
    wetTestData?:string,
    dryTestData?:string,
    geothermField?:boolean,
    rushTesting?:boolean,
    noteTest?:string,
    notesUpdatedBy?:string,
  ) {
    const { wsFileName } = getFileName(projectName);
    const workbook = XLSX.utils.book_new();
    const worksheetNames = Object.keys(headerData);

    worksheetNames.forEach((sheetName) => {
      const headers = headerData[sheetName];
      const rows = rowData[sheetName];
      if (!rows || rows.length === 0) { // if any response value is empty will ignore the tabs
        return;
      }
      const worksheetData: any[][] = [];
      worksheetData.push(['Project Name:', projectName]);
      worksheetData.push(['Project Number:', projectNumber]);
      worksheetData.push(['Customer Name:', name]);
      worksheetData.push(['Sample Received:', sampleReceivedDate]);
      worksheetData.push(['Instructions Confirmed:', instructionsConfirmedAt]);
      worksheetData.push(['Due Date:', dueDate]);
      worksheetData.push(['Oven Data:', ovenDate]);
      worksheetData.push(['Wet Test Data:', wetTestData]);
      worksheetData.push(['Dry Test Data:', dryTestData]);
      worksheetData.push(['Geotherm Field Testing:', geothermField]);
      worksheetData.push(['Rushed:', rushTesting]);
      worksheetData.push([]);

      worksheetData.push(['Notes Updated BY:', notesUpdatedBy]);
      worksheetData.push(['Notes:', noteTest]);
      worksheetData.push([]);
      // worksheetData.push([sheetName]);

      // Extract and map headers
      const headerRow = headers.map((header: { displayName: any }) => header.displayName);
      worksheetData.push(headerRow);
      rows.forEach((row: any) => {
        const rowData = headers.map((header: { key: string | number }) => row[header.key]);
        worksheetData.push(rowData);
      });
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);
      const customSheetName = customSheetNames[sheetName] || sheetName;
      // XLSX.utils.book_append_sheet(workbook, ws, sheetName);
      XLSX.utils.book_append_sheet(workbook, ws, customSheetName);
    });

    XLSX.writeFile(workbook, `${wsFileName}.xlsx`);
  }

  static exportPrecastWorksheetTable(
    headerData: any[],
    rowData: any[],
    projectName: string,
    projectNumber:string,
    name:string,
    sampleReceivedDate?:string,
    instructionsConfirmedAt?:string,
    dueDate?:string,
    ovenDate?:string,
    wetTestData?:string,
    dryTestData?:string,
    geothermField?:boolean,
    rushTesting?:boolean,
    noteTest?:string,
    notesUpdatedBy?:string,
  ) {
    const { wsFileName } = getFileName(projectName);
    const workbook = XLSX.utils.book_new();
    const worksheetData: any[][] = [];
    // Extract and map headers
    worksheetData.push(['Project Name:', projectName]);
    worksheetData.push(['Project Number:', projectNumber]);
    worksheetData.push(['Customer Name:', name]);
    worksheetData.push(['Sample Received:', sampleReceivedDate]);
    worksheetData.push(['Instructions Confirmed:', instructionsConfirmedAt]);
    worksheetData.push(['Due Date:', dueDate]);
    worksheetData.push(['Oven Data:', ovenDate]);
    worksheetData.push(['Wet Test Data:', wetTestData]);
    worksheetData.push(['Dry Test Data:', dryTestData]);
    worksheetData.push(['Geotherm Field Testing:', geothermField]);
    worksheetData.push(['Rushed:', rushTesting]);
    worksheetData.push([]);
    worksheetData.push(['Notes Updated BY:', notesUpdatedBy]);
    worksheetData.push(['Notes:', noteTest]);
    worksheetData.push([]);
    const headerRow = headerData.map((header: { displayName: string }) => header.displayName);
    worksheetData.push(headerRow);
    // Map the rows according to the headers
    rowData.forEach((row: any) => {
      const rowDataArray = headerData.map((header: { key: string }) => row[header.key]);
      worksheetData.push(rowDataArray);
    });
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, ws, 'Pre Cast Worksheet');
    XLSX.writeFile(workbook, `${wsFileName}.xlsx`);
  }
}
