import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ChartOptions, ChartDataset, Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
import { IPoints } from 'src/app/shared/models/graph-points.modal';
import { IADDRESS } from 'src/app/shared/models/i-address.modal';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';
import { ISIGNATURE } from 'src/app/shared/models/i-signature.modal';
import { SoilType } from 'src/app/shared/constants/soil-type.constants';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [DatePipe],
})
export class PreviewComponent implements OnInit {
  @ViewChildren('canvasRef') canvasRefs: QueryList<ElementRef<HTMLCanvasElement>>;
  @Output() getImageCount: EventEmitter<number> = new EventEmitter<number>();
  chart: Chart;
  @Input() previewData: REPORTPREVIEW;
  @Input() address: IADDRESS;
  @Input() signature: ISIGNATURE;
  @Input() soilType: string;
  @Input() PONumber: string;
  @Input() projectId: string;
  public lineChartData: ChartDataset[] = [];
  public lineChartDataArray: any[] = [];
  public textDataArray: any[] = [];
  splitCount = 6;
  data: any;
  figures: any[] = [];
  leftBorderColor = ['#E43131', '#3FC828', '#19B7E9', '#AD1CE0', '#9F3A0F', '#000000'];
  constructor(private reportService: ReportService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.getGraphData();
    // this.splitCount = this.soilType === SoilType.Native ? 1 : 6;
  }

  getGraphData(): void {
    this.reportService.getGraphData(this.projectId, this.soilType)?.subscribe({
      next: (data) => {
        const lineChartDataArray: any[] = [];
        const textDataArray: any[] = [];
        const textForDraw: any[] = [];
        let colorIndex = 0;
        this.data = data;
        data.forEach((graphDataArray: any[], i: number) => {
          const graphPoints: IPoints[] = [];
          graphDataArray.reverse();
          this.lineChartData.push({
            type: 'line',
            data: [],
            tension: 0.3,
            borderColor: this.leftBorderColor[colorIndex],
          });
          if (this.soilType === SoilType.Native) {
            // eslint-disable-next-line no-nested-ternary
            const depthString = graphDataArray[0]?.stockPile
              ? ' - Stockpile'
              : graphDataArray[0]?.depth
                ? ` - ${graphDataArray[0]?.depth}'-${graphDataArray[0]?.endDepth}'`
                : '';
            textForDraw.push({
              name: graphDataArray[0]?.sampleId.substring(0, 6),
              lineText: `${graphDataArray[0].sampleId} - @${graphDataArray[0]?.effort}%${depthString}`,
            });
          } else if (this.soilType === SoilType.Precast) {
            const dateString = this.datePipe.transform(
              new Date(new Date(graphDataArray[0]?.castDate).getTime() - new Date().getTimezoneOffset() * 60000),
              'MM/dd/yy',
            );
            textForDraw.push({
              name: graphDataArray[0]?.sampleId.substring(0, 6),
              lineText: `${graphDataArray[0].inHouseId} - ${dateString} - ${graphDataArray[0]?.sampleType}`,
            });
          }
          graphDataArray.forEach((each) => {
            if (this.soilType === SoilType.Native) {
              if (graphDataArray[0].worksheetType === 'Bulk') {
                graphPoints.push({
                  x: each.labSpecMoistureContent,
                  y: each.wetTr,
                });
              } else {
                graphPoints.push({
                  x: each.moistureContent,
                  y: each.wetTr,
                });
              }
            } else if (this.soilType === SoilType.Precast) {
              graphPoints.push({
                x: each.moisture,
                y: each.wetTr,
              });
            }
          });
          graphPoints.unshift({
            x: 0,
            y: graphDataArray[0]?.dryTr,
          });
          this.lineChartData[i].data = graphPoints;
          if ((i + 1) % this.splitCount === 0) {
            colorIndex = 0;
          } else {
            colorIndex += 1;
          }
        });
        let lineDataArray: any[] = [];
        let textArray: any[] = [];
        this.lineChartData.forEach((lineData, i) => {
          lineDataArray.push(lineData);
          if ((i + 1) % this.splitCount === 0) {
            lineChartDataArray.push([...lineDataArray]);
            lineDataArray = [];
          }
          if ((i + 1) % this.splitCount !== 0 && this.lineChartData.length - 1 === i) {
            lineChartDataArray.push([...lineDataArray]);
          }
        });
        textForDraw.forEach((textData, i) => {
          textArray.push(textData);
          if ((i + 1) % this.splitCount === 0) {
            textDataArray.push([...textArray]);
            textArray = [];
          }
          if ((i + 1) % this.splitCount !== 0 && textForDraw.length - 1 === i) {
            textDataArray.push([...textArray]);
          }
        });
        this.lineChartDataArray = lineChartDataArray;
        let imageCount = 0;
        setTimeout(() => {
          this.canvasRefs.forEach((canvasRef, i) => {
            const canvas = canvasRef.nativeElement;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
              ctx.fillRect(50, 10, 40, 40);
              this.chart = new Chart(ctx, {
                type: 'scatter',
                data: {
                  datasets: lineChartDataArray[i],
                },
                options: this.setChartOptions(),
                plugins: [
                  {
                    id: 'a',
                    afterDraw: (chart) => {
                      let x = 0;
                      let y = 0;
                      let width = 0;
                      let height = 0;
                      let offSet = 0;
                      if (this.soilType === SoilType.Native) {
                        x = chart.chartArea.right - 200;
                        y = chart.chartArea.top;
                        width = 200;
                        height = 200;
                        offSet = 70;
                      } else if (this.soilType === SoilType.Precast) {
                        x = chart.chartArea.right - 275;
                        y = chart.chartArea.top;
                        width = 275;
                        height = 200;
                        offSet = 110;
                      }
                      const lineWidth = 30;
                      this.drawBox(ctx, x, y, width, height);
                      const name = '';
                      // if (textDataArray[i] && textDataArray[i][0] && textDataArray[i][0]?.name) {
                      //   name = textDataArray[i][0].name;
                      // }
                      this.drawCenterHead(ctx, name, x + width / 2, y + height / 2 - 65);
                      for (let j = 1; j <= lineChartDataArray[i].length; j++) {
                        const centerX = x + width / 2 - offSet;
                        const centerY = y + height / 3 + 20 * j;
                        const text = textDataArray[i][j - 1].lineText;
                        this.drawEachLineDetails(ctx, text, centerX, centerY, lineWidth, this.leftBorderColor[j - 1]);
                      }
                    },
                  },
                ],
              });
              imageCount += 1;
            }
          });
          this.getImageCount.emit(imageCount);
        }, 50);
      },
      error: () => {},
    });
  }

  findMax(old: number, latest: number): number {
    return old < latest ? latest : old;
  }

  setChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: true,
          displayColors: false,
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'THERMAL DRYOUT CURVE',
          color: 'black',
        },
      },
      scales: {
        y: {
          min: 0,
          max: this.checkLegendChange() * 50,
          title: {
            display: true,
            text: 'THERMAL RESISTIVITY (°C -cm/W)',
            color: 'black',
            font: {
              weight: 'bold',
            },
          },

          ticks: {
            stepSize: 10,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            major: {
              enabled: true,
            },
            color: 'black',
          },
        },
        x: {
          min: 0,
          max: this.checkLegendChange() * 5,
          title: {
            display: true,
            text: 'MOISTURE CONTENT (% DRY WEIGHT)',
            color: 'black',
            font: {
              weight: 'bold',
              style: 'normal',
            },
          },
          ticks: {
            stepSize: 1,
            autoSkip: false,
            callback: (value, index) => (index % 5 === 0 ? value : ''),
            color: 'black',
          },
        },
      },
    };
  }

  drawBox(ctx: any, x: number, y: number, width: number, height: number): void {
    ctx.fillStyle = 'rgba(255, 255, 255)';
    ctx.fillRect(x, y, width, height);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.strokeRect(x, y, width, height);
  }

  drawCenterHead(ctx: any, text: string, centerX: number, centerY: number): void {
    ctx.fillStyle = 'black';
    ctx.font = 'bold 12px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(text, centerX, centerY);
    const commaX = centerX;
    const commaY = centerY + 2;
    const textWidth = ctx.measureText(text).width;
    const textX = commaX - textWidth / 2;
    const textY = commaY + 3;
    const underlineLength = textWidth;
    ctx.beginPath();
    ctx.moveTo(textX, textY);
    ctx.lineTo(textX + underlineLength, textY);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  drawEachLineDetails(
    ctx: any,
    text: string,
    centerX: number,
    centerY: number,
    lineWidth: number,
    lineColor: string,
  ): void {
    ctx.beginPath();
    ctx.moveTo(centerX - lineWidth / 2, centerY - lineWidth / 2 - 15);
    ctx.lineTo(centerX + lineWidth / 2, centerY - lineWidth / 2 - 15);
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 2;
    ctx.stroke();
    const textX = centerX + lineWidth / 2 + 10;
    const textY = centerY - lineWidth / 2 - 15;
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    ctx.textAlign = 'left';
    ctx.fillText(text, textX, textY);
  }

  drawDate(ctx: any, text: string, centerX: number, centerY: number, lineWidth: number) {
    ctx.beginPath();
    const textX = centerX + lineWidth / 2 + 10;
    const textY = centerY - lineWidth / 2 - 15;
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    ctx.textAlign = 'left';
    ctx.fillText(text, textX, textY);
  }

  checkLegendChange(): number {
    let maxTr = 0;
    let maxMc = 0;
    this.data.forEach((control: any[]) => {
      control.forEach((each: any, j: number) => {
        if (j === 0) {
          maxTr = this.findMax(maxTr, each.dryTr);
        }
        maxTr = this.findMax(maxTr, each.wetTr);
        if (this.soilType === SoilType.Native) {
          if (control[0].worksheetType === 'Bulk') {
            maxMc = this.findMax(maxMc, each.labSpecMoistureContent);
          } else {
            maxMc = this.findMax(maxMc, each.moistureContent);
          }
        } else if (this.soilType === SoilType.Precast) {
          maxMc = this.findMax(maxMc, each.moisture);
        }
      });
    });
    maxTr = Number(maxTr);
    if (maxTr / 50 > maxMc / 5) {
      return maxTr % 50 === 0 ? maxTr / 50 : Math.ceil(maxTr / 50);
    }
    return maxMc % 5 === 0 ? maxMc / 5 : Math.ceil(maxMc / 5);
  }
}
