<div *ngIf="previewData">
  <div class="main-body">
    <p [innerHTML]="previewData.mainBodyIntro"></p>
    <p>
      <strong class="un">Thermal Resistivity Tests:</strong>
      <span [innerHTML]="getBoldProject(previewData.mainBody)"></span>
    </p>
    <p><strong>Sample ID, Description, Thermal Resistivity, Moisture Content and Density</strong></p>
  </div>
  <div class="rpreview-sample-wrapper">
    <table>
      <tr>
        <th rowspan="2">
          <span>Sample ID</span>
        </th>
        <th rowspan="2">
          <span>Depth </span>
          <span>(ft) </span>
        </th>
        <th rowspan="2" *ngIf="previewData.reportType !== 'Tube'">
          <span>Effort </span>
          <span>(%) </span>
        </th>
        <th rowspan="2">
          <span>Description</span>
          <span>({{ previewData.companyName }})</span>
        </th>
        <th colspan="2">
          <span>Thermal Resistivity (°C-cm/W)</span>
        </th>
        <th rowspan="2">
          <span>Moisture Content</span>
          <span>(%) </span>
        </th>
        <th rowspan="2">
          <span>Dry Density</span>
          <span>(lb/ft3) </span>
        </th>
      </tr>
      <tr class="b">
        <th class="c">
          <span>Wet</span>
        </th>
        <th class="c">
          <span>Dry</span>
        </th>
      </tr>
      <tr class="p" *ngFor="let td of previewData?.thermalData">
        <td>{{ td.sampleId }}</td>
        <td>{{ td.depth }}</td>
        <td *ngIf="previewData?.reportType !== 'Tube'">{{ td.effort }}</td>
        <td>{{ td.description }}</td>
        <td>{{ td.wet }}</td>
        <td>{{ td.dry }}</td>
        <td>{{ td.moistureContent }}</td>
        <td>{{ td.density }}</td>
      </tr>
    </table>
  </div>
</div>
